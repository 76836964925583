<template>
  <component :is="mode === 'inline' ? 'div' : 'li'"
   :style="mode === 'inline' ? 'display: inline-flex;' : ''">
    <!-- Icono -->
    <figure v-if="iconClass && mode === 'list'">
      <span :class="iconClass"></span>
    </figure>

    <!-- Etiqueta (p o h4 ) -->
    <component v-if="mode === 'list'" :is="title ? 'h4' : 'p'">
      {{ label }}
    </component>

    <!-- Switch -->
    <label :class="['custom-switch-label', mode === 'inline' ? '' : 'switch']" style="display: inline-flex">
      <div v-if="mode === 'inline'" >
        <input class="custom-switch-input" :checked="internalValue" :disabled="disabled" @change="emitChange"
          type="checkbox" />
        <span class="custom-switch-lever"  :class="['custom-switch-lever', { 'custom-switch-lever-disabled': disabled }]"></span>
      </div>
      <input v-else v-model="internalValue" :id="providerId" @change="emitChange" type="checkbox"
        class="custom-switch-input" />
      <span v-if="mode === 'list'" class="custom-switch-lever"  :class="['custom-switch-lever', { 'custom-switch-lever-disabled': disabled }]"></span>
    </label>
    <p v-if="mode === 'inline'">{{ label }}</p>
  </component>
</template>

<script>
export default {
  name: "SwitchBistroToggle",
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      required: false, 
    },
    label: {
      type: String,
      required: true,
    },
    mode: {
      type: String,
      default: "inline", 
      validator: (val) => ["inline", "list"].includes(val),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    providerId: {
      type: [String, Number],
      required: false,
    },
    title: {
      type: Boolean,
      default: false,
    },
    iconClass: {
      type: String,
      default: "",
    },
  },
  emits: ["update:modelValue", "change"],
  computed: {
  internalValue: {
    get() {
      if (Array.isArray(this.modelValue)) {
        return this.modelValue.includes(this.value);
      }
      return this.modelValue || this.value;
    },
    set(value) {
      if (Array.isArray(this.modelValue)) {
        const newValue = [...this.modelValue];
        if (value) {
          if (!newValue.includes(this.value)) {
            newValue.push(this.value);
          }
        } else {
          const index = newValue.indexOf(this.value);
          if (index !== -1) {
            newValue.splice(index, 1);
          }
        }
        this.$emit("update:modelValue", newValue);
      } else {
        this.$emit("update:modelValue", value);
      }
    },
  },
},
  methods: {
    emitChange() {
      this.$emit("change", this.internalValue);
    },
  },
};
</script>

<style scoped>
/* Estado activado */
.custom-switch-input:checked+.custom-switch-lever {
  background-color: white;
  border: 1px solid #37c866;
}

/* Movimiento del círculo cuando está activado */
.custom-switch-input:checked+.custom-switch-lever:before {
  transform: translateX(18px);
  background-color: #37c866;
}

.custom-switch-input:disabled+.custom-switch-lever {
  background-color: white;
  border: 1px solid #d8d8d8;
}
.custom-switch-lever-disabled {
  background-color: #fff  !important; 
  border: 1px solid #37c866 !important; 
}

.custom-switch-lever-disabled::before {
  background-color: #949494  !important; 
}
</style>