<template>
  <transition name="modal-fade">
    <div class="modal-comment-container" v-show="isOpen">
      <div class="modal-comment-overlay" @click="closeModal"></div>
      <div class="modal-comment-content">
        <!-- <div class="modal-icon-comment">
          <img src="@/assets/icon-local-dining.svg" alt="icon-local-dining" />
          <button @click="closeModal" class="btn-flat">
            <span class="icon-Bistro-_Close"></span>
          </button>
        </div>        
        <div class="modal-comment-header">
          <span>Ingresar orden del plato</span>
        </div>
        <div class="order-dish-buttons">
          <button>1er</button>
          <button>2do</button>
        </div>
        <div class="modal-comment-border-bottom"></div> -->
        <div>
          <div class="modal-icon-comment">
          <img src="@/assets/icon-comment.svg" alt="icon-comment"/>
          </div>        
          <div class="modal-comment-header">
          <span>Escribe un comentario</span>
          </div>
           <input v-model="productComment" type="text" placeholder="Ingresa tu comentario" maxlength="25" class="modal-comment-input">
        </div>
        <div class="modal-comment-border-bottom"></div>
        <div class="modal-comment-footer">
          <div class="footer-buttons">
            <ButtonBistro @click="closeModal" :text="'Cancelar'" :variant="'transparent'" class="footer-hover"/>
            <ButtonBistro @click="saveComment" :text="'Guardar'" :variant="'solid-primary-blue'" class="footer-hover"/>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import ButtonBistro from "@/features/shared/components/ButtonBistro.vue"

export default {
  name: "ModalComment",
  components: {
    ButtonBistro,
  },
  props: {
    showProductCommentModal: {
      type: Boolean,
    },
    selectedProductComment: {
      type: String
    }
  },
  data() {
    return {
      isOpen: this.showProductCommentModal,
      productComment: this.selectedProductComment,
    };
  },
  methods: {
    openModal() {
      this.isOpen = true;
    },
    closeModal() {
      this.isOpen = false;
      this.productComment = ""
      this.$emit('update:showProductCommentModal', false);
    },
    saveComment() {
      this.$emit('onClick', this.productComment);
      this.closeModal()
    }
  },
}
</script>

<style scoped lang="scss">
.modal-comment-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  inset: 0;
  
  .modal-comment-overlay {
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0.5);
  }

  .modal-comment-content {
    background-color: #f3f0f0;
    background: $lightColorBg;
    position: relative;
    border-radius: 6px;
    width: 30%;
    box-sizing: border-box; 
    z-index: 220;
  }

  .modal-comment-header {
    padding: 5px 30px;

    >span {
      font-size: 16px;
      color: $darkColorFont;
      font-weight: 600;
    }
  }
}

.modal-icon-comment {
  display: flex; 
  justify-content: space-between; 
  padding: 20px 30px 2px 30px;
}

.order-dish-buttons {
  padding: 5px 30px 20px 30px;
}

.order-dish-buttons button {
  padding: 10px 20px !important; 
  background-color: #f3f0f0;
  color: black; 
  border: none; 
  border-radius: 4px; 
  cursor: pointer; 
  transition: background-color 0.3s;
  width: 90px;
  margin-right: 10px;
}

.order-dish-buttons button:hover {
  background-color: #db9909; 
}

.modal-comment-border-bottom {
  border-bottom: 1px solid #e0e0e0; 
  margin: 5px 0px; 
}

.modal-comment-footer {
  display: flex;
  justify-content: end;
}

.footer-buttons button {
  margin: 5px 30px 15px 0;
  height: auto;
  padding: 8px 30px;
  font-size: 14px;
  border: 1px solid #007bff;
}


.footer-hover:hover {
  background-color: #0056b3 !important;
  color: white;
  border: 1px solid #0056b3;
}

.modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  color: #000; 
}

.modal-comment-content{
  .modal-comment-input {
    margin: 0px 30px 15px 30px;
    background-color: #f3f0f0;
    border-radius: 4px;
    border: none !important;
    font-size: 14px;
    color: black;
    width: calc(100% - 68px);
    max-width: none;
    box-shadow: none !important;
    padding-left: 8px;
  }
}
</style>
