<template>
    <main class="main-container">
        <div class="container" :class="['container', { 'min-height-600': categories.length < 0 }]">
            <h1 class="title">Mi Carta</h1>
            <p class="subtitle">Asistente para la creación de cartas</p>
            <div class="border-line"></div>
            <div class="centered-content">
                <div class="input-search-container">
                    <InputBistroVModel v-model="searchValue" :type="inputType" placeholder="Buscar productos..." class="input-search" />
                </div>
                <div class="border-line"></div>
                <p v-if="categories.length == 0" class="add-category-text">Agrega una categoría para empezar a crear tu carta</p>
                <div v-else>
                    <NewCategorySection ref="newCategorySection"  :categories="categories" :search-value="searchValue" @update-category="updateCategory" @update-show-buttons="showButtons = $event"/>
                </div>
                <div class="border-line"></div>
                <ButtonBistro variant="blue-add-category" text="+ Agregar categoría" @click="addCategory"/>
                <div v-if="showButtons" class="action-buttons">
                    <ButtonBistro variant="border-line-red" text="Cancelar" @click="cancel" :class="{'disabled' :isLoading}"/>
                    <ButtonBistro variant="solid-primary-blue shadow" text="Guardar Carta" @click="save" :class="{'disabled' :isLoading}"/>
                </div>
            </div>
        </div>
    </main>
  </template>
  
<script>
import NewCategorySection from '@/features/pdvProducts/components/NewCategorySection.vue'
import ButtonBistro from '@/features/shared/components/ButtonBistro.vue'
import bOrdersCommons from "@/mixins/bOrdersCommons"
import commons from "@/mixins/mixCommons"
import shopCommons from "../../shared/mixins/shopCommons"
import productV2Commons from "@/mixins/productV2Commons"
import rolesCommons from "@/mixins/rolesCommons"
import authCommons from "@/mixins/authCommons.vue"
import InputBistroVModel from '@/features/pdvProducts/components/InputBistroVModel.vue'
import axios from 'axios'
import { toast } from "vue3-toastify"
import { db } from "@/firebase";
import { ref, get, set, update, push, remove } from "firebase/database"

export default {
    mixins: [bOrdersCommons, commons, rolesCommons, shopCommons, productV2Commons, authCommons],
    components: {
        InputBistroVModel,
        ButtonBistro,
        NewCategorySection
    },
    data() {
      return {
        categories: [],
        searchValue: "",  
        inputType: "search",
        isLoading: false,
        removedCategoriesList: [],
        showButtons: false
      }
    },
    mounted() {
		document.title = `${this.appTitle}  - Productos`
		if (this.isUserLoggedIn() === false) {
			axios
				.get("/api/auth/logout") /// OK
				.then(() => {
					this.$store.SET_USER(null)
					this.resetUser()
					this.setAutomaticLogin(false)
					location.href = "/login"
				})
		} else if (!this.hasToShowSettingsPosTab) {
			this.handleUnauthorizedAccess()

		} else {
			this.onMounted()
		}
    },
    _methods: {
        async onMounted() {
            this.$store.updateSideMenu(-1)
            this.$store.updateTopMenu(11)

            this.$store.hideElements({
                hideCalendar: true,
                hideFilters: false,
                hideTabs: true,
            })

            this.getListByShopCode()
            this.$store.CALL_MERCHANTS()
            this.toggleMerchantSelectorByLabel('showPDVListSelectionFilter')

            await this.getCategories()

        },
        async getCategories() {
            if (!this.shopCode) return
            const productsRef = ref(db, `products/${this.shopCode}`)
            const products = await get(productsRef)
            const productsSnapshot = products.val()

            if (productsSnapshot.idList === null) {
                console.error("idList is null")
                toast.error("Lista de de productos no encontrada")
                return
            }
            const productsListRef = ref(db, `listProducts/${productsSnapshot.idList}/products`)
            const productsList = await get(productsListRef)
            const productsListSnapshot = productsList.val()


            const storedCategories = productsSnapshot.categories.map(x => {
                return {
                    ...x,
                    editCategory: false,
                    errorName: '',
                    oldCategoryName: x.name,
                    products: [],
                    isCategoryTouched: false
                }
            })

            storedCategories.forEach(category => {
                const matchingProducts = Object.values(productsListSnapshot)
                    .filter(product => product.department === category.name)
                    .filter(product => product.origins && product.origins.some(origin => origin.name === 'Mostrador'))
                if (matchingProducts) {
                    category.products = matchingProducts.map(product => {
                        return {
                            ...product,
                            price: product.origins.find(origin => origin.name === 'Mostrador').price,
                            vat: product.rootVat,
                            errors: [],
                        }
                    })
                }
            })
            if (storedCategories) {
                this.$pdv.setCategoriesMenu(storedCategories)
                this.categories = this.$pdv.getCategoriesMenu
            }
        },
        addCategory() {
            const copyCategories = this.$pdv.getCategoriesMenu
            this.categories = ([
                ...copyCategories,
                {
                    name: "",
                    editCategory: true,
                    errorName: '',
                    oldCategoryName: null,
                    products: [],
                    isCategoryTouched: false
                }
            ])
            this.$nextTick(() => {
                this.$refs.newCategorySection.activateEventListener()
            })
        },
        cancel() {
            this.categories = this.$pdv.getCategoriesMenu
        },
        updateCategory(updatedCategories) {
            const removedCategories = this.categories.filter(category =>
                !updatedCategories.some(updatedCategory => updatedCategory.name === category.name)
            )
            this.removedCategoriesList.push(...removedCategories)
            this.categories = updatedCategories
            this.$pdv.setCategoriesMenu(updatedCategories)
        },
        async onUpsertCategoriesAndProducts(categories) {
            if (this.shopCode === null) {
                console.error("No se ha seleccionado un local")
                toast.error("No se ha seleccionado un local")
                return
            }
            const currentValueProdsShop = ref(db, `products/${this.shopCode}`)
            const productSnapshot = await get(currentValueProdsShop)
            const shopProductSetup = productSnapshot.val()

            if (!shopProductSetup.idList) {
                console.error("idList is null")
                toast.error("Lista de de productos no encontrada")
                return
            }

            const currentValueListProducts = ref(db, "listProducts/" + shopProductSetup.idList)
            const shopProductListSnapshot = await get(currentValueListProducts)
            const shopProductList = shopProductListSnapshot.val()

            const existingCategoryNames = categories.map(category => category.oldCategoryName).filter(name => name !== null)

            shopProductSetup.categories = shopProductSetup.categories.filter(category => existingCategoryNames.includes(category.name))

            if(shopProductList?.menues){
                shopProductList.menues = {name:"Tienda Online", uuid: this.createUUID(), origins: {fdsSyncStatus: 0, name: "Mostrador"}}
            }

            categories.forEach(async (category) => {
                let oldCategoryName = category.oldCategoryName //Es null si la categoria es nueva

                if (!oldCategoryName) {
                    const newCategory = {
                        name: category.name,
                        orderNumber: category.orderNumber || null,
                        hasToPrintInKitchen: category.hasToPrintInKitchen || false,
                        hasToPrintOnCounter: category.hasToPrintOnCounter || true,
                        hasToPrintOnAdditionalPrinter: category.hasToPrintOnAdditionalPrinter || false,
                        hasToEnableFlavorLimit: category.hasToEnableFlavorLimit || false,
                        minimumFlavorLimit: category.minimumFlavorLimit ?? 0,
                        maximumFlavorLimit: category.maximumFlavorLimit ?? 99,
                    }
                    shopProductSetup.categories.push(newCategory)
                } else if (oldCategoryName != category.name) {
                    let cat = shopProductSetup.categories.filter((c) => c.name === oldCategoryName)
                    if (cat.length === 1) {
                        cat[0].name = category.name
                    } else {
                        ///FIXME que deberia pasar aca? quiero editar algo que no existe?
                    }

                    if (shopProductList != null) {
                        if (shopProductList.products !== undefined && shopProductList.products !== null) {
                            let allProds = Object.entries(shopProductList.products)
                            allProds.forEach((element) => {
                                const key = element[0]
                                const prod = element[1]
                                if (prod.department === oldCategoryName) {
                                    prod.department = category.name

                                    const updateLP = ref(
                                        db,
                                        `listProducts/${shopProductSetup.idList}/products/${key}`
                                    )
                                    update(updateLP, prod)
                                }
                                if (shopProductList.combos) {
                                    let allCombos = Object.entries(shopProductList.combos)
                                    allCombos.forEach((element) => {
                                        const key = element[0]
                                        const combo = element[1]

                                        if (combo.department === oldCategoryName) {
                                            combo.department = category.name
                                            const updateLPC = ref(
                                                db,
                                                `listProducts/${shopProductSetup.idList}/combos/${key}`
                                            )
                                            update(updateLPC, combo)
                                        }
                                    })
                                }
                            })
                        }
                    }
                }

                if(this.removedCategoriesList.length > 0){
                    this.removedCategoriesList.forEach(removeCategory => {
                        const removedProducts = Object.values(shopProductList.products)
                            .filter(product => product.department === removeCategory.name)
                            .filter(product => 
                                product.origins && product.origins.some(origin => origin.name === 'Mostrador' &&
                                !removeCategory.products.some(catProduct => catProduct.sku === product.sku)))
                        
                        if(removedProducts.length > 0){
                            removedProducts.forEach(removedProduct => {
                                const productKey = this.findKeyBySku(Object.entries(shopProductList.products), removedProduct.sku)
                                if(!productKey) return;
                                const updateProduct = ref(db, `listProducts/${shopProductSetup.idList}/products/${productKey}`)
                                remove(updateProduct)
                            })
                        }
                    })
                    this.removedCategoriesList = []
                } else {
                    const removedProducts = Object.values(shopProductList.products)
                        .filter(product => product.department === category.name)
                        .filter(product => 
                            product.origins && product.origins.some(origin => origin.name === 'Mostrador' &&
                            !category.products.some(catProduct => catProduct.sku === product.sku)))
                    
                    if(removedProducts.length > 0){
                        removedProducts.forEach(removedProduct => {
                            const productKey = this.findKeyBySku(Object.entries(shopProductList.products), removedProduct.sku)
                            if(!productKey) return;
                            const updateProduct = ref(db, `listProducts/${shopProductSetup.idList}/products/${productKey}`)
                            remove(updateProduct)
                        })
                    }
                }

                let productsFromCategoryInFirebase = Object.values(shopProductList.products)
                    .filter(product => product.department === category.name)
                    .filter(product => 
                        product.origins && product.origins.some(origin => origin.name === 'Mostrador' &&
                        category.products.some(catProduct => catProduct.sku === product.sku)))
                    .map(product => {
                        const updatedProduct = category.products.find(catProduct => catProduct.sku === product.sku);
                        if (updatedProduct && (updatedProduct.name !== product.name || updatedProduct.vat !== product.vat || updatedProduct.price !== product.price)) {
                            return {
                                ...product,
                                name: updatedProduct.name,
                                origins: [{fdsSyncStatus: 0, iva: updatedProduct.vat, name: "Mostrador", price: updatedProduct.price}],
                                rootVat: updatedProduct.vat
                            };
                        }
                        return product; // Si no cambia el nombre, dejamos el producto como está
                    });

                const missingProducts = category.products.filter(catProduct => !productsFromCategoryInFirebase.some(product => product.sku === catProduct.sku))
                if(missingProducts.length > 0){
                    let skuIndex = shopProductList.nextIncrementForSku ?? 0;
                    missingProducts.forEach(x => {
                        x.sku = `10${this.shopCode}${skuIndex}`
                        skuIndex++;
                    });
                    shopProductList.nextIncrementForSku = skuIndex;
                    const newProductsForFirebase = missingProducts.map(product => ({
                            department: category.name,
                            description: null,
                            exempt: false,                        
                            hasToPrintInKitchen: false,
                            hasToPrintOnAdditionalPrinter: false,
                            hasToPrintOnCounter: true,
                            iepsMxTaxRate: 0,
                            image: null,
                            isProductAvailable: true,
                            measureUnit: null,
                            name: product.name,
                            nonTaxed: false,
                            origins: [{fdsSyncStatus: 0, iva: product.vat, name: "Mostrador", price: product.price}],
                            rg5329: false,
                            rootVat: product.vat,
                            sku: product.sku,
                            stock: "0",
                            type: "PRODUCTO",
                            weightable: false
                    }))
                
                    newProductsForFirebase.forEach(async (product) => {
                        const newProductKey = ref(
                            db,
                            `listProducts/${shopProductSetup.idList}/products`
                        )
                        push(newProductKey, product)
                    })
                }

                productsFromCategoryInFirebase.forEach(async (product) => {
                    const productKey = this.findKeyBySku(Object.entries(shopProductList.products), product.sku)
                    if(!productKey) return;
                    const updateProduct = ref(
                        db,
                        `listProducts/${shopProductSetup.idList}/products/${productKey}`
                    )
                    update(updateProduct, product)
                })
            })

            const updateProductsCateg = ref(
                db,
                `products/${this.shopCode}/categories`
            )
            await set(updateProductsCateg, shopProductSetup.categories)
            const updateNextIncrementSku = ref(
                db,
                `listProducts/${shopProductSetup.idList}/nextIncrementForSku`
            )
            await set(updateNextIncrementSku, shopProductList.nextIncrementForSku)
        },
        findKeyBySku(data, targetSku) {
            for(const [key, value] of data){
                if (value.sku === targetSku) {
                    return key; // Return the key (position 0 of each object)
                }
            }
            return null; // Return null if no match is found
        },
        async save() {
            this.$store.initLoading(this)
            this.isLoading = true
            const newCategorySection = this.$refs.newCategorySection
            const validation = newCategorySection.validateFields()
            if (!validation.isValid) {
                const categoriesWithErrors = this.categories.filter(category => category.errorName !== "")
                if (categoriesWithErrors.length > 0) {
                    categoriesWithErrors.forEach(error => toast.error(error.errorName))
                    this.isLoading = false
                    this.$store.finishLoading(this)
                    return false 
                }
                const productsWithErrors = this.categories.flatMap(category =>
                    category.products.flatMap(product =>
                        Object.values(product.errors).filter(error => error)
                    )
                );
                if(productsWithErrors.length > 0) {
                    productsWithErrors.forEach(error => toast.error(error))
                    this.isLoading = false
                    this.$store.finishLoading(this)
                    return
                }
                this.isLoading = false
                this.$store.finishLoading(this)
                return toast.error("Error al guardar la carta")
            }
            await this.onUpsertCategoriesAndProducts(this.categories)
            toast.success("La carta se guardó correctamente")

            setTimeout(() => {
                location.reload()
            }, 3000)
        },
    },
    get methods() {
        return this._methods
    },
    set methods(value) {
        this._methods = value
    },
    watch: {
        searchValue(newValue) {
            this.inputType = newValue.length > 0 ? "text" : "search"
        },
        async shopCode() {
            await this.getCategories()
        }
    },
}
</script>
  
<style scoped lang="scss">
    .main-container {
        background-color: #e2e2e2;
        overflow: auto;
    }

    .border-line {
        border-bottom: 1px solid #cecece; 
        margin: 3px 0 3px 0; 
    }
    .container {
        margin: 50px auto;
        padding: 20px;
        background-color: #f4f4f4;
        border-radius: 8px;
        text-align: center;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        display: flex; 
        flex-direction: column;
    }

    .min-height-600 {
        min-height: 650px;
    }

    .centered-content {
        margin: 0 100px; 
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    .input-search-container {
        text-align: left;

        .input-search {
            margin: 30px 0 15px 0;
            padding-left: 10px;
            width: 100%;            
            border: 1px solid #d8d8d8; 
            border-radius: 5px;     
            color: #212121;
            &:focus{
                border: none !important;
                box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.1) !important;
            }
        }    
    }  
    
    .title {
        font-size: 24px;
        margin-bottom: 8px;
    }
    
    .subtitle {
        font-size: 14px;
        color: #666;
        margin-bottom: 20px;
    }

    .add-category-text {
        font-size: 16px;
        margin: 20px;
        color: #666;
    }
    
    .action-buttons {
        display: flex;
        justify-content: flex-end;
        gap: 20px;
        margin-top: auto;
        padding-top: 20px;
    }

    .disabled {
        pointer-events: none; 
        opacity: 0.6;       
        cursor: not-allowed; 
    }
</style>
  