export function currency(amount) {
  if (typeof amount === "undefined")
    return "";

  if (amount === "")
    amount = "0";


  const localeCurrencyMap = {
    AR: { locale: "es-AR", currency: "ARS" },
    UY: { locale: "es-UY", currency: "UYU" },
    //TODO descomentar si vamos a usar los miles con coma en mexico
    // MX: { locale: "es-MX", currency: "MXN" },
    ES: { locale: "es-ES", currency: "EUR" },
    ENERGY_POS_SL: { locale: "es-SV", currency: "USD" },
  }

  const { locale, currency } =
    localeCurrencyMap[process.env.VUE_APP_INSTANCE_CODE] || // Configuración según instancia
    { locale: "es-AR", currency: "ARS" }

  return parseFloat(amount).toLocaleString(locale, {
    style: "currency",
    currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
}
