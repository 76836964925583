<template>
    <div :id="modalOption" class="modal modal-block-outside" style="overflow-y: auto !important"
        @keydown.esc="closeModal()">
        <div class="modal-header">
            <div class="image-title">
                <h4>{{ title }}</h4>
                <p>Fácil y Simple</p>
            </div>
            <div class="btnClose">
                <a href="#!" @click="closeModal" class="modal-action modal-close waves-effect waves-green btn-flat">
                    <span class="icon-Bistro-_Close"></span>
                </a>
            </div>
        </div>

        <div class="modal-body">
            <form action class="formGeneral" v-if="!confirmation">
                <section class="formMain article-product">
                    <div class="itemForm " style="width: 100%">
                        <p>Ingrese la información {{ text }} </p>
                        <section>
                            <label>Monto {{ action }}:</label>
                            <div class="input-group">
                                <span class="input-icon">$</span>
                                <input type="number" v-model="declaredAmount" class="v-text-field money-input"
                                    placeholder="0.00" @input="validatePositiveNumber" />
                            </div>
                            <p v-if="errorMessage && this.operation != 'open' && this.operation != 'close'" class="error-message">{{ errorMessage }}</p>
                        </section>
                        <section v-if="this.operation == 'withdrawal'">
                            <div class="select-group">
                                <label>Motivo:</label>
                                <select v-model="reason" class="browser-default" placeholder="Selecionar">
                                    <option value disabled selected>
                                        Seleccionar
                                    </option>
                                    <option v-for="option in options" :value="option" :key="option">
                                        {{ option }}
                                    </option>
                                </select>
                            </div>
                        </section>
                        <section v-if="this.operation == 'deposit' || this.operation == 'withdrawal'">
                            <label>Observaciones:</label>
                            <div class="input-remarks">
                                <input type="text" v-model="remarks"
                                    placeholder="Ej. Ajuste de caja, cambio de efectivo, cobros..." />
                            </div>
                        </section>
                    </div>
                </section>
            </form>
            <section v-else>
                <div class="confirmation">
                    <p v-if="difference != 0" class="modal-description">
                        El monto declarado es <span>{{ differenceText }}</span> al monto registrado.
                        ¿Desea registrar un ajuste por la diferencia?
                    </p>
                    <section class="amount-info">
                        <div>
                            <span>Monto registrado:</span>
                            <span class="amount-value">
                                <CurrencyStyle :valor="$filters.currency(registeredAmount)" />
                            </span>
                        </div>
                        <div>
                            <span>Monto declarado:</span>
                            <span class="amount-value">
                                <CurrencyStyle :valor="$filters.currency(declaredAmount)" />
                            </span>
                        </div>
                        <div>
                            <span>Diferencia encontrada:</span>
                            <div :class="{ 'items-grey': difference > 0 }">

                                <h4 :class="{ 'negative-numbers': difference < 0, }">
                                    <CurrencyStyle
                                        :valor="$filters.currency(difference)" />
                                </h4>
                               
                            </div>

                        </div>
                    </section>
                </div>

            </section>


            <div class="mainEvents-filter ">
                <section class="containButtons">

                    <button @click="closeModal()" class="mainEvents-filter btn-option">
                        <p>Cancelar</p>
                    </button>
                    <a @click="confirmationCashBox()" v-if="this.operation == 'close' || this.operation == 'open'"
                        class="mainEvents-filter">
                        <div class="btn-main">
                            <a>
                                <p>Confirmar</p>
                            </a>
                        </div>
                    </a>
                    <a @click="disableDeposit ? null : confirmationDeposit()" v-else-if="this.operation == 'deposit'"
                        class="mainEvents-filter">
                        <div class="btn-main">
                            <a>
                                <p>Confirmar depósito</p>
                            </a>
                        </div>
                    </a>
                    <a @click="confirmationWithdrawal()"
                        v-else-if="this.operation == 'withdrawal'" class="mainEvents-filter">
                        <div class="btn-main">
                            <a>
                                <p>Confirmar retiro</p>
                            </a>
                        </div>
                    </a>

                </section>
            </div>
        </div>
    </div>
</template>

<script>
import MixModals from '@/mixins/mixModals.vue';
import BoxPosCommons from '../mixins/BoxPosCommons.vue';
import CurrencyStyle from '@/components/CurrencyStyle.vue';
import { toast } from "vue3-toastify";

export default {
    data() {
        return {
            confirmation: false,
            declaredAmount: '',
            inputValue: "",
            errorMessage: "",
            depositAmount: '',
            remarks: '',
            disableDeposit: true,
            reason: '',
            options: ['Pago a proveedores', 'Compra de insumos', 'Retiro por cierre', 'Otros']
        };
    },
    props: {
        operation: {
            type: String,
            required: false,
            default: '',
        },
        modalOption: {
            type: String,
            default: () => {
                return '';
            },
        },

    },
    mixins: [MixModals, BoxPosCommons],
    methods: {
        confirmationCashBox() {

            if (this.confirmation) {
                const event = this.operation == 'open' ? 'onConfirmOpenAmount' : 'onConfirmCloseAmount';
                this.$emit(event, this.declaredAmount, this.difference);
                this.closeModal();
            }
            if (!this.confirmation) return this.confirmation = true;
        },
        confirmationDeposit() {
            this.$emit('onConfirmDepositAmount', this.declaredAmount, this.remarks)
            this.closeModal();
        },
        confirmationWithdrawal() {
            if(!this.declaredAmount)
            {
                toast.error("Debe declarar un monto para hacer el retiro");
                return;
            }
            if(!this.reason) {
                toast.error("Debe elegir un motivo para hacer el retiro");
                return;
            } 
            this.$emit('onConfirmWithdrawal', this.declaredAmount, this.reason, this.remarks)
            this.closeModal();

        },
        closeModal() {
            this.confirmation = false;
            this.resetData()
            this.closeModalById(`#${this.modalOption}`);
        },
        lastCashBoxData() {
            const cashBoxData = localStorage.getItem('cashBox');
            if (!cashBoxData) return null;

            const parsedData = JSON.parse(cashBoxData);
            return parsedData[parsedData.length - 1] || null;
        },
        resetData() {
            this.confirmation = false
            this.reason = ''
            this.declaredAmount = ''
            this.remarks = ''
        },
        validatePositiveNumber(event) {
            const value = event.target.value;

            if (value === "") {
                this.errorMessage = "El campo no puede estar vacío";
                this.disableDeposit = true;
                return;
            }

            if (/^\d*\.?\d+$/.test(value)) {
                this.errorMessage = "";
                this.inputValue = value;
                this.disableDeposit = false;
            } else {
                this.errorMessage = "Ingresa un valor positivo";
                this.disableDeposit = true;
            }
        },
        getCashAmountFromLastCashbox() {
            return this.$store.getCashBox?.openAmount ?? 0; //FIXME 
        },
        getCashAmountFromBackendInThisCashbox() {
            return 111; //FIXME: Sacar del store prpobablemente, pero es un valor que vino de backend.
        }

    },
    computed: {
        differenceText() {
            return this.difference > 0 ? "mayor" : "menor";
        },
        registeredAmount () {
            switch(this.operation) {
                case 'close':
                    return this.getCashAmountFromBackendInThisCashbox();
                case 'open':
                    return this.getCashAmountFromLastCashbox();
                default:
                    return 0;
            }
        },
        difference() {
            switch(this.operation) {
                case 'close':
                    return this.declaredAmount - this.getCashAmountFromBackendInThisCashbox();
                case 'open':
                    return this.declaredAmount - this.getCashAmountFromLastCashbox();
                default:
                    return 0;
            }
            
        },
        title() {
            switch (this.operation) {
                case 'close':
                    return 'Cerrar caja';
                case 'open':
                    return 'Abrir Caja';
                case 'deposit':
                    return 'Depósito de caja';
                case 'withdrawal':
                    return 'Retiro de caja';
                default:
                    return '';
            }
        },
        text() {
            switch (this.operation) {
                case 'open':
                    return 'para la apertura de la caja';
                case 'close':
                    return 'para el cierre de caja';
                case 'deposit':
                    return 'del depósito que desea realizar';
                case 'withdrawal':
                    return 'del retiro que desea realizar';
                default:
                    return '';
            }
        },
        action() {
            switch (this.operation) {
                case 'open':
                    return 'de apertura';
                case 'close':
                    return 'de cierre';
                case 'deposit':
                    return 'a depositar';
                case 'withdrawal':
                    return 'del retiro';
                default:
                    return '';
            }
        },
       




    },
    components: {
        CurrencyStyle
    }
};
</script>

<style scoped lang="scss">
.error-message {
    font-size: small;
    color: rgb(216, 32, 32);
    margin-top: 0;
}

.input-group {
    position: relative;
    display: flex;
    align-items: center;
    width: 40%;
}

.select-group {
    width: 40%;
}

.input-icon {
    position: absolute;
    left: 20px;
    font-size: 17px;
    margin-bottom: 8px;
    color: #000000;

}

div .mainEvents-filter {
    width: 100%;
}

.v-text-field {
    width: 50%;
    font-size: 1rem;
    border-radius: 0.25rem;
    color: #000;
    text-align: right;
    padding: 0 25px !important;
}

#modalCloseBox,
#modalDepositBox,
#modalWithdrawalBox,
#modalOpenBox {
    width: 40% !important;
    position: fixed;
    top: 15% !important;
}

.formGeneral {
    margin-top: 20px !important;
}

.modal-description {
    font-size: 16px;
    color: #333;
    margin-bottom: 20px;
}

.amount-info {
    margin-top: 20px;

    div {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }
}





.amount-value {
    font-size: 16px;
    font-weight: bold;
}

.confirmation {
    padding: 20px 100px
}

.items-grey {
    color: #37c866;
}



.containButtons {
    padding: 20px 100px;
    margin: auto;
    display: flex;
    gap: 8px;

    .btn-option {
        border-color: red !important;

        p {
            color: red !important;
        }
    }
}

.errorLoading-mainError {
    background-color: #fff;
    display: flex;
    align-items: center;

    p {
        white-space: nowrap;
    }
}
</style>