import createIndexedDb from '../db.js';
import Dexie from 'dexie';

const getOrders = async (shopCode) => {
  const db = createIndexedDb(shopCode);
  return await db.orders.toArray();
};


const getCashboxes = async (shopCode) => {
  const db = createIndexedDb(shopCode);
  return await db.cashboxes.toArray();
};

const insertOrder = async (shopCode, order) => {
  const db = createIndexedDb(shopCode);
  const orderId = await db.orders.add(order);
  return { id: orderId, ...order }
};

const insertCashbox = async (shopCode, cashboxData) => {
  const db = createIndexedDb(shopCode);
  const cashboxId = await db.cashboxes.add(cashboxData);
  return { id: cashboxId, ...cashboxData };
};

const updateOrder = async (shopCode, order) => {
  const db = createIndexedDb(shopCode);
  const clonedOrder = Dexie.deepClone(order);
  await db.orders.put(clonedOrder);
};
const updateCashbox = async (shopCode, box) => {
  const db = createIndexedDb(shopCode);
  const clonedCashbox = Dexie.deepClone(box);
  await db.cashboxes.put(clonedCashbox);
};

const deleteOrder = async (shopCode, order) => {
  const db = createIndexedDb(shopCode);
  await db.orders.delete(order.id);
};

const deleteCashbox = async (shopCode, order) => {
  const db = createIndexedDb(shopCode);
  await db.cashboxes.delete(order.id);
};

export default {
  getOrders,
  insertOrder,
  updateOrder,
  deleteOrder,
  insertCashbox,
  getCashboxes,
  updateCashbox,
  deleteCashbox,
};
