<template>  
  <input 
    :value="modelValue" 
    @input="$emit('update:modelValue', $event.target.value)" 
    :type="type" 
    :placeholder="placeholder" 
    class="search" 
    :class="{ 'hide-search': modelValue }" 
  />
</template>

<script>
export default {
  name: "InputBistro",
  props: {
    modelValue: {
      type: [String, Number],
      default: ""
    },
    type: {
      type: String,
      default: "text",
      validator: function (value) {
        return ["text", "email", "number", "search"].includes(value);
      },
    },
    placeholder: {
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
input {
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  color: $darkColorFont;
  height: 46px;
  width: 100%;
  max-width: 400px;
  padding: 0 10px;
  margin: 0;

  &[type="search"] {
    background-image: url(@assets/icon-search.svg);
    background-repeat: no-repeat;
    background-position: 12px center;
    padding-left: 40px;
  }

  &[type="number"] {
    background-image: url(@assets/img/dolar-symbol.png);
    background-repeat: no-repeat;
    background-position: 12px center;
    background-size: 18px 18px;
    padding-left: 40px;
  }

  &.hide-search[type="search"] {
    background-image: none; 
  }

  &[type="text"] {
    color: black;
  }

  &:focus {
    border-color: $primaryColor;
  }
}
</style>
