import indexedDbRepo from "./ordersIndexedDbRepository";
import {
  getTotalAmountFromProduct,
  getTotalVatAmountFromProduct,
  getAmountFromProduct,
  getDiscountsFromProductForSync,
  getProductTotalDiscountAmountForSync,
  getProductTotalDiscountAmount,
} from "../products/productsService";
import axios from "axios";
import { formatDate, getAndroidId, isOlderThan } from "./../../utils/utils";
import { ASYNC_STATUS, ORDER_STATUS } from "./../../utils/orderStatuses";
import { toast } from "vue3-toastify";

const getOrderTotalAmount = (order) => {
  return order.amount;
};

const getTotalDiscountAmountOfAllProductsInTheOrder = (order) => {
  return (
    order.products?.reduce(
      (acc, prod) => acc + getProductTotalDiscountAmount(prod),
      0
    ) ?? 0
  );
};

const getOrderComment = (order) => {
  return `${order.origin} N° ${order.ticketNumber}`;
};
const getOrderTotalVatAmount = (order) => {
  return parseFloat(order.vatAmount.toFixed(2));
};

const getOrder = async (shopCode, orderId) => {
  const orders = await indexedDbRepo.getOrders(shopCode);
  const order = orders.filter((x) => x.id == orderId)[0];
  return order;
};

const getOrders = async (shopCode) => {
  return await indexedDbRepo.getOrders(shopCode);
};
const getActiveOrders = async (shopCode) => {
  const allOrders = await indexedDbRepo.getOrders(shopCode);
  return allOrders.filter((order) => order.status == ORDER_STATUS.ACTIVE);
};

const createOrder = async (shopCode) => {
  const order = {
    uuid: window.crypto.randomUUID(),
    origin: "Mostrador",
    status: ORDER_STATUS.ACTIVE,
    createdAt: Date.now(),
    amount: 0,
    netAmount: 0,
    vatAmount: 0,
    products: [],
    asyncStatus: ASYNC_STATUS.PENDING,
    payments: [],
    lastUpdate: Date.now(),
    ticketNumber: null,
    movementType: "COMANDA",
    BillNumber: null,
    discounts: [],
  };
  return await indexedDbRepo.insertOrder(shopCode, order);
};
const createObjectCashbox = async (shopCode, order, t) => {
  const cashbox = {
    amount: getOrderTotalAmount(order),
    asyncStatus: ASYNC_STATUS.PENDING,
    billNumber: null,
    cashAccumAmount: 0,
    cashMovementId: order.id,
    comments: getOrderComment(order),
    date: formatDate(),
    ticketNumber: String(order.ticketNumber),
    movementType: order.movementType,
    totalAccumDiscountPerProductAmount:
      getTotalDiscountAmountOfAllProductsInTheOrder(order),
    saleAuthCode: order.payments[0]?.authCode,
    saleCaptureWay: order.payments[0]?.captureWay,
    saleOrigin: order.origin.toUpperCase(),
    salePaymentMethod: order.payments[0]?.paymentWay,
    saleType: order.movementType,
    saleId: order.id,
    sequence: 1,
    totalAccumAmount: 0,
    totalDiscountAmount:
      order.discounts?.reduce((acc, discount) => acc + discount, 0) ?? 0,
    totalTaxesAmount: 0,
    totalVatAmount: getOrderTotalVatAmount(order),
    userName: t.$store.authUser?.user?.name,
    uuid: order.uuid,
    createdAt: Date.now(),
  };
  return await indexedDbRepo.insertCashbox(shopCode, cashbox);
};

const updateOrder = async (shopCode, order) => {
  if (!shopCode) {
    console.error("shopCode is required");
    return;
  }
  await indexedDbRepo.updateOrder(shopCode, order);
};

const updateCashbox = async (shopCode, box) => {
  if (!shopCode) {
    console.error("shopCode is required");
    return;
  }
  await indexedDbRepo.updateCashbox(shopCode, box);
};

const deleteOrder = async (shopCode, order) => {
  if (!shopCode) {
    console.error("shopCode is required");
    return;
  }
  await indexedDbRepo.deleteOrder(shopCode, order);
};

const deleteCashbox = async (shopCode, box) => {
  if (!shopCode) {
    console.error("shopCode is required");
    return;
  }
  await indexedDbRepo.deleteCashbox(shopCode, box);
};

const syncOrder = async (shopCode, pendingOrder, t) => {
  if (!navigator.onLine) {
    return false;
  }

  const androidId = getAndroidId(shopCode);
  const payload = {
    androidId: androidId,
    iMEI: "",
    Sales: [
      {
        combosDetails: [],
        dinners: 0,
        discounts: pendingOrder.discounts,
        ticketNumber: String(pendingOrder.ticketNumber),
        Origin: pendingOrder.origin,
        paymentInfo: [
          {
            change: parseFloat(
              (pendingOrder.payments[0]?.receivedPaidAmount ?? 0) -
                pendingOrder.payments[0]?.amount
            ),
            partialAmount: pendingOrder.payments[0]?.amount,
            paymentDescription:
              pendingOrder.payments[0]?.paymentDescription ?? "-",
            paymentWay: pendingOrder.payments[0]?.paymentWay,
            captureWay: pendingOrder.payments[0]?.captureWay,
            authCode: pendingOrder.payments[0]?.authCode,
            cardholder: pendingOrder.payments[0]?.cardHolder,
            truncatedCardNumber: pendingOrder.payments[0]?.truncatedCardNumber,
            externalReferenceId: pendingOrder.payments[0]?.externalReferenceId,
            receivedPaidAmount: parseFloat(
              pendingOrder.payments[0]?.receivedPaidAmount ?? 0
            ),
          },
        ],
        saleDetails: pendingOrder.products.map((product) => ({
          amount: getAmountFromProduct(product),
          exempt: false,
          isWeightable: product.weightable,
          nonTaxed: false,
          measureUnit: product.measureUnit,
          productCategory: product.department,
          productName: product.name,
          quantity: product.quantity,
          sku: product.sku,
          originPrice: product.originPrice,
          originIva: product.originIva,
          discountAmount: getProductTotalDiscountAmountForSync(product),
          discounts: getDiscountsFromProductForSync(product),
          syncSaleOptionList: [],
          totalAmount: getTotalAmountFromProduct(product),
          totalVatAmount: getTotalVatAmountFromProduct(product),
          vatRate: parseFloat(product.originIva),
        })),
        saleId: pendingOrder.id,
        SaleTaxes: [],
        status: "CLOSE",
        timeStamp: pendingOrder.timeStamp, //FIXME #6 -> La fecha debiera estar en la tabla orders.. y sacarla de ahi, como hiciste en cashboxes
        totalAmount: getOrderTotalAmount(pendingOrder),
        totalVatAmount: getOrderTotalVatAmount(pendingOrder),
        username: t.$store.authUser?.user?.name,
        UUID: pendingOrder.uuid,
      },
    ],
  };
  try {
    const { data } = await axios.post(`/api/pdv/SyncSales`, payload);
    if (data?.responseCode == 0) {
      return true;
    } else {
      toast.error(data.message);
      return false;
    }
  } catch (error) {
    toast.error("Error al procesar la orden:", error);
    return false;
  }
};

const syncCashbox = async (shopCode, box) => {
  const androidId = getAndroidId(shopCode);
  const payload = {
    androidId: androidId,
    shopCode: shopCode,
    CashBox: [
      {
        Amount: box.amount,
        BillNumber: box.billNumber,
        CashAccumAmount: box.cashAccumAmount,
        CashMovementId: box.id,
        Comments: box.comments,
        Date: box.date,
        ticketNumber: box.ticketNumber,
        MovementType: box.movementType,
        TotalAccumDiscountPerProductAmount:
          box.totalAccumDiscountPerProductAmount,
        SaleAuthCode: box.saleAuthCode,
        SaleCaptureWay: box.captureWay,
        SaleId: box.saleId,
        SaleOrigin: box.saleOrigin,
        SalePaymentMethod: box.salePaymentMethod,
        SaleType: box.saleType,
        sequence: box.sequence,
        TotalAccumAmount: box.totalAccumAmount,
        TotalDiscountAmount: box.totalDiscountAmount,
        TotalTaxesAmount: box.totalTaxesAmount,
        TotalVatAmount: box.totalVatAmount,
        UserName: box.userName,
        UUID: box.uuid,
        customerId: box.customerId,
        externalReference: box.externalReference,
        waiter: box.waiter,
      },
    ],
    iMEI: "",
  };
  try {
    const data = await axios.post(`/api/pdv/SyncCashbox`, payload);
    if (data?.data?.responseCode == 0) {
      return true;
    } else {
      toast.error(data.message);
      return false;
    }
  } catch (err) {
    toast.error("Ocurrió un error al sincronizar la caja.");
    return false;
  }
};

// Función para sincronizar las órdenes pendientes
const syncOrders = async (shopCode, t) => {
  const orders = await indexedDbRepo.getOrders(shopCode);
  const ordersToSync = orders.filter(
    (order) =>
      order.asyncStatus == ASYNC_STATUS.PENDING &&
      order.status == ORDER_STATUS.CLOSED
  );
  for (const order of ordersToSync) {
    try {
      const isSuccess = await syncOrder(shopCode, order, t);

      if (isSuccess) {
        order.asyncStatus = ASYNC_STATUS.SYNCED;
        await updateOrder(shopCode, order);
        toast.success(`Orden ${order.id} sincronizada exitósamente`);
      }
    } catch (error) {
      toast.error(`Error al sincronizar la orden ${order.id}`);
    }
  }
  await syncDeletedOrders(shopCode);
};

// Función para sincronizar las cashbox pendientes
const syncCashboxes = async (shopCode, hasToShowToast = false) => {
  const cashboxes = await indexedDbRepo.getCashboxes(shopCode);

  const cashboxesToSync = cashboxes.filter(
    (box) => box.asyncStatus == ASYNC_STATUS.PENDING
  );

  for (const box of cashboxesToSync) {
    try {
      const isSuccess = await syncCashbox(shopCode, box);

      if (isSuccess) {
        box.asyncStatus = ASYNC_STATUS.SYNCED;
        await updateCashbox(shopCode, box);
        if (hasToShowToast) {
          toast.success(`Movimiento ${box.id} sincronizado exitósamente`);
        }
      }
    } catch (error) {
      console.error(error);
      toast.error(`Error al sincronizar el movimiento de caja ${box.id}`);
    }
  }
  await syncDeletedCashboxes(shopCode);
};

const syncDeletedOrders = async (shopCode) => {
  const orders = await indexedDbRepo.getOrders(shopCode);
  // Filtrar órdenes más antiguas de 10 días
  const ordersToDelete = orders.filter(
    (order) =>
      isOlderThan(order.createdAt, 10) &&
      order.status == ORDER_STATUS.CLOSED &&
      order.asyncStatus == ASYNC_STATUS.SYNCED
  );
  for (const order of ordersToDelete) {
    await deleteOrder(shopCode, order);
  }
};
const syncDeletedCashboxes = async (shopCode) => {
  const boxes = await indexedDbRepo.getCashboxes(shopCode);
  // Filtrar órdenes más antiguas de 10 días
  const boxesToDelete = boxes.filter(
    (box) =>
      isOlderThan(box.createdAt, 10) && box.asyncStatus == ASYNC_STATUS.SYNCED
  );
  for (const boxes of boxesToDelete) {
    await deleteCashbox(shopCode, boxes);
  }
};
export {
  getOrder,
  getOrders,
  getActiveOrders,
  createOrder,
  updateOrder,
  createObjectCashbox,
  deleteOrder,
  syncCashboxes,
  syncOrders,
};
