<template>
  <!-- HEADER SUPERIOR A 991 -->
  <header class="headerBistro" v-if="!mobile">
    <!-- LOGOTIPO -->

    <article class="logo">
      <a :href="getLogoHRefUrl">
        <!-- <img src="~assets/img/logo_header.png" alt=""> -->
        <BistroLogo type="1" />
      </a>
    </article>

    <!-- NAVEGACION -->
    <nav>
      <ul>
        <li v-for="item in options.filter(
          (x) => x.show && hasToShowThisOption(x.id)
        )" :key="item.id" :class="{ active: isActive(item)}">
          <router-link class="button" :to="item.url">
            <p>{{ item.title }}</p>
          </router-link>
        </li>
      </ul>
    </nav>

    <!-- OPCIONES DE USUARIO -->
    <article class="user">
      <a href="#" class="dropdown-trigger userDropdown" data-target="dropdown-user">
        <div class="item">
          <h3>Hola</h3>
          <p v-if="user?.user !== undefined">{{ user?.user?.name }}</p>
        </div>

        <figure>
          <img src="@/assets/img/comandos-avatar.webp" alt="" />
        </figure>

        <article class="version">v{{ version }}</article>
      </a>

      <!-- Dropdown Structure -->
      <ul id="dropdown-user" class="dropdown-content dropUser">
        <li v-if="hasToShowSettingsPosTab">
          <a href="/settingsPos">Configuraciones</a>
        </li>
        <li v-else-if="hasToShowThisOption(8)">
          <a href="/settingsV2">Configuraciones</a>
        </li>
        <li v-if="hasToShowMyPayments">
          <a href="/myPayments">Mis Pagos</a>
        </li>
        <li v-if="hasToShowThisOption(7)">
          <a href="/eventsV2">Auditoría de Cambios</a>
        </li>
        <li v-if="this.showChangePassword">
          <a href="/password">Contraseña</a>
        </li>
        <li>
          <a href="#" @click="logout">Cerrar Sesión</a>
        </li>
      </ul>
    </article>
  </header>

  <!-- HEADER INFERIOR A 991 -->
  <header v-else class="headerMobile">
    <!-- LOGOTIPO -->
    <article class="logogroup">
      <!-- SUBMENU -->
      <article class="submenu">
        <a class="active">
          <span v-if="drawer" @click="drawer = !drawer" class="icon-Bistro-_Close drawer"></span>
          <figure v-if="!drawer" @click="drawer = !drawer">
            <img src="@/assets/img/icon-menu.svg" alt="" class="drawer" />
          </figure>
        </a>
      </article>

      <!-- LOGOTIPO -->

      <article class="logo">
        <a :href="getLogoHRefUrl">
          <!-- <img src="~assets/img/logo_header.png" alt=""> -->
          <Logo type="1" />
        </a>
      </article>
    </article>

    <!-- OPCIONES FILTRADO -->
    <article class="optionMobileHeader">
      <!-- FILTRADO GENERAL -->
      <section v-if="showMerchantSelectionFilter && !elements.hideFilters" class="filterPDV">
        <!-- ENLACE A DROPDOWN -->
        <a v-if="showMerchantSelectionFilter" href='#' class="filterPDV-link" @click="showGeneralFilter">
          <figure :class="selectedPdvs?.length > 0 ? 'mobile-filter-applied' : ''">
            <p>+</p>
          </figure>
        </a>
        <div v-if="hasToShowGeneralFilter" class="dropdown-click-listener" @click="hasToShowGeneralFilter = false">
        </div>
        <!-- Dropdown Structure -->
        <transition name="fade">
          <ul :class="dropdownClassSelector" v-if="hasToShowGeneralFilter">
            <p :class="dropdownOptionClassSelector" @click="unselectAllShopFilter">Desmarcar todos</p>
            <hr class="dropdown-line">
            <li v-for="item in merchantTags" :key="`${item.shopCode}`" @click="selectPdv(item)"
              class="dropdown-item-container">
              <input class="bistro-checkbox shop-filter-checkbox" type="checkbox" style="opacity: 1;"
                v-model="item.selected" />
              <div class="dropdown-item-text">
                <h4 :ref="`merchantName_${item.shopCode}`" class="dropdown-truncate-text"
                  :title="getMerchantNameForDropdown(item.shopCode, item.merchantName)">
                  {{ item.merchantName }}
                </h4>
                <p>{{ item.shopCode }}</p>
              </div>
            </li>
          </ul>
        </transition>
      </section>

      <!-- OPCIONES DE USUARIO -->
      <article class="user">
        <a href="#" class="dropdown-trigger userDropdown" data-target="dropdown-user-mobile">
          <figure>
            <img src="@/assets/img/comandos-avatar.webp" alt="" />
          </figure>
        </a>

        <!-- Dropdown Structure -->
        <ul id="dropdown-user-mobile" class="dropdown-content dropUser">
          <li>
            <a href="/"></a>
          </li>
          <li v-if="hasToShowSettingsPosTab">
            <a href="/settingsPos">Configuraciones</a>
          </li>
          <li v-else-if="hasToShowThisOption(8)">
            <a href="/settingsV2">Configuraciones</a>
          </li>
          <li v-if="hasToShowMyPayments">
            <a href="/myPayments">Mis Pagos</a>
          </li>
          <li v-if="hasToShowThisOption(7)">
            <a href="/eventsV2">Auditoría de Cambios</a>
          </li>
          <li v-if="this.showChangePassword">
            <a href="/password">Contraseña</a>
          </li>
          <li>
            <a href="#" @click="logout">Cerrar Sesión</a>
          </li>
        </ul>
      </article>
    </article>

    <!-- FILTRO TABS MOBILE -->
    <article v-if="!elements.hideTabs" :class="{ active: showTabs }" class="tabsMobile">
      <h3>Filtrado Rápido</h3>
      <!-- TABS -->
      <ShopFilter />

      <!-- BTN ACTIVE FILTER MOBILE -->
      <button v-if="!showProductListSelectionFilter && !showProviderListSelectionFilter
      " @click="showTabs = !showTabs" class="btnFilterMobile">
        <p>{{ tabSelected.title }}</p>
      </button>

      <!-- BTN ACTIVE FILTER MOBILE -->
      <button v-if="showProviderListSelectionFilter" @click="showTabs = !showTabs" class="btnFilterMobile">
        <p>{{ providerTabSelected.title }}</p>
      </button>

      <!-- BTN ACTIVE FILTER MOBILE -->
      <button v-if="showProductListSelectionFilter" @click="showTabs = !showTabs" class="btnFilterMobile">
        <p>{{ stockTabSelected.title }}</p>
      </button>
    </article>

    <div v-if="drawer" @click="drawer = !drawer" :class="{ 'overlay-drawer': drawer }"></div>
    <!-- NAV MOBILE -->
    <nav :class="{ active: drawer }">
      <ul>
        <li @click="drawer = false">
          <router-link v-if="hasToShowThisOption(0) && !hasToShowProductsPosTab" 
          to="/productsV2" :class="{ active: option === 0 && !isDashboardActive }">
            <p>PRODUCTOS</p>
          </router-link>
          <router-link v-else-if="hasToShowThisOption(11) && hasToShowProductsPosTab" 
          to="/productsPdv" :class="{ active: option === 11 && !isDashboardActive}">
            <p>PRODUCTOS</p>
          </router-link>
        </li>
        <li v-if="hasToShowThisOption(2)" @click="drawer = false">
          <router-link to="/dashboard/indexV2" :class="{ active: isDashboardActive }">
            <p>VENTAS Y REPORTES</p>
          </router-link>
        </li>


        <li v-if="hasToShowThisOption(2)" @click="drawer = false">
          <router-link to="/dashboard/indexV2" :class="{ 'active-2': optionDashboard === 0 }" class="new-child">
            <figure>
              <span class="icon-Bistro-_Dashboard"></span>
            </figure>
            <p>Tablero</p>
          </router-link>
        </li>

        <li v-if="hasToShowThisOption(2)" @click="drawer = false">
          <router-link to="/dashboard/consolidatedV2"
            :class="{ 'active-2': optionDashboard === 1 }" class="new-child">
            <figure>
              <span class="icon-Bistro-_Comandas"></span>
            </figure>
            <p>Consolidado</p>
          </router-link>
        </li>

        <li v-if="showBillingPage && hasToShowThisOption(2)" @click="drawer = false">
          <router-link to="/dashboard/billingDetail"
            :class="{ 'active-2': optionDashboard === 2 }" class="new-child">
            <figure>
              <span class="icon-Bistro-_TDC"></span>
            </figure>
            <p>Facturación</p>
          </router-link>
        </li>

        <li v-if="hasToShowThisOption(2)" @click="drawer = false">
          <router-link :to="hasToShowReportPosTab ? '/dashboard/reportPDV' : '/dashboard/report'"
            :class="{ 'active-2': optionDashboard === 3 }" class="new-child">
            <figure>
              <span class="icon-Bistro-_FileDoble"></span>
            </figure>
            <p>Reportes</p>
          </router-link>
        </li>

        <li @click="drawer = false">
          <router-link v-if="hasToShowThisOption(1)" to="/clientsV2" :class="{ active: option === 1 }">
            <p>CLIENTES</p>
          </router-link>
        </li>


        <li @click="drawer = false">
          <router-link v-if="hasToShowThisOption(3)" to="/providersV2" :class="{ active: option === 3 }">
            <p>PROVEEDORES</p>
          </router-link>
        </li>

        <li @click="drawer = false">
          <router-link v-if="hasToShowThisOption(5) && !hasToShowCashPosTab"
            to="/box" :class="{ active: option ===  5 }">
            <p>CAJA</p>
          </router-link>
          <router-link v-if="hasToShowThisOption(10) && hasToShowCashPosTab"
            to="/boxPos" :class="{ active: option ===  10 }">
            <p>CAJA</p>
          </router-link>
        </li>

        <li @click="drawer = false">
          <router-link v-if="hasToShowThisOption(6)" to="/usersV2" :class="{ active: option === 6 }">
            <p>USUARIOS</p>
          </router-link>
        </li>

        <li @click="drawer = false">
          <router-link v-if="hasToShowThisOption(9)" to="/pdv/orders" :class="{ active: option === 7 }">
            <p>PDV</p>
          </router-link>
        </li>
      </ul>
    </nav>

    <!-- CALENDARIO -->
    <article v-if="!elements.hideCalendar" class="headerOptions">
      <section class="calendar">
        <div class="calendar-title">
          <figure>
            <span class="icon-Bistro-_calendar"></span>
          </figure>
          <div class="datepicker-trigger">
            <h3 @click="showCalendar" id="datepicker-trigger">{{ date }}</h3>
          </div>
        </div>
      </section>
    </article>

    <MerchantSelectionFilter v-if="showProductListSelectionFilter && !isShowingCentralizedStock"
      :optionList="productLists" @onSelectOption="onProductsListChanged" />

    <MerchantSelectionFilter v-if="showProviderListSelectionFilter" :optionList="providerLists"
      @onSelectOption="onProvidersListChanged" />

    <MerchantSelectionFilter v-if="showMenuEngineeringListSelectionFilter" :optionList="menuEngineeringLists"
      @onSelectOption="onMenuEngineeringListChanged" />

    <MerchantSelectionFilter v-if="showUserListSelectionFilter" :optionList="userList"
      @onSelectOption="onUserListChanged" />

    <MerchantSelectionFilter v-if="showSettingListSelectionFilter" :optionList="settingList"
      @onSelectOption="onSettingListChanged" />

    <MerchantSelectionFilter v-if="showClientListSelectionFilter" :optionList="clientList"
      @onSelectOption="onCustomerListChanged" />
  </header>
</template>
<script>
import mixCommons from "../mixins/mixCommons";
import headerCommons from "../mixins/headerCommons";
import providerCommons from "../mixins/providerCommons";
import stockV2Commons from "../mixins/stockV2Commons";
import rolesCommons from "../mixins/rolesCommons";
import mixTester from "../mixins/mixTester";
import $ from "jquery";
import ShopFilter from "../components/ShopFilter";
import BistroLogo from "../components/BistroLogo";
import MerchantSelectionFilter from "../components/MerchantSelectionFilter";
import moment from "moment";
import pjson from "../../package.json";
moment.locale("es");

export default {
  components: {
    ShopFilter,
    BistroLogo,
    MerchantSelectionFilter,
  },
  mixins: [
    mixCommons,
    stockV2Commons,
    providerCommons,
    headerCommons,
    rolesCommons,
    mixTester,
  ],
  data() {
    return {
      hasToShowGeneralFilter: false,
      drawer: false,
      showTabs: false,
      version: pjson.version, // 'v1.13',
      // mobile: false,
      dateFormat: "D MMM",
      dateOne: "",
      dateTwo: "",
      options: [
        {
          id: 0,
          title: "PRODUCTOS",
          url: "/productsV2",
          show: true
        },
        {
          id: 11,
          title: "PRODUCTOS",
          url: "/productspdv",
          show: false,
        },
        {
          id: 2,
          title: "VENTAS Y REPORTES",
          url: "/dashboard/indexV2",
          show: true,
          childs: [
            {
              title: "Tablero",
              url: "/dashboard/indexV2",
              icon: "icon-Bistro-_Dashboard",
              show: true,
            },
            {
              title: "Consolidado",
              url: "/dashboard/consolidatedV2",
              icon: "icon-Bistro-_Comandas",
              show: true,
            },
            {
              title: "Facturación",
              url: "/dashboard/billingDetail",
              icon: "icon-Bistro-_TDC",
              show: false,
            },
            {
              title: "Reportes",
              url: "/dashboard/report",
              icon: "icon-Bistro-_FileDoble",
              show: false,
            },
            {
              title: "Reportes",
              url: "/dashboard/reportPDV",
              icon: "icon-Bistro-_FileDoble",
              show: false,
            },
          ],
        },
        {
          id: 1,
          title: "CLIENTES",
          url: "/clientsV2",
          show: true
        },
        {
          id: 3,
          title: "PROVEEDORES",
          url: "/providersV2",
          show: true
        },
        {
          id: 5,
          title: "CAJA",
          url: "/box",
          show: true
        },
        {
          id: 10,
          title: "CAJA",
          url: "/boxPos",
          show: false
        },
        {
          id: 6,
          title: "USUARIOS",
          url: "/usersV2",
          show: true
        },
        {
          id: 9,
          title: "PDV",
          url: "/pdv/orders",
          show: false,
        },
      ],
      showChangePassword: true
    };
  },
  computed: {
    selectedProvidersList() {
      if (this.providerLists[0] && !this.$store.getRefreshProviderList) {
        this.onProvidersListChanged(this.providerLists[0].shopCode);
        return this.providerLists[0].shopCode;
      }
      return this.$store.getRefreshProviderList;
    },
    option() {
      return this.$store.getTopMenu;
    },
    optionDashboard() {
      if(!this.$route.path.startsWith('/dashboard/')) return false
      return this.$store.getSideMenu;
    },
    dropdownClassSelector() {
      return {
        'dropdown-filter': true,
        'filterPDV-dropdown': true,
        'dropdown-filter-show': this.hasToShowGeneralFilter,
      };
    },
    dropdownOptionClassSelector() {
      return {
        'dropdown-options': true,
        'dropdown-options-active': this.selectedPdvs?.length > 0,
      };
    },
    hasToShowMyPayments() {
      if (process.env.VUE_APP_INSTANCE_CODE === 'MX') {
        return true
      } else {
        return false
      }
    },
    isDashboardActive() {
      return this.$route.path.startsWith('/dashboard/')
    }
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.getWindowWidth);
    window.removeEventListener("resize", this.getWindowHeight);
  },
  mounted() {
    /* eslint-disable */
    const dropdown = document.querySelectorAll(".dropdown-trigger");
    M.Dropdown.init(dropdown);
    /* eslint-enable */

    this.$nextTick(function () {
      window.addEventListener("resize", this.getWindowWidth);
      window.addEventListener("resize", this.getWindowHeight);
      // Init
      this.getWindowWidth();
      this.getWindowHeight();

      this.showChangePassword = !this.isAutomaticLogin();
    });

    const itemsToUpdate = [9, 10, 11]; 
    this.options
      .filter(option => itemsToUpdate.includes(option.id))
      .forEach(itemToUpdate => {
        itemToUpdate.show = this.hasToShowPosTab; 

        // Si el ID 10 está configurado para mostrarse, el ID 5 no debe mostrarse
        if (itemToUpdate.id === 10 && itemToUpdate.show) {
          const option = this.options.find(option => option.id === 5);
          if (option) {
            option.show = false; 
          }
        }

        if (itemToUpdate.id === 11 && itemToUpdate.show) {
          const option = this.options.find(option => option.id === 0)
          if (option) {
            option.show = false
          }
        }
      })
      
  },
  methods: {
    showGeneralFilter() {
      this.hasToShowGeneralFilter = !this.hasToShowGeneralFilter;
    },
    applyDate() {
      let tab = this.tabsFilter.find((item) => {
        return item.title === "Personalizado";
      });

      tab.param = `ConfigurablePeriod${this.dateOne !== "" ? "&From=" + this.dateOne : ""
        }${this.dateTwo !== "" ? "&Until=" + this.dateTwo : ""}`;
      this.changeTab(tab);
      this.formatDates(this.dateOne, this.dateTwo);
    },
    getWindowWidth() {
      this.windowWidth = document.documentElement.clientWidth;
      if (this.windowWidth <= 991) {
        // this.mobile = true
        setTimeout(() => {
          $(".dropdown-trigger").unbind("click");
          //$(".dropdown-trigger").dropdown();
          /* eslint-disable */
          var dropdown = document.getElementsByClassName("dropdown-trigger");
          var instance = M.Dropdown.init(dropdown);
          //instance.open();
          /* eslint-enable */
        }, 1000);
      } else {
        // this.mobile = false
      }
    },
    getWindowHeight() {
      this.windowHeight = document.documentElement.clientHeight;
    },
    isActive(item) {
      if (this.$route.path === item.url) {
        return true;
      }

      if (item.childs) {
        return this.isDashboardActive
      }

      return false;
    },
  },
};
</script>
<style lang="scss">
.headerBistro {
  display: flex;
}


.mobile-filter-applied {
  background-color: #37c866 !important;
}
</style>
