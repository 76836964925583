import { defineStore } from "pinia";
//import axios from "axios";

export const useGlobalStore = defineStore("global", {
  state: () => {
    return {
      authUser: null,
      merchantTags: null,
      topMenu: 0,
      sideMenu: 0,
      news: null,
      debt: null,
      hasToShowIngredientsSection: false,
      hasAlreadyShownDebts: false,
      debtPaymentStatus: null,
      refreshProductList: null,
      refreshProviderList: null,
      refreshPDVList: null,
      refreshMenuEngineeringList: null,
      refreshUserList: null,
      refreshSettingList: null,
      refreshClientList: null,
      backendContext: {},
      fiscalKeyNameInCulture: "Fiscal Key",
      productActionSelected: "product",
      providerActionSelected: "provider",
      hasToShowProviderFilters: true,
      refreshSelectedShopCode: null,
      currentShopMyPayments: null,
      currentShopCodeMyPayments: null,
      clientSelectedShop: null,
      showMerchantSelectionFilter: true,
      showProductListSelectionFilter: false,
      showPDVListSelectionFilter: false,
      showProviderListSelectionFilter: false,
      showMenuEngineeringListSelectionFilter: false,
      showClientListSelectionFilter: false,
      showUserListSelectionFilter: false,
      showSettingListSelectionFilter: false,
      showMyPaymentsListSelectionFilter: false,
      tabSelected: {},
      hasToSelectFirstItem: false,
      currentShopTheme: 1, //Gastronómico
      hasToSelectFirstClientItem: false,
      merchantSelected: [],
      productLists: [],
      providerLists: [],
      menuEngineeringLists: [],
      userList: [],
      PDVList: [],
      settingList: [],
      myPaymentsList: [],
      clientList: [],
      calendarType: "range",
      hideHeader: {
        hideCalendar: false,
        hideFilters: false,
        hideTabs: false,
        hideSomeTabs: false,
      },
      loading: false,
      progress: 0,
      progressInterval: 0,
      mobile: false,
      modalInfo: {
        info: {},
      },
      modalInfoV2: {
        extendedInfo: {},
      },
      dateCalendar: "Seleccione fecha",
      provider: {},
      editStockMovementList: {},
      logoUrl: null,
      backgroundMenuUrl: null,
      profileShops: [],
      originalProfileShops: [],
      profileQrs: [],
      profileTableQrs: [],
      modalCustomerTransactions: [],
      step: 1,
      dueDays: 0,
      currentReceipt: null,
      loadingSkeleton: false,
      isShowingCentralizedStock: false,
      centralizedStock: {
        allCategories: {},
        shopNames: {},
        products: {},
        selectedCategories: [],
        selectedShopNames: [],
      }, //contiene todos los productos con sus respectivas cantidades
      currentCost: null,
      hasMounted: false,
      cashboxData: {}
    };
  },
  getters: {
    getLoadingSkeleton: (state) => state.loadingSkeleton,
    times2: (state) => state.count * 2,
    getAuthUser: (state) => state.authUser,
    getNews: (state) => state.news,
    getSideMenu: (state) => state.sideMenu,
    getTopMenu: (state) => state.topMenu,
    getProductLists: (state) => state.productLists,
    getHasToShowIngredientsSection: (state) =>
      state.hasToShowIngredientsSection,
    getShopsThatHaveToShowIngredients: (state) =>
      state.backendContext?.shopsThatHaveToShowIngredients,
    getProductsThatHaveProductionCosts: (state) =>
      state.backendContext?.productsThatHaveProductionCosts,
    getEditStockMovementList: (state) => state.editStockMovementList,
    getDebt: (state) => state.debt,
    getProductActionSelected: (state) => state.productActionSelected,
    getProviderLists: (state) => state.providerLists,
    getMenuEngineeringLists: (state) => state.menuEngineeringLists,
    getMobile: (state) => state.mobile,
    getHasAlreadyShownDebts: (state) => state.hasAlreadyShownDebts,
    getDueDays: (state) => state.dueDays,
    getTabSelected: (state) => state.tabSelected,
    getDateCalendar: (state) => state.dateCalendar,
    getSelectedMerchants: (state) => state.merchantSelected,
    getHideHeader: (state) => state.hideHeader,
    getMerchantTags: (state) => state.merchantTags,
    getHasToSelectFirstItem: (state) => state.hasToSelectFirstItem,
    getCurrentShopTheme: (state) => state.currentShopTheme,
    getRefreshClientList: (state) => state.refreshClientList,
    getRefreshSelectedShopCode: (state) => state.refreshSelectedShopCode,
    getRefreshSelectedShopMyPayments: (state) => state.currentShopMyPayments,
    getRefreshSelectedShopCodeMyPayments: (state) => state.currentShopCodeMyPayments,
    getModalCustomerTransactions: (state) => state.modalCustomerTransactions,
    getRefreshUserList: (state) => state.refreshUserList,
    getHasToSelectFirstClientItem: (state) => state.hasToSelectFirstClientItem,
    getUserList: (state) => state.userList,
    getPDVList: (state) => state.PDVList,
    getSettingList: (state) => state.settingList,
    getMyPaymentsList: (state) => state.myPaymentsList,
    getClientList: (state) => state.clientList,
    getCashBox:(state) => state.cashboxData,
    getRefreshProviderList: (state) => state.refreshProviderList,
    getRefreshPDVList: (state) => state.refreshPDVList,
    getShowMerchantSelectionFilter: (state) =>
      state.showMerchantSelectionFilter,
    getLoading: (state) => state.loading,
    getProgress: (state) => state.progress,
    getShowProductListSelectionFilter: (state) =>
      state.showProductListSelectionFilter,
    getShowPDVListSelectionFilter: (state) =>
      state.showPDVListSelectionFilter,
    getShowProviderListSelectionFilter: (state) =>
      state.showProviderListSelectionFilter,
    getShowMenuEngineeringListSelectionFilter: (state) =>
      state.showMenuEngineeringListSelectionFilter,
    getShowClientListSelectionFilter: (state) =>
      state.showClientListSelectionFilter,
    getShowUserListSelectionFilter: (state) =>
      state.showUserListSelectionFilter,
    getShowSettingListSelectionFilter: (state) =>
      state.showSettingListSelectionFilter,
    getShowMyPaymentsListSelectionFilter: (state) =>
      state.showMyPaymentsListSelectionFilter,
    getProviderActionSelected: (state) => state.providerActionSelected,
    getHasToShowProviderFilters: (state) => state.hasToShowProviderFilters,
    getProfileShops: (state) => state.profileShops,
    getBackgroundMenuUrl: (state) => state.backgroundMenuUrl,
    getProfileQrs: (state) => state.profileQrs,
    getProfileTableQrs: (state) => state.profileTableQrs,
    getOriginalProfileShops: (state) => state.originalProfileShops,
    getCalendarType: (state) => state.calendarType,
    getModalInfoV2: (state) => state.modalInfoV2,
    getRefreshProductList: (state) => state.refreshProductList,
    getShopsThatHaveToShowSecondaryProductPictures: (state) =>
      state.backendContext?.shopsThatHaveToShowSecondaryProductPictures,
    getShopsThatAreBoundToLocal: (state) => state.backendContext?.shopBindings,
    getShowingCentralizedStock: (state) => state.isShowingCentralizedStock,
    getShopsThatHaveToShowCentralizedStock: (state) =>
      state.backendContext?.shopsThatHaveToShowCentralizedStock,
    getCentralizedStockSelectedCategories: (state) =>
      state.centralizedStock.selectedCategories,
    getCentralizedStockSelectedShopNames: (state) =>
      state.centralizedStock.selectedShopNames,
    getCentralizedStockAllCategories: (state) =>
      state.centralizedStock.allCategories,
    getCentralizedStockShopNames: (state) => state.centralizedStock.shopNames,
    getCentralizedStockProducts: (state) => state.centralizedStock.products,
    getStep: (state) => state.step,
    getCurrentReceipt: (state) => state.currentReceipt,
    getCurrentCost: (state) => state.currentCost,
    getShopThemes: (state) => state.backendContext?.shopThemes,
    getShopsThatHaveToShowBreakStock: (state) =>
      state.backendContext?.shopsThatHaveToShowBreakStock,
    getCentralizedProductsList: (state) =>
      state.backendContext?.centralizedProductsList,
    getFdsSyncInformationList: (state) => state.backendContext?.fdsSyncInfoList,
    getRefreshMenuEngineeringList: (state) => state.refreshMenuEngineeringList,
    getDebtPaymentStatus: (state) => state.debtPaymentStatus
  },
  actions: {

    SET_USER(user) {
      this.authUser = user;
    },
    SET_MERCHANT_TAGS(merchantTags) {
      this.merchantTags = merchantTags;
    },
    UPDATE_TAB_SELECTED: function (tab) {
      this.tabSelected = tab;
    },
    UPDATE_MOBILE(value) {
      this.mobile = value;
    },
    UPDATE_MERCHANT_SELECTED: function (merchant) {
      let toDelete = null;
      let exist = this.merchantSelected.find((item, index) => {
        if (item.shopCode === merchant.shopCode) {
          toDelete = index;
        }
        return item.shopCode === merchant.shopCode;
      });

      if (!exist) {
        this.merchantSelected.push(merchant);
      } else {
        this.merchantSelected.splice(toDelete, 1);
      }
    },
    initTabs() {
      this.UPDATE_TAB_SELECTED({ id: 1, title: "Hoy", param: "Today" });
      this.UPDATE_CALENDAR("Seleccione fecha");
    },
    initTabReport() {
      this.UPDATE_TAB_SELECTED({ id: 4, title: "Mes", param: "Month" });
      this.UPDATE_CALENDAR("Seleccione fecha");
    },
    updateTabSelected(tab) {
      this.UPDATE_TAB_SELECTED(tab);
    },
    setLoadingSkeleton(value){
      this.loadingSkeleton = value
    },
    toggleMerchantSelected(merchant) {
      this.UPDATE_MERCHANT_SELECTED(merchant);
    },
    toggleMobile(value) {
      this.UPDATE_MOBILE(value);
    },

    RESET_SELECTED_MERCHANTS() {
      this.merchantSelected = [];
    },
    resetSelectedMerchants() {
      this.RESET_SELECTED_MERCHANTS();
    },

    SELECT_MERCHANT(merchant) {
      this.merchantSelected.push(merchant);
    },
    selectMerchant(merchant) {
      this.SELECT_MERCHANT(merchant);
    },

    TOGGLE_LOADING(loading) {
      this.loading = loading;
    },
    setCashBox(data){
      this.cashboxData = data
    },
    TOGGLE_PROGRESS() {
      this.progress = 0;
      this.progressInterval = setInterval(() => {
        if (this.progress <= 90) {
          this.progress += 1;
        }
      }, 100);
    },
    initLoading(data) {
      this.TOGGLE_PROGRESS();
      data.$nextTick(() => {
        this.TOGGLE_LOADING(true);
      });
    },
    finishLoading(data) {
      this.progress = 100;
      clearInterval(this.progressInterval);
      data.$nextTick(() => {
        setTimeout(() => {
          this.TOGGLE_LOADING(false);
        }, 300);
      });
    },
    errorLoading(data) {
      data.$nextTick(() => {
        this.TOGGLE_LOADING(false);
        this.setLoadingSkeleton(false)

      });
    },
    setHasMounted(value) {
      this.hasMounted = value;
    },
    SET_STOCK_MOVEMENTS(value) {
      this.editStockMovementList = value;
    },
    setStockMovements(value) {
      this.SET_STOCK_MOVEMENTS(value);
    },

    UPDATE_CALENDAR(date) {
      this.dateCalendar = date;
    },
    updateDate(date) {
      this.UPDATE_CALENDAR(date);
    },

    SHOW_MERCHANT_SELECTION_FILTER(value) {
      this.showMerchantSelectionFilter = value;
    },
    setShowMerchantSelectionFilter(opt) {
      this.SHOW_MERCHANT_SELECTION_FILTER(opt);
    },

    SHOW_PRODUCT_LIST_SELECTION_FILTER(value) {
      this.showProductListSelectionFilter = value;
    },
    setShowProductListSelectionFilter(opt) {
      this.SHOW_PRODUCT_LIST_SELECTION_FILTER(opt);
    },
    SHOW_PDV_LIST_SELECTION_FILTER(value) {
      this.showPDVListSelectionFilter = value;
    },
    setShowPDVListSelectionFilter(opt) {
      this.SHOW_PDV_LIST_SELECTION_FILTER(opt);
    },
    setHasAlreadyShownDebts(opt) {
      this.SET_HAS_ALREADY_SHOWN_DEBTS(opt);
    },
    SET_HAS_ALREADY_SHOWN_DEBTS(value) {
      this.hasAlreadyShownDebts = value;
    },
    SHOW_PROVIDER_LIST_SELECTION_FILTER(value) {
      this.showProviderListSelectionFilter = value;
    },
    setShowProviderListSelectionFilter(opt) {
      this.SHOW_PROVIDER_LIST_SELECTION_FILTER(opt);
    },

    SHOW_MENU_ENGINEERING_SELECTION_FILTER(value) {
      this.showMenuEngineeringListSelectionFilter = value;
    },
    setShowMenuEngineeringListSelectionFilter(opt) {
      this.SHOW_MENU_ENGINEERING_SELECTION_FILTER(opt);
    },
    setRefreshMenuEngineeringList(opt) {
      this.REFRESH_ENGINEERING_LIST(opt);
    },
    REFRESH_ENGINEERING_LIST(value) {
      this.refreshMenuEngineeringList = value;
    },
    SHOW_CLIENT_LIST_SELECTION_FILTER(value) {
      this.showClientListSelectionFilter = value;
    },
    setShowClientListSelectionFilter(opt) {
      this.SHOW_CLIENT_LIST_SELECTION_FILTER(opt);
    },
    CLIENT_LIST(value) {
      this.clientList = value;
    },
    setClientList(opt) {
      this.CLIENT_LIST(opt);
    },
    REFRESH_CLIENT_LIST(value) {
      this.refreshClientList = value;
    },
    setRefreshClientList(opt) {
      this.REFRESH_CLIENT_LIST(opt);
    },
    SET_CLIENT_SELECTED_SHOP(value) {
      this.clientSelectedShop = value;
    },
    setClientSelectedShop(opt) {
      this.SET_CLIENT_SELECTED_SHOP(opt);
    },
    HAS_TO_SELECT_FIRST_CLIENT_ITEM(value) {
      this.hasToSelectFirstClientItem = value;
    },
    setHasToSelectFirstClientItem(opt) {
      this.HAS_TO_SELECT_FIRST_CLIENT_ITEM = opt;
    },
    REFRESH_SELECTED_SHOP_CODE(value) {
      this.refreshSelectedShopCode = value;
    },
    REFRESH_SELECTED_SHOP_MY_PAYMENTS(value, code) {
      this.currentShopMyPayments = value;
      this.currentShopCodeMyPayments = code;
    },

    setRefreshSelectedShopCode(opt) {
      this.REFRESH_SELECTED_SHOP_CODE(opt);
    },
    setCurrentShopMyPayments(opt, code) {
      this.REFRESH_SELECTED_SHOP_MY_PAYMENTS(opt, code);
    },

    SHOW_USER_LIST_SELECTION_FILTER(value) {
      this.showUserListSelectionFilter = value;
    },
    setShowUserListSelectionFilter(opt) {
      this.SHOW_USER_LIST_SELECTION_FILTER(opt);
    },
    USER_LIST(value) {
      this.userList = value;
    },
    setUserList(opt) {
      this.USER_LIST(opt);
    },
    PDV_LIST(value) {
      this.PDVList = value;
    },
    setPDVList(opt) {
      this.PDV_LIST(opt);
    },
    SETTING_LIST(value) {
      this.settingList = value;
    },
    MY_PAYMENTS_LIST(value) {
      this.myPaymentsList = value;
    },
    setSettingList(opt) {
      this.SETTING_LIST(opt);
    },
    setMyPaymentsList(opt) {
      this.MY_PAYMENTS_LIST(opt);
    },
    setUpdateProfileShops(opt) {
      this.UPDATE_PROFILE_SHOPS(opt);
    },
    UPDATE_PROFILE_SHOPS(value) {
      this.profileShops = value;
    },
    setUpdateOriginalProfileShops(opt) {
      this.UPDATE_ORIGINAL_PROFILE_SHOPS(opt);
    },
    UPDATE_ORIGINAL_PROFILE_SHOPS(value) {
      this.originalProfileShops = value;
    },
    setUpdateBackgrounMenuUrl(opt) {
      this.UPDATE_BACKGROUND_MENU_URL(opt);
    },
    UPDATE_BACKGROUND_MENU_URL(value) {
      this.backgroundMenuUrl = value;
    },
    setUpdateProfileQrs(opt) {
      this.UPDATE_PROFILE_QRS(opt);
    },
    UPDATE_PROFILE_QRS(value) {
      this.profileQrs = value;
    },
    setUpdateProfileTableQrs(opt) {
      this.UPDATE_PROFILE_TABLE_QRS(opt);
    },
    UPDATE_PROFILE_TABLE_QRS(value) {
      this.profileTableQrs = value;
    },
    HAS_TO_SELECT_FIRS_ITEM(value) {
      this.hasToSelectFirstItem = value;
    },
    setHasToSelectFirstItem(opt) {
      this.HAS_TO_SELECT_FIRS_ITEM(opt);
    },
    REFRESH_USER_LIST(value) {
      this.refreshUserList = value;
    },
    setRefreshUserList(opt) {
      this.REFRESH_USER_LIST(opt);
    },
    SHOW_SETTING_LIST_SELECTION_FILTER(value) {
      this.showSettingListSelectionFilter = value;
    },
    setShowSettingListSelectionFilter(opt) {
      this.SHOW_SETTING_LIST_SELECTION_FILTER(opt);
    },
    setShowMyPaymentsListSelectionFilter(opt) {
      this.SHOW_MY_PAYMENTS_LIST_SELECTION_FILTER(opt);
    },
    SHOW_MY_PAYMENTS_LIST_SELECTION_FILTER(value) {
      this.showMyPaymentsListSelectionFilter = value;
    },
    PROVIDER_ACTION_SELECTED(providerActionSelected) {
      this.providerActionSelected = providerActionSelected;
    },
    setProviderActionSelected(value) {
      this.PROVIDER_ACTION_SELECTED(value);
    },
    SET_RECEIPT_FOR_EDITION(opt) {
      this.currentReceipt = opt;
    },
    setReceiptForEdition(value) {
      this.SET_RECEIPT_FOR_EDITION(value);
    },
    PROVIDER_LISTS(value) {
      this.providerLists = value;
    },
    setProviderLists(opt) {
      this.PROVIDER_LISTS(opt);
    },
    SET_FISCAL_KEY_NAME_IN_CULTURE(value) {
      this.fiscalKeyNameInCulture = value;
    },
    setFiscalKeyNameInCulture(opt) {
      this.SET_FISCAL_KEY_NAME_IN_CULTURE(opt);
    },
    setRefreshProviderList(opt) {
      this.REFRESH_PROVIDER_LIST(opt);
    },
    REFRESH_PROVIDER_LIST(value) {
      this.refreshProviderList = value;
    },
    HAS_TO_SHOW_PROVIDER_FILTERS(opt) {
      this.hasToShowProviderFilters = opt;
    },
    setHasToShowProviderFilters(value) {
      this.HAS_TO_SHOW_PROVIDER_FILTERS(value);
    },
    LOAD_BACKEND_CONTEXT(context) {
      this.backendContext = context;
    },
    loadBackendContext(value) {
      this.LOAD_BACKEND_CONTEXT(value);
    },
    UPDATE_TOPMENU: function (opt) {
      this.topMenu = opt;
    },
    updateTopMenu(opt) {
      this.UPDATE_TOPMENU(opt);
    },
    UPDATE_SIDEMENU: function (opt) {
      this.sideMenu = opt;
    },
    updateSideMenu(opt) {
      this.UPDATE_SIDEMENU(opt);
    },
    HIDE_HEADER_ELEMENTS: function (obj) {
      this.hideHeader = obj;
    },
    hideElements(obj) {
      this.HIDE_HEADER_ELEMENTS(obj);
    },
    SET_CALENDAR_TYPE: function (type) {
      this.calendarType = type;
    },
    updateCalendarType(type) {
      this.SET_CALENDAR_TYPE(type);
    },
    CALL_MERCHANTS: function () {
      this.merchantSelected = this.merchantSelected.map((item) => {
        item.change = !item.change;
        return item;
      });
    },

    SET_DEBT_PAYMENT_STATUS: function (debtPaymentStatus) {
      this.debtPaymentStatus = debtPaymentStatus;
    },
    setDebtPaymentStatus(value) {
      this.SET_DEBT_PAYMENT_STATUS(value);
    },

    SET_DEBT: function (debt) {
      this.debt = debt;
    },
    setDebt(value) {
      this.SET_DEBT(value);
    },

    SET_NEWS: function (news) {
      this.news = news;
    },
    setNews(value) {
      this.SET_NEWS(value);
    },

    SET_DUE_DAYS: function (dueDays) {
      this.dueDays = dueDays;
    },
    setDueDays(days) {
      this.SET_DUE_DAYS(days);
    },

    UPDATE_MODAL_INFO_V2: function (info) {
      this.modalInfoV2 = info;
    },
    updateModalInfoV2(info) {
      this.UPDATE_MODAL_INFO_V2(info);
    },

    UPDATE_MODAL_INFO: function (info) {
      this.modalInfo = info;
    },
    updateModalInfo(info) {
      this.UPDATE_MODAL_INFO(info);
    },
    SET_SHOWING_CENTRALIZED_STOCK(value) {
      this.isShowingCentralizedStock = value;
    },
    LOAD_CATEGORIES_AND_SHOPNAMES: function (context) {
      this.centralizedStock.allCategories = context?.categories;
      this.centralizedStock.shopNames = context?.shopNames;
    },
    LOAD_CENTRALIZED_STOCK_PRODUCTS: function (context) {
      this.centralizedStock.products = context;
    },
    SET_CENTRALIZED_STOCK_SELECTED_CATEGORIES: function (context) {
      this.centralizedStock.selectedCategories = context;
    },
    SET_CENTRALIZED_STOCK_SELECTED_SHOPNAMES: function (context) {
      this.centralizedStock.selectedShopNames = context;
    },
    setShowingCentralizedStock(value) {
      this.SET_SHOWING_CENTRALIZED_STOCK(value);
    },
    loadCategoriesAndShopNames(value) {
      this.LOAD_CATEGORIES_AND_SHOPNAMES(value);
    },
    loadCentralizedStockProducts(value) {
      this.LOAD_CENTRALIZED_STOCK_PRODUCTS(value);
    },
    setCentralizedStockSelectedCategories(value) {
      this.SET_CENTRALIZED_STOCK_SELECTED_CATEGORIES(value);
    },
    setCentralizedStockSelectedShopNames(value) {
      this.SET_CENTRALIZED_STOCK_SELECTED_SHOPNAMES(value);
    },
    SET_PRODUCT_LISTS(prodLists) {
      this.productLists = prodLists;
    },
    setProductLists(value) {
      this.SET_PRODUCT_LISTS(value);
    },
    SET_REFRESH_PRODUCT_LIST: function (event) {
      this.refreshProductList = event;
    },
    setRefreshProductList(event) {
      this.SET_REFRESH_PRODUCT_LIST(event);
    },
    SET_REFRESH_PDV_LIST: function (event) {
      this.refreshPDVList = event;
    },
    setRefreshPDVList(event) {
      this.SET_REFRESH_PDV_LIST(event);
    },
    HAS_TO_SHOW_INGREDIENTS_SECTION: function (val) {
      this.hasToShowIngredientsSection = val;
    },
    setHasToShowIngredientsSection(value) {
      this.HAS_TO_SHOW_INGREDIENTS_SECTION(value);
    },

    PRODUCT_ACTION_SELECTED(productActionSelected) {
      this.productActionSelected = productActionSelected;
    },
    setProductActionSelected(value) {
      this.PRODUCT_ACTION_SELECTED(value);
    },
    CURRENT_COST(cost) {
      this.currentCost = cost;
    },
    setCurrentCost(value) {
      this.CURRENT_COST(value);
    },
    MENU_ENGINEERING_LIST(value) {
      this.menuEngineeringLists = value;
    },
    setMenuEngineeringLists(value) {
      this.MENU_ENGINEERING_LIST(value);
    },
    SET_CURRENT_SHOP_THEME(value) {
      this.currentShopTheme = value;
    },
    setCurrentShopTheme(value) {
      this.SET_CURRENT_SHOP_THEME(value);
    },
  },
});
