import { createRouter, createWebHistory } from "vue-router";
import BistroDashboardV2 from "./pages/BistroDashboardV2.vue";
import BistroConsolidated from "./pages/BistroConsolidated";
import BistroLogin from "./pages/BistroLogin.vue";
import dashboard from "./layouts/dashboardDefault.vue";
import dashboardNoMenu from "./layouts/dashboardNoMenu.vue";
import pdvLayout from "./features/pdv/layouts/pdvLayout.vue";
import { useGlobalStore } from "./store/global.js";
import axios from "axios";
import BillViewer from "./pages/billViewer.vue";
import TicketsViewer from "./pages/ticketsViewer.vue";
import BistroProducts from "./pages/BistroProducts.vue";
import BistroBox from "./pages/BistroBox.vue";
import AutomaticLogin from "./pages/AutomaticLogin.vue";
import BistroMenuEngineering from "./pages/BistroMenuEngineering.vue";
import EventsV2 from './pages/EventsV2.vue'
import Password from "./pages/Password.vue";
import ClientsV2 from "./pages/BistroClientsV2.vue";
import ClientsDetailsV2 from  "./pages/ClientsDetailsV2.vue"
import ProvidersV2 from  "./pages/BistroProvidersV2.vue"
import UsersV2 from  "./pages/BistroUsersV2.vue"
import userDetailsV2 from  "./pages/usersDetailsV2.vue"
import SettingsV2 from  "./pages/SettingsV2.vue"
import MyPayments from  "./pages/MyPayments.vue"
import BillingDetail from  "./pages/dashboard/BillingDetail.vue"
import indexReports from  "./pages/dashboard/report/indexReports.vue"
import BoxReports from  "./pages/dashboard/report/BoxReports.vue"
import SalesReports from  "./pages/dashboard/report/RankingSales.vue"
import DailyRankingSales from  "./pages/dashboard/report/DailyRankingSales.vue"
import Days from  "./pages/dashboard/report/ReportDays.vue"
import Month from  "./pages/dashboard/report/ReportMonth.vue"
import TransactionDetailReport from  "./pages/dashboard/report/TransactionDetailReport.vue"
import AdditionalsReport from  "./pages/dashboard/report/AdditionalsReport.vue"
import StockMonthlyReport from  "./pages/dashboard/report/StockMonthlyReport.vue"
import ComboSalesReport from  "./pages/dashboard/report/ComboSalesReport.vue"
import WaiterReport from  "./pages/dashboard/report/WaiterReport.vue"
import MenuProfitabilityReport from  "./pages/dashboard/report/MenuProfitabilityReport.vue"
import mixCommons from "./mixins/mixCommons";



/* PDV */
import PdvOrders from "./features/pdv/pages/PdvOrders.vue";
import PdvOrderDetails from "./features/pdv/pages/PdvOrderDetails.vue";
// import PdvTables from "./features/pdv/pages/PdvTables.vue";
import PdvOrderCheckout from "./features/pdv/pages/PdvOrderCheckout.vue";
import IndexReportsPDV from "./pages/dashboard/report/IndexReportsPDV.vue";
import SettingsPos from "./features/pdv/pages/SettingsPos.vue";
import BoxPos from "./features/pdvBox/pages/BoxPos.vue";
import BistroProductsPDV from "@/features/pdvProducts/pages/BistroProductsPDV.vue"

const routes = [
  { path: "/", component: BistroDashboardV2, 
    meta: {
      requiresAuth: true,
      layout: dashboard,
    },
  },
  {
    path: "/login",
    component: BistroLogin,
    meta: {
      requiresNoAuth: true,
    },
  },
  {
    path: "/automaticLogin",
    component: AutomaticLogin,
    meta: {
      requiresNoAuth: true,
    },
  },
  {
    path: "/login",
    component: BistroLogin,
    meta: {
      requiresNoAuth: true,
    },
  },
  {
    path: "/billViewer",
    component: BillViewer,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/ticketsViewer",
    component: TicketsViewer,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/dashboard/indexV2",
    component: BistroDashboardV2,
    meta: {
      requiresAuth: true,
      layout: dashboard,
    },
  },
  {
    path: "/dashboard",
    component: BistroDashboardV2,
    meta: {
      requiresAuth: true,
      layout: dashboard,
    },
  },
  {
    path: "/dashboard/report",
    component: indexReports,
    meta: {
      requiresAuth: true,
      layout: dashboard,
    },
  },
  {
    path: "/dashboard/reportPDV",
    component: IndexReportsPDV,
    meta: {
      requiresAuth: true,
      layout: dashboard,
    },
  },
  {
    path: "/dashboard/report/box",
    component: BoxReports,
    meta: {
      requiresAuth: true,
      layout: dashboard,
    },
  },
  {
    path: "/dashboard/report/sales",
    component: SalesReports,
    meta: {
      requiresAuth: true,
      layout: dashboard,
    },
  },
  {
    path: "/dashboard/report/dailyRankingSales",
    component: DailyRankingSales,
    meta: {
      requiresAuth: true,
      layout: dashboard,
    },
  },
  {
    path: "/dashboard/report/days",
    component: Days,
    meta: {
      requiresAuth: true,
      layout: dashboard,
    },
  },
  {
    path: "/dashboard/report/month",
    component: Month,
    meta: {
      requiresAuth: true,
      layout: dashboard,
    },
  },
  {
    path: "/dashboard/report/transactionDetailReport",
    component: TransactionDetailReport,
    meta: {
      requiresAuth: true,
      layout: dashboard,
    },
  },
  {
    path: "/dashboard/report/additionals",
    component: AdditionalsReport,
    meta: {
      requiresAuth: true,
      layout: dashboard,
    },
  },
  {
    path: "/dashboard/report/stockMonthlyReport",
    component: StockMonthlyReport,
    meta: {
      requiresAuth: true,
      layout: dashboard,
    },
  },
  {
    path: "/dashboard/report/comboSalesReport",
    component: ComboSalesReport,
    meta: {
      requiresAuth: true,
      layout: dashboard,
    },
  },
  {
    path: "/dashboard/report/waiterReport",
    component: WaiterReport,
    meta: {
      requiresAuth: true,
      layout: dashboard,
    },
  },
  {
    path: "/dashboard/report/MenuProfitabilityReport",
    component: MenuProfitabilityReport,
    meta: {
      requiresAuth: true,
      layout: dashboard,
    },
  },
  {
    path: "/dashboard/billingDetail",
    component: BillingDetail,
    meta: {
      requiresAuth: true,
      layout: dashboard,
    },
  },
  {
    path: "/productsV2",
    component: BistroProducts,
    meta: {
      requiresAuth: true,
      layout: dashboardNoMenu,
    },
  },
  {
    path: "/productsPDV",
    component: BistroProductsPDV,
    meta: {
      requiresAuth: true,
      layout: dashboardNoMenu,
    },
  },
  {
    path: "/menuEngineering",
    component: BistroMenuEngineering,
    meta: {
      requiresAuth: true,
      layout: dashboardNoMenu,
    },
  },
  {
    path: "/box",
    component: BistroBox,
    meta: {
      requiresAuth: true,
      layout: dashboardNoMenu,
    },
  },
  {
    path: "/eventsV2",
    component: EventsV2,
    meta: {
      requiresAuth: true,
      layout: dashboardNoMenu,
    },
  },
  {
    path: "/password",
    component: Password,
    meta: {
      requiresAuth: true,
      layout: dashboardNoMenu,
    },
  },
  {
    path: "/settingsV2",
    component: SettingsV2,
    meta: {
      requiresAuth: true,
      layout: dashboardNoMenu,
    },
  },
  {
    path: "/myPayments",
    component: MyPayments,
    meta: {
      requiresAuth: true,
      layout: dashboardNoMenu,
    },
  },
  {
    path: "/clientsV2",
    component: ClientsV2,
    meta: {
      requiresAuth: true,
      layout: dashboardNoMenu,
    },
  },
  {
    path: "/usersV2",
    component: UsersV2,
    meta: {
      requiresAuth: true,
      layout: dashboardNoMenu,
    },
  },
  {
    path: "/usersDetailsV2",
    component: userDetailsV2,
    meta: {
      requiresAuth: true,
      layout: dashboard,
    },
  },
  {
    path: "/clientsDetailsV2",
    component: ClientsDetailsV2,
    meta: {
      requiresAuth: true,
      layout: dashboard,
    },
  },
  {
    path: "/providersV2",
    component: ProvidersV2,
    meta: {
      requiresAuth: true,
      layout: dashboardNoMenu,
    },
  },
  {
    path: "/dashboard/consolidatedV2",
    component: BistroConsolidated,
    meta: {
      requiresAuth: true,
      layout: dashboard,
    },
  },
  {
    path: "/pdv/orders",
    component: PdvOrders,
    meta: {
      requiresAuth: true,
      layout: pdvLayout,
    },
  },
  {
    path: "/pdv/order/checkout/:shopcode/:id",
    component: PdvOrderCheckout,
    meta: {
      requiresAuth: true,
      layout: pdvLayout,
    },
  },
  {
    path: "/pdv/order/details/:shopcode/:id",
    component: PdvOrderDetails,
    meta: {
      requiresAuth: true,
      layout: pdvLayout,
    },
  },
  {
    path: "/settingsPos",
    component: SettingsPos,
    meta: {
      requiresAuth: true,
      layout: dashboardNoMenu,
    },
  },
  {
    path: "/boxPos",
    component: BoxPos,
    meta: {
      requiresAuth: true,
      layout: dashboardNoMenu,
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const store = useGlobalStore();
  const mix = mixCommons.methods;
  if (!store.getAuthUser) {
    try {
      const response = await axios.post("/api/auth/getCurrentUserData", {});
      store.SET_USER(response.data);
      //MIGRATION
      if(to.fullPath.includes("/login") && store.getAuthUser && to.query?.username) //Si esta yendo al login y tengo informacion de la sesion
      {
        const decodedUsername = decodeURIComponent(atob(to.query?.username));

        if(decodedUsername != store.getAuthUser.user.name)
        {
          axios
            .get("/api/auth/logout") /// OK
            .then(() => {
              mix.resetUser();
              mix.setAutomaticLogin(false);
              mix.resetPeriodSelection();
            });
          redirect(null);
        }
        else //END MIGRATION
          redirect(response.data);
      }
      else
        redirect(response.data);
    } catch (error) {
      console.error("[AUTH] Error al cargar los datos del usuario:", error);
      redirect(null);
    }
  } else {
    console.log('[AUTH] AUTH! getauthUser')
    redirect(true);
  }

  function redirect(access) {
    if (to.path === "/" || (to.meta.requiresAuth && access === null)) {
      next("/login");
    } else if (to.meta.requiresNoAuth && access != null) {
      next("/dashboard/indexV2");
    } else {
      // Si el usuario tiene el rol de PosAdministrator e intenta ingresar manualmente por URL a report, se le redirige a reportPDV
      // TODO: Cambiar el rol "Tester" cuando esté creado el rol PosAdministrator
    //   if (to.path === "/dashboard/report" && store.getAuthUser.role === "Tester") {
    //     next("/dashboard/reportPDV");
    //   } else {
    //   console.log('[AUTH] redirect! 3')
    //   next();
    // }
    // console.log('[AUTH] redirect! 3')
    next();
    }
  }
});

export default router;
