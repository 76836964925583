<script>
export default {
    computed: {
        shopCode() {
            return this.$store.getRefreshPDVList;
        }
    },
    methods: {
        getListByShopCode() {
            this.PDVList = [];
            const merchantTags = this.$store.getMerchantTags;
            merchantTags.forEach((shop,) => {
                //cambiar shopCode por PDV cuando se agrege en el back 
                if (shop.shopCode) {
                    this.PDVList.push({
                        shopCode: shop.shopCode,
                        listName: `${shop.merchantName} (${shop.shopCode})`,
                    });
                }
                this.$store.setPDVList(this.PDVList);
            });
        },   
    }
}
</script>