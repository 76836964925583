<template>
	<!-- CONTENIDO -->
	<section class="mainContain-info">
		<!-- FILTRADO DE LA SECCION -->
		<article class="mainFilter filterEvent">
			<!-- TITULO Y SEARCH -->
			<section class="mainFilter-title">
				<h1>Ranking de Ventas Diario</h1>
			</section>

			<!-- OPCIONES ADICIONALE -->
			<section class="mainFilter-filter">
				<!-- DESCARGAR EXCEL -->
				<a v-if="mobile == false" :href="`/api/report/daily_sales_rankingV2/?${currentQueryString}`"
					target="_blank" class="waves-effect waves-light btn-link">
					<span class="icon-Bistro-_Download"></span>
					<span>Descargar Detalle</span>
				</a>

			</section>
		</article>

		<!-- LISTADO DE PRODUCTOS -->
		<article class="mainEvents salesDashboard">
			<!-- TIPO DE TRANSACCION -->
			<section :class="{ 'active': showFilter }" class="mainEvents-filter FilterMobile">
				<div class="containTransaction FilterMobile active">

					<!-- SEARCH -->
					<div class="searchDashboard">
						<!-- MENSAJE -->
						<div class="itemMessage">
							<div class="text">
								<h5>Filtra tus ventas</h5>
								<p>Por código</p>
							</div>
							<a @click="search = !search" v-if="!search" id="btnSearchSales">
								<span class="icon-Bistro-_Search"></span>
							</a>
						</div>

						<!-- INPUT -->
						<div v-if="search" class="item">
							<a @click="search = !search" id="btnCloseSearch">
								<span class="icon-Bistro-_Close"></span>
							</a>

							<input v-model="criteria" type="text" class="inputFilter"
								placeholder="Buscar por Código ...">
						</div>
					</div>

					<!-- TIPOS DE PAGOS -->
					<ul class="containTransaction-lists">
						<!-- Titulo -->
						<SwitchToggle mode="list" v-model="categoryFilters" label="categorías" :title="true" />


						<!-- OPCION 1 -->
						<SwitchToggle mode="list" v-for="(item, index) in departmentsFilter" :key="index" v-model="item.isChecked"
							:label="item.department" @change="chargeFilterCategory(index, item.isChecked)" />


					</ul>
				</div>

				<!-- BTN ACTIVE FILTER MOBILE -->
				<button @click="showFilter = !showFilter" class="btnFilterMobile">
					<span class="icon-Bistro-_Filter"></span>
				</button>
			</section>
			<!-- MENSAJE ERROR -->
			<div v-if="reports?.length === 0 && loaded" class="mainEvents-history dailySalesRanking reportError">
				<h4>No hay reportes de ranking de ventas</h4>
			</div>

			<!-- LISTADO DE FACTURAS -->
			<ul v-if="reports?.length > 0" class="mainEvents-history dailySalesRanking">
				<!-- Historial 1 -->
				<li v-for="(item, index) in reports" :key="index">
					<figure class="iconHistory">
						<span :class="{ 'icon-Bistro-_Check': true }"></span>
					</figure>

					<div class="items">
						<h5>Fecha</h5>
						<p>{{ item.date }}</p>
					</div>

					<!-- FECHA -->
					<div class="items">
						<h5>STOCK</h5>
						<p>{{ item.stockSold }}</p>
					</div>

					<!-- OPERACION -->
					<div class="items">
						<p>{{ item.productoNombre }}</p>
					</div>

					<!-- DESCRIPCION -->
					<div class="items">
						<p>Vendidos: {{ item.quantity }}</p>
						<p>Consumidos: {{ item.consumedQuantity }}</p>
					</div>
					<!-- TOTAL -->
					<div class="items tooltip">
						<h3><currency-style :valor="$filters.currency(item.monto)" /></h3>
						<p class="tooltiptext">No contempla movimientos de invitaciones ni consumo empleados</p>
					</div>
				</li>
			</ul>
			<div v-else class=" mainEvents-history dailySalesRanking">
				<article v-for="i in 4" :key="i" :class="sectionLoading">
				</article>
			</div>
		</article>
		<help-center />
	</section>
</template>
<script>
import commons from '@/mixins/mixCommons'
import authCommons from '@/mixins/authCommons'
import bistroV2Commons from '@/mixins/bistroV2Commons'
import rolesCommons from '@/mixins/rolesCommons'
import computeds from '@/mixins/menuOptions'
import selectedPdvs from '@/mixins/select_pdvs_get_data'
import filterCategoryMixin from '@/mixins/filterCategory'
import { parseToQuery } from '@/utils/utils'
import CurrencyStyle from '@/components/CurrencyStyle'
import HelpCenter from '@/components/HelpCenter'
import axios from 'axios'
import SwitchToggle from '@/components/SwitchToggle.vue'

export default {
	mixins: [authCommons, commons, computeds, filterCategoryMixin, selectedPdvs, bistroV2Commons, rolesCommons],
	data() {
		return {
			filterInterval: null,
			departments: [],
			search: false,
			showFilter: false,
			loaded: false,
			currentQueryString: '',
			reports: [],
			criteria: '',
			category: ''
		}
	},
	async mounted() {
		document.title = `${this.appTitle} - Ranking de Ventas Diario`
		if (this.isUserLoggedIn() === false) {
			axios.get('/api/auth/logout') /// OK
				.then(() => {
					this.$store.SET_USER(null)
					this.resetUser()
					this.setAutomaticLogin(false)
					location.href = '/login'
				})
		} else if (this.hasToShowThisOption(2) === false) {
			this.handleUnauthorizedAccess()

		} else {
			if (await this.checkSessionInBackendAsync()) {
				this.onMounted()
				this.$store.setLoadingSkeleton(true)

			}

		}
	},
	watch: {
		criteria() {
			clearTimeout(this.timeout)
			this.timeout = setTimeout(() => {
				this.params['Code'] = this.criteria
				this.getData()
			}, 500)
		},
		category() {
			if (this.category !== '') {
				this.params['DepartamentName'] = this.category
			} else {
				delete this.params['DepartamentName']
			}
			this.getData()
		},
		dateCalendar() {
			if (this.dateCalendar.indexOf('Seleccione') < 0) {
				this.params['Period'] = this.tab.param
				this.getData()
			}
		},
		tab() {
			if (this.tab.id !== 6 && this.loaded) {
				this.params['Period'] = this.tab.param
				this.getData()
			}
		},
	},
	methods: {
		async onMounted() {
			this.$store.hideElements({
				hideCalendar: false,
				hideFilters: false,
				hideTabs: false
			})

			this.$store.updateCalendarType('range')
			this.toggleMerchantSelectorByLabel('showMerchantSelectionFilter')
			if (this.getPeriodSelectionFromLocalStorage() !== null) {
				await this.$store.updateTabSelected(this.getPeriodSelectionFromLocalStorage())
				this.params['Period'] = this.getPeriodSelectionFromLocalStorage().param
			} else {
				await this.$store.initTabs()
			}
			this.$store.setHasMounted(true)
			this.getData()
		},
		getData() {
			let ids = this.selectedPdvs.map(item => {
				return item.shopCode
			})
			if (ids?.length > 0) {
				this.params['MerchantFilter'] = ids
			} else {
				delete this.params['MerchantFilter']
			}
			this.currentQueryString = parseToQuery(this.params)

			clearInterval(this.filterInterval)
			this.filterInterval = setInterval(() => {
				clearInterval(this.filterInterval)

				this.$store.initLoading(this)
				axios.get(`/api/report/dailySalesRankingV2?${parseToQuery(this.params)}`)
					.then(res => {
						this.reports = res.data.saleItems
						this.departments = res.data.categories
						this.setupSwitches(res.data.categories)
						this.$store.setLoadingSkeleton(false)
						this.$store.finishLoading(this)
						this.loaded = true
					})
					.catch(err => {
						this.loaded = true
						this.$store.errorLoading(this)
						this.showErrors(err.response)
					})
			}, 1000)
		}
	},
	components: {
		CurrencyStyle,
		HelpCenter,
		SwitchToggle
	},
}
</script>
<style scoped>
.skeleton-card {
	margin-bottom: 10px;
}
</style>