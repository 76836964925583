<script>
export default {
    watch: {
        transactionTypeFilters() {
            this.setAllTransactionTypes(this.transactionTypeFilters)
        },
        deposit() {
            this.params['HasToFilterByDeposits'] = `${this.deposit ? 'SI' : 'NO'}`
            this.pageChangeHandle(1)
        },
        collect() {
            this.params['HasToFilterByCollects'] = `${this.collect ? 'SI' : 'NO'}`
            this.pageChangeHandle(1)
        },
        audit() {
            this.params['HasToFilterByAudits'] = `${this.audit ? 'SI' : 'NO'}`
            this.pageChangeHandle(1)
        },
        withdrawal() {
            this.params['HasToFilterByWithdrawals'] = `${this.withdrawal ? 'SI' : 'NO'}`
            this.pageChangeHandle(1)
        },
        order() {
            this.params['HasToFilterByOrders'] = `${this.order ? 'SI' : 'NO'}`
            this.pageChangeHandle(1)
        },
        sale() {
            this.params['HasToFilterBySales'] = `${this.sale ? 'SI' : 'NO'}`
            this.pageChangeHandle(1)
        },
        openCashbox() {
            this.params['HasToFilterByOpenCashbox'] = `${this.openCashbox ? 'SI' : 'NO'}`
            this.pageChangeHandle(1)
        },
        closeCashbox() {
            this.params['HasToFilterByCloseCashbox'] = `${this.closeCashbox ? 'SI' : 'NO'}`
            this.pageChangeHandle(1)
        },
        openTurn() {
            this.params['HasToFilterByOpenTurn'] = `${this.openTurn ? 'SI' : 'NO'}`
            this.pageChangeHandle(1)
        },
        closeTurn() {
            this.params['HasToFilterByCloseTurn'] = `${this.closeTurn ? 'SI' : 'NO'}`
            this.pageChangeHandle(1)
        },
        voidMovement() {
            this.params['HasToFilterByVoidMovement'] = `${this.voidMovement ? 'SI' : 'NO'}`
            this.pageChangeHandle(1)
        },
        billed() {
            this.params['HasToFilterByBilled'] = `${this.billed ? 'SI' : 'NO'}`
            this.pageChangeHandle(1)
        },
        creditNote() {
            this.params['HasToFilterByCreditNote'] = `${this.creditNote ? 'SI' : 'NO'}`
            this.pageChangeHandle(1)
        },
        tip() {
            this.params['HasToFilterByTip'] = `${this.tip ? 'SI' : 'NO'}`
            this.pageChangeHandle(1)
        },
        tab() {
            if (this.tab.id !== 6 && this.loaded) {
                this.params['Period'] = this.tab.param
                this.pageChangeHandle(1)
            }
        },
        dateCalendar() {
            if (this.dateCalendar.indexOf('Seleccione') < 0) {
                this.params['Period'] = this.tab.param
                this.pageChangeHandle(1)
            }
        }
    },
}
</script>