<template>
	<div v-if="config" class="section-divider">
		<h4>{{ title }}</h4>
		<p v-if="subtitle">{{ subtitle }}</p>

		<section>

			<switch-toggle label="Habilitar" v-model="getConfig.enabled"
				@change="getConfig.enabled = !getConfig.enabled" />


			<article v-if="getConfig.enabled">
				<div v-for="(config, index) in getConfig.hoursByDay" :key="index" class="row-config">

					<switch-toggle class="day" :label="getDayLabelByKey(config.dayKey)" v-model="config.isOpen"
					@change="modifyDayStatus(index, !config.isOpen)"  />

					<div>
						<div v-for="(h, i) in config.hours" :key="i" class="hour-row">
							<div class="itemForm input">
								<input
									v-model="h.from"
									type="time"
									placeholder="HH:MM"
									min="00:00"
									max="23:59"
								/>
							</div>
							<div class="itemForm input">
								<input
									v-model="h.to"
									type="time"
									placeholder="HH:MM"
									min="00:00"
									max="23:59"
								/>
							</div>
							<img
								class="clickable"
								@click="deleteHour(index, i)"
								src="@/assets/img/delete.svg"
								title="Eliminar horario"
								alt="Eliminar horario"
							/>
						</div>
					</div>

					<img
						class="clickable"
						@click="addHour(index)"
						src="@/assets/img/add.webp"
						title="Agregar horario"
						alt="Agregar horario"
					/>
				</div>
			</article>
		</section>

	</div>
</template>

<script>
import timePickerCommons from "../mixins/timePickerCommons";
import SwitchToggle from "./SwitchToggle.vue";

export default {
	mixins: [
		timePickerCommons,
	],
	props: {
		shopCode: {
			type: String
		},
		title: {
			type: String
		},
		subtitle: {
			type: String,
			default: null
		},
		configHours: {
			type: Object
		},
		hasToShowSaveButton: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			config: null
		};
	},
	mounted() {
		this.config = this.configHours;
	},

	computed: {
        isLoading () {
			return this.$store.getLoading;
		}
	},
	watch: {
		config: {
			deep: true,
			handler() {
				this.saveConfig();
			}
		},
	},
	methods: {
		saveConfig() {
			this.$emit("upsertConfigHours", this.config, this.shopCode);
		},
	},
	components: {
		SwitchToggle

	},
};
</script>

<style lang="stylus">
.clickable
    cursor: pointer;

.section-divider
    margin 40px 0px
    max-width 550px
    section
        margin 30px 0px

        .row-config
            display flex
            justify-content space-between
            align-items center
            border-top 1px solid rgba(216,216,216,0.4)

            &:last-child
                border-bottom 1px solid rgba(216,216,216,0.4)

            .day
                width 150px

            .hour-row
                display flex
                align-items center

                .input
                    width 110px !important
                    margin 3% 1%

                    input
                        width 110px !important
                        text-indent 5px !important
                        margin 0

    img
        width 25px
        height 25px
        margin 5px


@media (max-width: 530px)
    .row-config
        display flex
        flex-direction column
        padding 20px
</style>
