<template>
	<section class="mainContain-info">

		<!-- FILTRADO DE LA SECCION -->
		<article class="mainFilter filterEvent">
			<!-- TITULO Y SEARCH -->
			<section class="mainFilter-title">
				<div class="fix-title">
					<h1>Reportes de Stock</h1>
					<p v-if="isGeneratingReport">La generación del reporte puede demorar unos minutos, gracias por tu
						paciencia.</p>
				</div>
			</section>

			<!-- OPCIONES ADICIONALE -->
			<section class="mainFilter-filter">
				<Spinner v-if="isGeneratingReport" />
				<div v-else @click="onGenerateCurrentStockReport()" class="btn-main">
					<a class="waves-effect waves-light">
						<p>Generar Detalle Actual</p>
					</a>
				</div>
			</section>
		</article>

	
		<!-- LISTADO DE REPORTES -->
		<article v-if="reports.length > 0" class="mainEvents reportDashboard">

			<!-- LISTADO DE REPORTES -->
			<!-- <ul class="providerDetail-facture"> -->
			<ul class="stockReport">
				<!-- ASENTAMIENTOS -->
				<!-- VENTAS -->
				<a class="hidden" ref="donwload" :href="href" :download="pdfName">Download</a>
				<li v-for="(item, index) in reports" :key="index">
					<figure class="iconFactu">
						<span class="icon-Bistro-_FileDoble"></span>
					</figure>

					<!-- CONCEPTO -->
					<div class="items stockReportDetailTitle">
						<h3>Reporte de Stock</h3>
						<p>{{ item.yearAndMonth }}</p>
					</div>

					<!-- OPERACION -->
					<div class="items stockReportDetail">
						<p><strong>{{ item.shopName }}</strong></p>
						<p>{{ item.timestamp }}</p>
					</div>


					<!-- OPCIONES DE ESTATUS -->
					<div class="items downloadStockReport">
						<!-- <no-ssr>
							<div class="btn-main">
								<a :href="item.url" class="waves-effect waves-light" download>
									<p><span class="icon-Bistro-_Download_blue"></span>&nbsp;<span class="downloadStockReportSpan">Descargar detalle</span></p>
								</a>
							</div>
						</no-ssr> -->
						<a v-if="item.summarizedUrl" :href="item.summarizedUrl" target="_blank"
							class="waves-effect waves-light btn-link" download>
							<span class="icon-Bistro-_Download"></span>
							<span>Descargar Resumen</span>
						</a>
					</div>
					<div class="items downloadStockReport">
						<!-- <no-ssr>
							<div class="btn-main">
								<a :href="item.url" class="waves-effect waves-light" download>
									<p><span class="icon-Bistro-_Download_blue"></span>&nbsp;<span class="downloadStockReportSpan">Descargar detalle</span></p>
								</a>
							</div>
						</no-ssr> -->
						<a :href="item.url" target="_blank" class="waves-effect waves-light btn-link" download>
							<span class="icon-Bistro-_Download"></span>
							<span>Descargar Detalle</span>
						</a>
					</div>
				</li>
			</ul>

		</article>
		
	

		<div v-if="reports && reports.length > 0 && isMoreOfOnePage()" class="base-pagination">
			<BasePagination :current-page="currentPage" :totalCount="totalCount" :countPerPage="countPerPage"
				class="list__pagination" @nextPage="pageChangeHandle('next')"
				@previousPage="pageChangeHandle('previous')" @loadPage="pageChangeHandle" />
		</div>

		<!-- MENSAJE ERROR -->
		<article v-if="reports && reports.length === 0 && loaded" class="reportError">
			<h4>No hay reportes de stock</h4>
		</article>

		<article class="mainEvents reportDashboard" v-for="i in 4" :key="i" :class="sectionLoading">
			
		</article>
		<help-center />
	</section>
</template>
<script>
import commons from '@/mixins/mixCommons'
import rolesCommons from '@/mixins/rolesCommons'
import authCommons from '@/mixins/authCommons'
import bistroV2Commons from '@/mixins/bistroV2Commons'
import computeds from '@/mixins/menuOptions'
import selectPdvs from '@/mixins/select_pdvs'
import axios from 'axios'
import { parseToQuery } from '@/utils/utils'
import BasePagination from '@/components/BasePagination'
import Spinner from '@/components/SpinnerComp'
import HelpCenter from '@/components/HelpCenter'
import { toast } from "vue3-toastify"

export default {
	mixins: [authCommons, commons, computeds, rolesCommons, selectPdvs, bistroV2Commons],
	data() {
		return {
			isGeneratingReport: false,
			params: {},
			reports: [],
			loaded: false,
			href: '',
			pdfName: '',
			currentPage: 1,
			countPerPage: 10,
			totalCount: 0
		}
	},
	async mounted() {
		document.title = `${this.appTitle} - Reportes mensuales de stock`
		this.$store.setHasMounted(false)

		if (this.isUserLoggedIn() === false) {
			axios.get('/api/auth/logout') /// OK
				.then(() => {
					this.$store.SET_USER(null)
					this.resetUser()
					this.setAutomaticLogin(false)
					location.href = '/login'
				})
		} else if (this.hasToShowThisOption(2) === false) {
			this.handleUnauthorizedAccess()

		} else {
			if (await this.checkSessionInBackendAsync()) {
				this.onMounted()
			}
		}
	},
	watch: {
		tab() {
			if (this.tab.id !== 6) {
				this.params['Period'] = this.tab.param
				this.getData()
			}
		},
		dateCalendar() {
			if (this.dateCalendar.indexOf('Seleccione') < 0) {
				this.params['Period'] = this.tab.param
				this.getData()
			}
		}
	},
	methods: {
		async onMounted() {
			this.$store.setLoadingSkeleton(true)
			this.$store.setShowProductListSelectionFilter(true)
			this.$store.hideElements({
				hideCalendar: true,
				hideFilters: false,
				hideTabs: true
			})
			this.$store.updateSideMenu(3)
			if (this.getPeriodSelectionFromLocalStorage() !== null) {
				await this.$store.updateTabSelected(this.getPeriodSelectionFromLocalStorage())
				this.params['Period'] = this.getPeriodSelectionFromLocalStorage().param
			} else {
				await this.$store.initTabs()
			}
			this.$store.CALL_MERCHANTS()

			this.params['CurrentPage'] = this.currentPage
			this.params['CountPerPage'] = this.countPerPage
			this.$store.setHasMounted(true)

		},
		onGenerateCurrentStockReport() {
			this.isGeneratingReport = true
			this.$store.initLoading(this)
			const shopCodes = this.$store.getMerchantTags.map(
				(i) => {
					return i.shopCode
				}
			)
			axios.post(`/api/report/generateCurrentStockReport`, {
				ShopCodes: shopCodes
			})
				.then((res) => {
					this.isGeneratingReport = false
					toast.success(res.data?.responseMessage ?? 'Reporte generado correctamente!')
					this.$store.finishLoading(this)
					this.getData()
				})
				.catch(err => {
					this.isGeneratingReport = false
					if (err.response && err.response.status === 401) {
						toast.show('La sesion ha caducado, seras redirigido al login')
						setTimeout(() => {
							window.location.href = '/login'
						}, 3000)
					} else {
						toast.error('Error al generar el reporte. Reintentá más tarde')
						this.$store.errorLoading(this)
					}
				})
		},
		isMoreOfOnePage() {
			return (this.totalCount - this.countPerPage) > 0
		},
		getData() {
			if (!this.$store.hasMounted) return
			this.$store.initLoading(this)
			axios.get(`/api/report/stockMonthlyReport?${parseToQuery(this.params)}`)
				.then(res => {
					this.reports = res.data.reports
					this.totalCount = res.data.totalCount
					this.$store.setLoadingSkeleton(false)
					this.$store.finishLoading(this)
					this.loaded = true
				})
				.catch(err => {
					this.$store.errorLoading(this)
					this.showErrors(err.response)
				})
		},
	},
	components: {
		BasePagination,
		Spinner,
		HelpCenter
	},
}
</script>

<style scoped>
.skeleton-card {
	margin: 10px 25px;
}
</style>
