<script>
export default {
  computed: {
    getCurrencySymbol() {
      switch (process.env.VUE_APP_INSTANCE_CODE) {
        case "AR":
        case "UY":
        case "MX":
        case "ENERGY_POS_SL":
          return "$";
        case "ES":
          return "€";
        default:
          return "$";
      }
    },
    getStep() {
      switch (process.env.VUE_APP_INSTANCE_CODE) {
        case "CL":
        case "PROVENTAS":
          return "1";
        default:
          return "0.01";
      }
    },
  },
  methods: {
    getParseWithCurrencySymbol(value, mode = "span", separator = ",") {
      switch (process.env.VUE_APP_INSTANCE_CODE) {
        default:
          return `${this.getParse(
            value,
            mode,
            separator
          )}`;
      }
    },

    getParse(value, mode = "span", separator = ",") {
      //TODO usar si vamos a separar los miles por coma
      // if(process.env.VUE_APP_INSTANCE_CODE === "MX" || process.env.VUE_APP_INSTANCE_CODE === "ENERGY_POS_SL"){
      //   separator = "."
      // }

      value = value.toString();
      
      if (value === 0) value = value.toString();
      try {
        let values = value.split(separator);

        switch (process.env.VUE_APP_INSTANCE_CODE) {
          case "CL":
          case "PROVENTAS":
            switch (mode) {
              case "span":
                return `<span class="integer-value">${values[0]}</span>`;
              default:
                return values[0];
            }
          default:
            switch (mode) {
              case "span":
                if (values[1]) {
                  // if(process.env.VUE_APP_INSTANCE_CODE === 'ES'){
                  //   return `<span class="integer-value">${this.getCurrencySymbol} ${values[0]},</span><span class="decimal-value">${values[1]}</span>`;
                  // } else {
                  //   return `<span class="integer-value">${values[0]},</span><span class="decimal-value">${values[1]}</span>`;
                  // }
                  return `<span class="integer-value">${values[0] + separator}</span><span class="decimal-value">${values[1]}</span>`;
                } else {
                  // if(process.env.VUE_APP_INSTANCE_CODE === 'ES') {
                  //   return `<span class="integer-value"> ${this.getCurrencySymbol} ${values[0]}</span>`;
                  // } else {
                  //   return `<span class="integer-value">${values[0]}</span>`;
                  // }
                  return `<span class="integer-value">${values[0]}</span>`;
                }
              default:
                if (values[1]) {
                  return `${values[0]}.${values[1]}`;
                } else {
                  return `${values[0]}`;
                }
            }
        }
      } catch (err) {
        console.error(`getParse error -> `, err, value, mode, separator);
        return value;
      }
    },
  },
};
</script>
