<template>
    <main>
        <!-- FILTRADO DE LA SECCION -->
        <article class="mainFilter filterEvent">
            <!-- TITULO Y SEARCH -->
            <section class="mainFilter-title">
                <h1>Caja</h1>
            </section>
            <section class="mainFilter-filter">
                <div class="filterRecord tooltip items">
                    <div class="item"
                        :class="{ 'items-grey': cashBoxData.declaredAmount > 0 || cashboxInProgress.openAmount > 0 }">

                        <h4 :class="{ 'negative-numbers': cashBoxData.declaredAmount < 0 }">
                            <CurrencyStyle v-if="!isCashBoxOpened"
                                :valor="$filters.currency(cashBoxData.openAmount ?? 0)" />
                            <CurrencyStyle v-else :valor="$filters.currency(cashboxInProgress.declaredAmount)" />
                        </h4>
                        <p>Efectivo</p>

                    </div>
                    <div class="item">
                        <h4 v-if="!isCashBoxOpened">-</h4>
                        <h4 v-else>{{ $filters.currency(0) }}</h4>
                        <p>Tarjeta</p>
                    </div>
                    <div class="item">
                        <h4 v-if="!isCashBoxOpened">-</h4>
                        <h4 v-else>{{ $filters.currency(0) }}</h4>
                        <p>QR</p>
                    </div>
                    <div class="item">
                        <h4 v-if="!isCashBoxOpened">-</h4>
                        <h4 v-else>{{ $filters.currency(0) }}</h4>
                        <p>Online</p>
                    </div>
                    <div class="item">
                        <h4 v-if="!isCashBoxOpened">-</h4>
                        <h4 v-else>
                            <CurrencyStyle :valor="$filters.currency(data.trnxAmountShown)" />
                        </h4>
                        <p>Invitación</p>
                    </div>
                </div>
            </section>
        </article>



        <!-- LISTADO DE PRODUCTOS -->
        <article :class="{ errorLoading: data.cashBoxItems && data.cashBoxItems.length === 0 }" class="mainEvents ">

            <!-- TIPO DE TRANSACCION -->
            <section :class="{ active: showFilter }" class="mainEvents-filter FilterMobile">

                <!-- BOTONES DE ACCION PARA CERRAR CAJA  -->
                <section class="containButtons">

                    <a @click="showConfirmationCashBox()" class="mainEvents-filter">
                        <div class="btn-main">
                            <a class="">
                                <p>{{ isCashBoxOpened ? 'Cerrar caja' : 'Abrir caja' }}</p>
                            </a>
                        </div>
                    </a>
                    <button @click="withdrawFunds()" class="btn-option" :class="{ 'disabled-option': !isCashBoxOpened }"
                        :disabled="!isCashBoxOpened" type="button">
                        <p>Retirar</p>
                    </button>
                    <button @click="depositFunds()" class="btn-option" :class="{ 'disabled-option': !isCashBoxOpened }"
                        :disabled="!isCashBoxOpened" type="button">
                        <p>Depositar</p>
                    </button>
                    <button @click="printPartialReport()" class="btn-option"
                        :class="{ 'disabled-option': !isCashBoxOpened }" :disabled="isCashBoxOpened" type="button">
                        <p>Imprimir parcial</p>
                    </button>
                </section>
                <div v-if="isCashBoxOpened || previousCashBox" class="containTransaction">
                    <ul class="containTransaction-lists">
                        <!-- Titulo -->
                        <SwitchToggle mode="list" v-model="transactionTypeFilters" label="Tipos de Transacción" :title="true" />

                        <!-- OPCION 1 -->
                        <SwitchToggle mode="list" v-model="deposit" label="Depósito" />

                        <!-- OPCION 2 -->
                        <SwitchToggle mode="list" v-model="collect" label="Cobro" />

                        <!-- OPCION 3 -->
                        <SwitchToggle mode="list" v-model="audit" label="Ajuste" />

                        <!-- OPCION 4 -->
                        <SwitchToggle mode="list" v-model="withdrawal" label="Retiro" />

                        <!-- OPCION 5 -->
                        <SwitchToggle mode="list" v-model="order" label="Comanda" />

                        <!-- OPCION 6 -->
                        <SwitchToggle mode="list" v-model="sale" label="Venta" />

                        <!-- OPCION 7 -->
                        <SwitchToggle mode="list" v-model="openCashbox" label="Apertura de Caja" />

                        <!-- OPCION 8 -->
                        <SwitchToggle mode="list" v-model="closeCashbox" label="Cierre de Caja" />

                        <!-- OPCION 9 -->
                        <SwitchToggle mode="list" v-model="openTurn" label="Apertura de Turno" />

                        <!-- OPCION 10 -->
                        <SwitchToggle mode="list" v-model="closeTurn" label="Cierre de Turno" />

                        <!-- OPCION 11 -->
                        <SwitchToggle mode="list" v-model="voidMovement" label="Anulación" />

                        <!-- OPCION 12 -->
                        <SwitchToggle mode="list" v-model="billed" label="Anulación por facturación" />

                        <!-- OPCION 13 -->
                        <SwitchToggle mode="list" v-model="creditNote" label="Nota de crédito" />

                        <!-- OPCION 14 -->
                        <SwitchToggle mode="list" v-if="hasToShowTip" v-model="tip" label="Propina" />
                        
                    </ul>
                </div>
                <!-- BTN ACTIVE FILTER MOBILE -->
                <button @click="showFilter = !showFilter" class="btnFilterMobile">
                    <span class="icon-Bistro-_Filter"></span>
                </button>
            </section>

            <!-- LISTADO DE MOVIMIENTOS DE CAJA -->
            <div v-if="!previousCashBox && !isCashBoxOpened" class="mainEvents-history errorLoading-mainError ">

                <p>Atención. Debe abrir caja para empezar a operar.</p>
            </div>
            <ul v-else-if="data.cashBoxItems && data.cashBoxItems.length > 0" class="mainEvents-history">
                <table-row v-for="(item, index) in data.cashBoxItems" target="boxV2" :modal="false" :isSaleV2="false"
                    :data="item" :key="index" />
            </ul>

            <empty-element v-else-if="data.cashBoxItems?.length === 0 && loaded" />
        </article>

        <div v-if="data.cashBoxItems && data.cashBoxItems.length > 0 && isMoreOfOnePage()" class="base-pagination">
            <BasePagination :current-page="currentPage" :totalCount="data.totalCount" :countPerPage="countPerPage"
                class="list__pagination" @nextPage="pageChangeHandle('next')"
                @previousPage="pageChangeHandle('previous')" @loadPage="pageChangeHandle" />
        </div>
        <help-center />

        <ModalBoxPos @onConfirmOpenAmount="handleOpenAmount" :modalOption="'modalOpenBox'" operation="open" />
        <ModalBoxPos @onConfirmDepositAmount="handleDepositAmount" :modalOption="'modalDepositBox'"
            operation="deposit" />
        <ModalBoxPos @onConfirmWithdrawal="handleWithdrawalAmount" :modalOption="'modalWithdrawalBox'"
            operation="withdrawal" />
        <ModalBoxPos @onConfirmCloseAmount="handleCloseAmount" :modalOption="'modalCloseBox'" operation="close" />
    </main>
</template>

<script>
import axios from "axios";
import mixCommons from "@/mixins/mixCommons";
import rolesCommons from "@/mixins/rolesCommons";
import authCommons from "@/mixins/authCommons";
import computeds from "@/mixins/menuOptions";
import bistroV2Commons from "@/mixins/bistroV2Commons";
import TableRow from "@/components/TableRow";
import EmptyElement from "@/components/EmptyElement";
import { parseToQuery } from "@/utils/utils";
import BasePagination from "@/components/BasePagination";
import HelpCenter from "@/components/HelpCenter";
import ModalBoxPos from "@/features/pdvBox/components/ModalBoxPos.vue";
import MixModals from "@/mixins/mixModals.vue";
import CurrencyStyle from "@/components/CurrencyStyle.vue";
import mixBoxCommons from '@/mixins/mixBoxCommons.vue';
import boxPosCommons from '../mixins/BoxPosCommons.vue';
import ShopCommons from "@/features/shared/mixins/shopCommons.vue";
import { syncCashboxes } from "../../shared/repositories/orders/ordersService.js"
// import { toast } from "vue3-toastify";
import { formatDate } from "@/features/shared/utils/utils";
import { ASYNC_STATUS } from "@/features/shared/utils/orderStatuses";
import indexedDbRepo from "@/features/shared/repositories/orders/ordersIndexedDbRepository";
import SwitchToggle from "@/components/SwitchToggle.vue";

export default {
    mixins: [authCommons, mixCommons, computeds, rolesCommons, bistroV2Commons, MixModals, mixBoxCommons, ShopCommons, boxPosCommons],
    data() {
        return {
            data: {},
            filterInterval: null,
            transactionTypeFilters: true,
            deposit: true,
            collect: true,
            showFilter: false,
            loaded: false,
            isCashBoxOpened: false,
            audit: true,
            withdrawal: true,
            order: true,
            sale: true,
            openCashbox: true,
            closeCashbox: true,
            openTurn: true,
            closeTurn: true,
            voidMovement: true,
            billed: true,
            creditNote: true,
            tip: true,
            excelParams: '',
            previousCashBox: false,
            params: {
                HasToFilterByDeposits: 'SI',
                HasToFilterByCollects: 'SI',
                HasToFilterByAudits: 'SI',
                HasToFilterByWithdrawals: 'SI',
                HasToFilterByOrders: 'SI',
                HasToFilterBySales: 'SI',
                HasToFilterByOpenCashbox: 'SI',
                HasToFilterByCloseCashbox: 'SI',
                HasToFilterByOpenTurn: 'SI',
                HasToFilterByCloseTurn: 'SI',
                HasToFilterByVoidMovement: 'SI',
                HasToFilterByBilled: 'SI',
                HasToFilterByCreditNote: 'SI',
                HasToFilterByTip: 'SI'
            },
            currentPage: 1,
            countPerPage: 50,
            showModalBox: false,
            cashBoxData: {
                openDate: "", // Fecha de apertura de la caja
                closeDate: null, // Fecha de cierre de la caja (nulleable)
                openAmount: 0, // Monto al abrir
                closeAmount: 0, // Monto al cerrar (nulleable)
                closeBox: false
            },
        }
    },
    async mounted() {
        document.title = `${this.appTitle}  - Caja`;
        if (this.isUserLoggedIn() === false) {
            axios.get('/api/auth/logout') /// OK
                .then(() => {
                    this.$store.SET_USER(null)
                    this.resetUser()
                    this.setAutomaticLogin(false)
                    location.href = '/login'
                })
        } else if (!this.hasToShowSettingsPosTab) {
            this.handleUnauthorizedAccess()
        } else {
            if (await this.checkSessionInBackendAsync()) {
                this.onMounted()
            }
        }
    },
    watch: {
        async shopCode() {
            this.resetCashBoxData();
            await this.getDataCashBox()
        }
    },
    computed: {
        lastUpdateCashbox() {
            const cashBoxData = this.listOfBoxClosures ? JSON.parse(this.listOfBoxClosures) : null;
            if(!cashBoxData) return null
            return cashBoxData[cashBoxData.length - 1];
        },
        listOfBoxClosures() {
            return localStorage.getItem(`cashBox_${this.shopCode}`);
        },
        hasToShowTip() {
            if (process.env.VUE_APP_INSTANCE_CODE == "AR") {
                return false
            } else {
                return true
            }
        },

    },
    methods: {
        async syncCashboxReport() { ///Esto es cuando cierro la caja. Es el reporte de cierre de caja
            // console.log(payload)
            // try {
            //     const data = await axios.post(`/api/pdv/SyncCashboxReport`, payload); //  TODO arreglar endpoint de syncronizacion
            //     if (data?.data?.responseCode == 0) {
            //         toast.success("Caja cerrada.");
            //     } else {
            //         toast.error(data.message);
            //         return false;
            //     }
            // } catch (err) {
            //     toast.error("Ocurrió un error al abrir la caja.");
            //     return false;
            // }

        },
        resetCashBoxData() {
            this.cashBoxData = {
                openDate: "",
                closeDate: null,
                openAmount: 0,
                closeAmount: 0,
                closeBox: false
            };
            this.isCashBoxOpened = false;
        },
        saveDataInLocalStorage(cashBox) {
            localStorage.setItem(`cashBox_${this.shopCode}`, JSON.stringify(cashBox));
        },

        async handleWithdrawalAmount(amount, reason, remarks) {
            const cashbox = this.createCashbox({
                amount: amount * (-1),
                comments: `${reason ?? ''} ${remarks ?? ''}`,
                movementType: 'RETIRO',
            });
            this.$store.initLoading(this);
            await indexedDbRepo.insertCashbox(this.shopCode, cashbox);
            await syncCashboxes(this.shopCode, true);
            this.$store.finishLoading(this);

            setTimeout(async () => {
                this.getData();
            }, 3000);
        },
        async handleOpenAmount(declaredAmount, differenceAmount) {
            this.resetCashBoxData();

            // Inicializar `cashBoxData` con los datos de apertura
            this.cashBoxData = {
                ...this.cashBoxData,
                openDate: formatDate(),
                declaredAmount: declaredAmount,
            };

            const existingData = this.listOfBoxClosures;
            let listCashBoxData = Array.isArray(existingData) ? existingData : [];

            try {
                if (listCashBoxData.length === 0) {
                    listCashBoxData.push(this.cashBoxData);
                    this.saveDataInLocalStorage(listCashBoxData);
                }

                this.$store.setCashBox(this.cashBoxData);
                const cashbox = this.createCashbox({
                    amount: this.cashBoxData.declaredAmount - differenceAmount,
                    comments: 'APERTURA DE CAJA',
                    movementType: 'APERTURA DE CAJA',
                });
                this.$store.initLoading(this);

                await indexedDbRepo.insertCashbox(this.shopCode, cashbox);

                if(differenceAmount != 0) {
                    const cashboxDifference = this.createCashbox({
                        amount: differenceAmount,
                        comments: `DIFERENCIA ENCONTRADA ${this.$filters.currency(differenceAmount)}`,
                        movementType: 'AJUSTE EN APERTURA DE CAJA',
                    });
                    await indexedDbRepo.insertCashbox(this.shopCode, cashboxDifference);
                }

                await syncCashboxes(this.shopCode, true);
                this.$store.finishLoading(this);
                setTimeout(async () => {
                    this.previousCashBox = false
                    this.getData();
                }, 3000);
                this.isCashBoxOpened = true;
            } catch (error) {
                console.error("Error procesando datos de caja:", error);
            }
        },
        async handleDepositAmount(amount, remarks) {
            const cashbox = this.createCashbox({
                amount: amount,
                comments: remarks ?? '',
                movementType: 'DEPOSITO',
            });
            this.$store.initLoading(this);

            await indexedDbRepo.insertCashbox(this.shopCode, cashbox);
            await syncCashboxes(this.shopCode, true);
            this.$store.finishLoading(this);

            setTimeout(async () => {
                this.getData();
            }, 3000);
        },
        createCashbox(overrides = {}) {
            const baseCashbox = {
                amount: 0,
                asyncStatus: ASYNC_STATUS.PENDING,
                billNumber: '',
                customerId: null,
                externalReference: null,
                waiter: null,
                cashAccumAmount: 0,
                cashMovementId: 0,
                comments: '',
                date: formatDate(),
                ticketNumber: '',
                movementType: '',
                totalAccumDiscountPerProductAmount: 0,
                saleAuthCode: '',
                saleCaptureWay: '',
                saleId: 0,
                saleOrigin: null,
                salePaymentMethod: 'EFECTIVO',
                saleType: '',
                sequence: 1,
                totalAccumAmount: 0,
                totalDiscountAmount: 0,
                totalTaxesAmount: 0,
                totalVatAmount: 0,
                userName: this.$store.authUser?.user?.name,
                uuid: null,
                createdAt: Date.now(),
            };

            return { ...baseCashbox, ...overrides };
        },
        showConfirmationCashBox() {
            if (!this.isCashBoxOpened) {
                this.openModalById('#modalOpenBox')
            } else {
                this.openModalById('#modalCloseBox')
            }
        },
        depositFunds() {
            this.openModalById('#modalDepositBox')
        },
        withdrawFunds() {
            this.openModalById('#modalWithdrawalBox')
        },
        async handleCloseAmount(closeAmount, differenceAmount) {
            const cashboxLocal = { ...this.$store.getCashBox };
            cashboxLocal.closeAmount = closeAmount;
            cashboxLocal.closeDate = formatDate();
            cashboxLocal.closeBox = true;

            const list = this.listOfBoxClosures || "[]";
            let cashBoxData = JSON.parse(list);

            if (cashBoxData.length > 0) {
                cashBoxData[cashBoxData.length - 1] = cashboxLocal;
            } else {
                cashBoxData.push(cashboxLocal);
            }

            this.saveDataInLocalStorage(cashBoxData);

            this.$store.initLoading(this);

            this.$store.setCashBox(cashBoxData);
            const cashbox = this.createCashbox({
                amount: closeAmount - differenceAmount,
                comments: 'CIERRE DE CAJA',
                movementType: 'CIERRE DE CAJA',
            });

            await indexedDbRepo.insertCashbox(this.shopCode, cashbox);
            if(differenceAmount != 0) {
                const cashboxDifference = this.createCashbox({
                    amount: differenceAmount,
                    comments: `DIFERENCIA ENCONTRADA ${this.$filters.currency(differenceAmount)}`,
                    movementType: 'AJUSTE EN CIERRE CAJA',
                });
                await indexedDbRepo.insertCashbox(this.shopCode, cashboxDifference);
            }

            await syncCashboxes(this.shopCode, true);
            await this.syncCashboxReport(cashbox)
            this.$store.finishLoading(this);
            setTimeout(async () => {
                location.reload();
            }, 3000);
            this.isCashBoxOpened = false;
            this.closeModalById("#modalCloseBox");

        },
        setAllTransactionTypes(bool) {
            this.deposit = bool
            this.collect = bool
            this.audit = bool
            this.withdrawal = bool
            this.order = bool
            this.sale = bool
            this.openCashbox = bool
            this.closeCashbox = bool
            this.openTurn = bool
            this.closeTurn = bool
            this.voidMovement = bool
            this.billed = bool
            this.creditNote = bool
            this.tip = bool
        },
        onMounted() {
            this.$store.updateTopMenu(10);
            this.$store.hideElements({
                hideCalendar: true,
                hideFilters: false,
                hideTabs: true,
            });
            this.toggleMerchantSelectorByLabel("showPDVListSelectionFilter");

            this.getListByShopCode()

            this.$store.CALL_MERCHANTS();
            this.params['CurrentPage'] = this.currentPage
            this.params['CountPerPage'] = this.countPerPage
            this.$store.setHasMounted(true)
            this.getDataCashBox()
        },
        async getDataCashBox() {
            try {
                let lastUpdate = this.lastUpdateCashbox
                if(!lastUpdate) {
                    lastUpdate = this.cashBoxData
                }

                if (lastUpdate.closeBox) {
                    this.cashBoxData.openAmount = lastUpdate.closeAmount
                }

                if (!lastUpdate.closeDate && lastUpdate.openDate) {
                    this.cashBoxData = lastUpdate
                    this.isCashBoxOpened = true
                }

                if(!this.isCashBoxOpened){
                    this.previousCashBox = true
                }

                this.getData()
                this.$store.setCashBox(this.cashBoxData)
                return;

            }
            catch (error) {
                console.error("Error al guardar los datos en localStorage:", error);
            }
        },
        isMoreOfOnePage() {
            return (this.data.totalCount - this.countPerPage) > 0
        },
        getData() {
            clearInterval(this.filterInterval)
            this.filterInterval = setInterval(() => {
                clearInterval(this.filterInterval)
                this.params['Period'] = this.previousCashBox ? 'PreviusCashBox' : 'CurrentCashBox'
                this.params['MerchantFilter'] = this.shopCode
                this.$store.initLoading(this);
                axios.get(`/api/boxV2/?${parseToQuery(this.params)}`)
                    .then(res => {
                        this.data = res.data
                        this.$store.finishLoading(this);
                        this.loaded = true
                        this.showErrors(res)
                    })
                    .catch(err => {
                        this.$store.errorLoading(this);
                        this.showErrors(err.response)
                    })
            }, 1000)
        }
    },
    components: {
        TableRow,
        EmptyElement,
        BasePagination,
        HelpCenter,
        ModalBoxPos,
        CurrencyStyle,
        SwitchToggle
    },
};
</script>

<style scoped lang="scss">
.errorLoading-mainError {
    display: flex;
    align-items: center;
    height: 70vh;

}

.btn-option {
    margin-bottom: 6px;

    &.disabled-option {
        pointer-events: none;
        opacity: 0.5;
        cursor: not-allowed !important;
    }
}

.items-grey {
    color: #37c866;
}
</style>