<template>
    <div>
        <div v-if="discount" :class="['input-comment', variant]">
            <span>DESCUENTO {{ product.discountByPercentage ? `(${product.discountByPercentage}%):` : "FIJO" }}</span>
            <div class="comment-content">
                <span>{{ computedDiscount }}</span>
                <img :src="deleteIcon" alt="Eliminar" @click="removeComment" class="icon-trash" v-tooltip="'Eliminar Descuento'"/>
            </div>
        </div>
        <div v-else :class="['input-comment', variant]">
            <span>{{ product?.comment }}</span>
        </div>
    </div>
</template>
<script>
import deleteIcon from '@/assets/icon-trash.svg';
import { getProductDiscountPercentageTotalAmount, getProductDiscountTotalAmount } from '../../shared/repositories/products/productsService';
export default {
    name: "CommentProduct",
    props: {
        product: {
            type: Object,
        },
        variant: {
            type: String,
            validator: function (value) {
                return [
                    "bg-gold",
                    "bg-purple"
                ].includes(value);
            },
        },
        discount: {
            type: String,
        }
    },
    computed: {
        buttonClass() {
            return `button-bistro ${this.variant}`;
        },
        computedDiscount() {
            let discountValue = 0;
            if (this.product.discountByAmount) {
                discountValue = getProductDiscountTotalAmount(this.product)
            } else if (this.product.discountByPercentage) {
                discountValue = getProductDiscountPercentageTotalAmount(this.product)
            }
            return new Intl.NumberFormat('es-CL', { style: 'currency', currency: 'CLP', minimumFractionDigits: 2 }).format(-discountValue);
        }
    },
    data() {
        return {
            deleteIcon 
        };
    },
    methods: {
        removeComment() {
            this.$emit("removeComment")
        },
    },
}
</script>

<style lang="scss">
.input-comment {
    display: flex;
    justify-content: space-between;
    color: white;
    padding: 4px;
    margin-top: 5px;
    border-radius: 2px;
    padding-bottom: 5px;   
    
    &.bg-gold {
        background-color: rgba(102, 128, 0, 0.5);
    }

    &.bg-purple {
        background-color: rgba(148, 22, 187, 0.5);
    }
}

.comment-content {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 14px; 
    margin-right: 3px;
}

.icon-trash {
    cursor: pointer; 
}
</style>