<template>
    <div id="modalOrderPayChange" class="modal modal-pdv">
        <div class="modal-header">
            <div class="image-title">
                <h2>Cambio a entregar</h2>
            </div>

            <div class="btnClose">
                <a href="#!" @click="closeModal" class="modal-action modal-close waves-effect waves-green btn-flat">
                    <span class="icon-Bistro-_Close"></span>
                </a>
            </div>
        </div>
        <hr>
        <div class="modal-body">
            <div class="precio">
                <span>{{ $filters.currency(this.order?.payments[0]?.receivedPaidAmount - this.order?.amount) }}</span>
            </div>
        </div>
        <div class="modal-footer">
            <ButtonBistro text="Continuar y cerrar" @handleClick="closeModal" />
        </div>
    </div>
</template>

<script>
import ButtonBistro from "@/features/shared/components/ButtonBistro.vue"
import router from "@/router";

export default {
    props: {
        order: {
            type: Object,
            required: true,
        },
    },
    methods: {
        closeModal() {
            router.push("/pdv/orders");
        }
    },
    components: {
        ButtonBistro,
    },
};
</script>

<style scoped>
.precio {
    font-size: 56px;
    font-weight: bold;
    text-align: center;
    margin: 30px 0;
}

.modal-footer {
    display: flex;
    justify-content: center;
    padding: 20px;
}
</style>