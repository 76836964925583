<template>

  <main>
    <!-- <section class="mainContain-info"> -->
    <article class="mainFilter filterEvent">
      <section class="mainFilter-title">
        <h1>Consolidado</h1>
      </section>

      <section class="mainFilter-filter">
        <div class="filterRecord tooltip">
          <div class="item">
            <h4>{{ data.trnxQuantitySalesShown }}</h4>
            <p>Ventas</p>
            <span class="tooltiptext">No contempla movimientos hechos con invitaciones o consumos
              empleados</span>
          </div>
          <div class="item">
            <h4>{{ data.trnxQuantityOrdersShown }}</h4>
            <p>Comandas</p>
          </div>
          <div class="item">
            <h4>{{ data.trnxQuantityVoidsShown }}</h4>
            <p>Anulaciones</p>
          </div>
          <div class="item">
            <h4>{{ data.trnxQuantityCreditNotesShown }}</h4>
            <p>Notas de Crédito</p>
          </div>
          <div class="item">
            <h4>
              <currency-style :valor="$filters.currency(data.trnxAmountShown)" />
            </h4>
            <p>Monto Total</p>
          </div>
        </div>
        <!-- OPCION MODAL -->
        <a v-if="mobile == false" href="#" @click="processReportInBackend(getOrdersUrl, excelParams)"
          class="waves-effect waves-light btn-link filterRecord download">
          <span class="icon-Bistro-_Download"></span>
          <span>Descargar Detalle</span>
        </a>
      </section>
    </article>

    <!-- LISTADO DE PRODUCTOS -->
    <article :class="{ errorLoading: data.orders && data.orders.length === 0 }" class="mainEvents salesDashboard">
      <!-- TIPO DE TRANSACCION -->
      <section :class="{ active: showFilter }" class="mainEvents-filter FilterMobile">
        <div class="containTransaction">
          <div class="searchDashboard">
            <div class="itemMessage">
              <div class="text">
                <h5>Filtra tu Consolidado</h5>
                <p>Fácil y Simple</p>
              </div>
              <a @click="search = !search" v-if="!search" id="btnSearchSales">
                <span class="icon-Bistro-_Search"></span>
              </a>
            </div>

            <div v-if="search" class="item">
              <a @click="search = !search" id="btnCloseSearch">
                <span class="icon-Bistro-_Close"></span>
              </a>

              <input v-model="criteria" type="text" class="inputFilter" placeholder="Nro de comanda o Nro de factura" />
            </div>
          </div>

          <ul class="containTransaction-lists">
            <SwitchToggle mode="list" v-model="paymentMethodFilters" label="medios de pago" :title="true" />

            <SwitchToggle mode="list" v-model="card" icon-class="icon-Bistro-_Visa" label="Tarjeta" />


            <SwitchToggle mode="list" v-model="cash" icon-class="icon-Bistro-_DolarMoney" label="Efectivo" />

            <SwitchToggle mode="list" v-model="online" icon-class="icon-Bistro-_Online" label="Online" />

            <SwitchToggle mode="list" v-model="qr" icon-class="icon-Bistro-_QR" label="QR" />

            <SwitchToggle mode="list" v-model="account" icon-class="icon-Bistro-_CuentaCliente"
              label="Cuenta Cliente" />

            <SwitchToggle mode="list" v-model="invitation" icon-class="icon-Bistro-_Invitacion1" label="Invitación" />

            <SwitchToggle mode="list" v-model="clerck" icon-class="icon-Bistro-_ConsumoEmpleados"
              label="Consumo Empleado" />

          </ul>

          <ul class="containTransaction-lists">
            <SwitchToggle mode="list" v-model="originFilters" label="origenes" :title="true" />


              <SwitchToggle mode="list" v-for="(bool, index) in data.origins" :key="index" :value="bool" :label="index"
                @change="chargeFilterOrigin(index, !bool)" />
          </ul>

          <ul class="containTransaction-lists">
            <SwitchToggle mode="list" v-model="typeFilters" label="tipos" :title="true" />

            <SwitchToggle mode="list" v-model="order_with_discount" label="Comanda c/descuento" />

            <SwitchToggle mode="list" v-model="order_no_discount" label="Comanda s/descuento" />

            <SwitchToggle mode="list" v-model="sale_with_discount" label="Venta c/descuento" />

            <SwitchToggle mode="list" v-model="sale_no_discount" label="Venta s/descuento" />


          </ul>
        </div>
        <button @click="showFilter = !showFilter" class="btnFilterMobile">
          <span class="icon-Bistro-_Filter"></span>
        </button>
      </section>

      <ul v-if="data.sales && data.sales.length > 0" class="mainEvents-history">
        <!-- Historial 1 -->
        <table-row v-for="(sale, index) in data.sales" target="consolidatedV2" :modal="true" :isSaleV2="true"
          :data="sale" :key="index" />
      </ul>
      <empty-element v-else-if="data.sales && data.sales.length === 0 && loaded" />
      <article v-else class="mainEvents-history">
        <article v-for="i in 4" :key="i" :class="sectionLoading">
        </article>
      </article>
    </article>

    <div v-if="data.sales && data.sales.length > 0 && isMoreOfOnePage()" class="base-pagination">
      <BasePagination :current-page="currentPage" :totalCount="data.totalCount" :countPerPage="countPerPage"
        class="list__pagination" @nextPage="pageChangeHandle('next')" @previousPage="pageChangeHandle('previous')"
        @loadPage="pageChangeHandle" />
    </div>
    <modal title="Resumen de" :showBillingActions="showBillingPage"></modal>
    <help-center />
  </main>

</template>
<script>
import axios from "axios";
import mixCommons from "../mixins/mixCommons";
import authCommons from "../mixins/authCommons";
import filterSaleTypesCommons from "../mixins/filterSaleTypesCommons";
import rolesCommons from "../mixins/rolesCommons";
import computeds from "../mixins/menuOptions";
import selectPdvs from "../mixins/select_pdvs";
import TableRow from "../components/TableRow";
import Modal from "../components/ModalSalesDetailsV2";
import EmptyElement from "../components/EmptyElement";
import { parseToQuery } from "../utils/utils";
import CurrencyStyle from "../components/CurrencyStyle";
import BasePagination from "../components/BasePagination";
import HelpCenter from "../components/HelpCenter";
import bOrdersCommons from "../mixins/bOrdersCommons";
import reportCommons from "../mixins/reportCommons";
import SwitchToggle from "@/components/SwitchToggle.vue";

export default {
  mixins: [
    authCommons,
    mixCommons,
    computeds,
    selectPdvs,
    rolesCommons,
    filterSaleTypesCommons,
    bOrdersCommons,
    reportCommons,
  ],
  data() {
    return {
      data: {},
      filterInterval: null,
      showFilter: false,
      search: false,
      excelParams: "",
      loaded: false,
      paymentMethodFilters: true,
      card: true,
      cash: true,
      online: true,
      qr: true,
      uuid: "",
      invitation: true,
      clerck: true,
      originFilters: true,
      typeFilters: true,
      account: true,
      criteria: "",
      params: {
        FilterPaymentWay: [],
        FilterOrigin: [],
        FilterTrnxStatus: [],
        FilterSaleTypes: [],
      },
      timeout: null,
      currentPage: 1,
      countPerPage: 50,
    };
  },
  async mounted() {
    document.title = `${this.appTitle}  - Consolidado de Transacciones`;
    this.$store.setHasMounted(false)
    this.$store.setLoadingSkeleton(true)
    if (this.isUserLoggedIn() === false) {
      axios
        .get("/api/auth/logout") /// OK
        .then(() => {
          this.$store.SET_USER(null);
          this.resetUser();
          this.setAutomaticLogin(false);
          location.href = "/login";
        });
    } else if (this.hasToShowThisOption(2) === false) {
      this.handleUnauthorizedAccess()

    }
    else {
      if (await this.checkSessionInBackendAsync()) {
        this.uuid = this.createUUID();
        this.$store.updateTopMenu(0);
        this.$store.updateSideMenu(1);

        if (this.getPeriodSelectionFromLocalStorage() !== null) {
          await this.$store.updateTabSelected(
            this.getPeriodSelectionFromLocalStorage()
          );
          this.params["Period"] = this.getPeriodSelectionFromLocalStorage().param;
        } else {
          await this.$store.initTabs();
        }
        this.$store.hideElements({});

        this.toggleMerchantSelectorByLabel("showMerchantSelectionFilter");
        this.$store.CALL_MERCHANTS();
        this.$store.updateCalendarType("range");

        this.params["CurrentPage"] = this.currentPage;
        this.params["CountPerPage"] = this.countPerPage;
        this.$store.setHasMounted(true)
      }

    }
  },
  watch: {
    paymentMethodFilters() {
      this.setAllPaymentTypes(this.paymentMethodFilters);
    },
    card() {
      this.chargeFilterPaymetWay(this.card, "TARJETA");
      this.pageChangeHandle(1);
    },
    cash() {
      this.chargeFilterPaymetWay(this.cash, "EFECTIVO");
      this.pageChangeHandle(1);
    },
    online() {
      this.chargeFilterPaymetWay(this.online, "ONLINE");
      this.pageChangeHandle(1);
    },
    qr() {
      this.chargeFilterPaymetWay(this.qr, "QR");
      this.pageChangeHandle(1);
    },
    account() {
      this.chargeFilterPaymetWay(this.account, "ACCOUNT");
      this.pageChangeHandle(1);
    },
    invitation() {
      this.chargeFilterPaymetWay(this.invitation, "INVITATION");
      this.pageChangeHandle(1);
    },
    clerck() {
      this.chargeFilterPaymetWay(this.clerck, "EMPLOYEE");
      this.pageChangeHandle(1);
    },
    originFilters() {
      for (var key in this.data.origins) {
        this.data.origins[key] = this.originFilters;
        this.chargeFilterOrigin(key, this.originFilters);
      }
    },
    typeFilters() {
      this.setAllTypes(this.typeFilters);
    },
    criteria() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.params["FilterCriteria"] = this.criteria;
        this.pageChangeHandle(1);
      }, 500);
    },
    tab() {
      if (this.tab.id !== 6 && this.loaded) {
        this.params["Period"] = this.tab.param;
        this.pageChangeHandle(1);
      }
    },
    dateCalendar() {
      if (this.dateCalendar.indexOf("Seleccione") < 0) {
        this.params["Period"] = this.tab.param;
        this.pageChangeHandle(1);
      }
    },
  },
  methods: {
    setAllPaymentTypes(bool) {
      this.card = bool;
      this.cash = bool;
      this.online = bool;
      this.qr = bool;
      this.account = bool;
      this.invitation = bool;
      this.clerck = bool;
    },
    isMoreOfOnePage() {
      return this.data.totalCount - this.countPerPage > 0;
    },
    chargeFilterPaymetWay(bool, payment) {
      if (bool) {
        var index = this.params.FilterPaymentWay.indexOf(payment);
        this.params.FilterPaymentWay.splice(index, 1);
      } else {
        this.params.FilterPaymentWay.push(payment);
      }
    },
    chargeFilterOrigin(origen, bool) {
      this.data.origins[origen] = bool;

      var index = this.params.FilterOrigin.indexOf(origen);
      if (bool) {
        this.params.FilterOrigin.splice(index, 1);
      } else if (index < 0) {
        this.params.FilterOrigin.push(origen);
      }
      this.pageChangeHandle(1);
    },
    addExcelParams() {
      let p = this.params["CurrentPage"];
      this.params["Uuid"] = this.uuid;
      this.params["CurrentPage"] = null;
      this.excelParams = parseToQuery(this.params);
      this.params["CurrentPage"] = p;
    },
    getData() {
      if (!this.$store.hasMounted) return
      this.addExcelParams();

      clearInterval(this.filterInterval);
      this.filterInterval = setInterval(() => {
        clearInterval(this.filterInterval);

        this.$store.initLoading(this);
        axios
          .get(`/api/consolidatedV2/?${parseToQuery(this.params)}`)
          .then((res) => {
            if(res?.data?.responseCode != 0) {
              this.showErrors(res);
            } else {
              this.data = res.data;
            }
            this.$store.finishLoading(this);
            this.loaded = true;
          })
          .catch((err) => {
            this.$store.errorLoading(this);
            this.showErrors(err.response);
          });
      }, 1000);
    },
  },
  computed: {
    getOrdersUrl() {
      if (
        this.tab.param == "Today" ||
        this.tab.param == "CurrentCashBox" ||
        this.tab.param == "PreviusCashBox"
      ) {
        // return '/consolidatedV2'
        return "/backendConsolidatedV2";
      } else return "/differedConsolidatedV2";
    },
  },
  components: {
    TableRow,
    Modal,
    EmptyElement,
    CurrencyStyle,
    BasePagination,
    HelpCenter,
    SwitchToggle
  },

};
</script>
<style scoped>
.skeleton-card {
  margin-bottom: 10px;
}
</style>