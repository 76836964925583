<script>
import { ref, get } from "firebase/database";
import { toast } from "vue3-toastify"
import { db } from "@/firebase.js";
import axios from "axios";
import "firebase/storage";

export default {
    
    data() {
		return {
			fileInputKey: Date.now(),
			storageRepo: "s3",
			urlPopUpImage: "",
			product: {
				image: null,
			},
			maxWidth: 1920,
			maxHeight: 1080,
			selectedFile: null,
			isImageLoading: false,
			isAnyDayOpen: false,
			hasAnyPaymentWayEnabled: false,
			conditionsForDeliveryHours: false,
			deliveryHours: null,
			sameOpeningHoursForDelivery: false,
			openingConfig: null,
			expandTabName: "",
			windowWidth: 0,
			shopCode: null,
			selectedProductIdList: null,
			isLoading: false,
			isUpdateAllOnlineStoreConfiguration: false,
			selectedLogoShopCode: null,
			selectedSecondScreenShopCode: null,
			selectedPrinterLogoShopCode: null,
			selectedFloorPlanShopCode: null,
			selectedBackgroundMenuShopCode: null,
			selectedMenuShopCode: null,
			selectedShopCodes: [],
			certificatePassword: null,
			profile: {},
			storeStatus: {
				chainCode: "",
				remoteId: "",
				fdsShopCode: "",
				status: true,
			},
			isMounting: true,
		};
	},
    watch: {
		selectedProductIdList() {
			if (this.selectedProductIdList == null) return;
			const qrCodes = [];
			const tableQrCodes = [];
			let subdomain = this.getBOrdersSubDomainByInstance();
			this.generateMenuQrs(
				this.shopCode,
				this.selectedProductIdList,
				subdomain,
				qrCodes
			);
			this.generateTableQrs(this.shopCode, subdomain, tableQrCodes);
		},
		onSelectedShopCodeChanged() {
			this.selectedShopCodes = [this.$store.getRefreshSelectedShopCode];
			this.selectProductListId(this.selectedShopCodes[0]);
		},
	},
	computed: {
		onSelectedShopCodeChanged() {
			/*eslint-disable*/
			this.shopCode = this.$store.getRefreshSelectedShopCode;
			return this.$store.getRefreshSelectedShopCode;
		},
		hasToShowMiscTab() {
			return this.expandTabName === "misc";
		},
		hasToShowMultideliveryTab() {
			return this.expandTabName === "multidelivery";
		},
		hasToShowLogoAndImagesTab() {
			return this.expandTabName === "logoAndImages";
		},
		hasToShowMyFiscalDataTab() {
			return (
				this.expandTabName === "fiscal_data" &&
				this.hasToShowFiscalDataSection
			);
		},
		hasToShowFiscalDataSection() {
			switch (process.env.VUE_APP_INSTANCE_CODE) {
				case "AR":
					return true;
				case "ES":
					return true;
				case "MX":
					if (this.shops !== undefined && this.shops.length > 0) {
						let shop = this.shops.find((x) => x.code == this.shopCode);
						if (shop) {
							return shop.fiscalData;
						}
					}
					return false;
				default:
					return false;
			}
		},
		hasToShowPrintTicket() {
			switch (process.env.VUE_APP_INSTANCE_CODE) {
				default:
				case "AR":
					return true;
				case "ES":
					return true;
				case "MX":
					return false;
			}
		},
		hasToShowQrTab() {
			return this.expandTabName === "qr";
		},
		hasToShowQrDownloadSection() {
			const shopTheme = this.getShopTheme(this.shopCode);
			this.$store.setCurrentShopTheme(shopTheme);
			switch (shopTheme) {
				case 1:
				default:
					return true;
				case 2:
					return false;
			}

		},
		hasToShowOnlineStoreTab() {
			return this.expandTabName === "online_store";
		},
		user() {
			return this.$store.getAuthUser;
		},
		shopLogoUrl() {
			return this.$store.getLogoUrl;
		},
		backgroundMenuUrl() {
			return this.$store.getBackgroundMenuUrl;
		},
		shops() {
			return this.$store.getProfileShops;
		},
		qrs() {
			return this.$store.getProfileQrs.slice(
				0,
				Math.min(10, this.$store.getProfileQrs.length)
			);
		},
		fdsInfos() {
			if (this.shops !== undefined && this.shops.length > 0) {
				let shop = this.shops.find((x) => x.code == this.shopCode);
				if (shop) return shop.fdsConfigs?.filter((x) => x.isEnabled);
			}
			return {};
			// return this.$store.getters.profileShops
			// 	.filter(x => x.code == this.shopCode)
			// 	.map((x) => x.fdsConfigs)[0]
			// 	.filter((x) => x.isEnabled);
		},
		tableQrs() {
			// return this.$store.getters.profileTableQrs
			return this.$store.getProfileTableQrs.slice(0, Math.min(10, this.$store.getProfileTableQrs.length)
			);
		},
		hasToShowMxCertificateUploadSection() {
			switch (process.env.VUE_APP_INSTANCE_CODE) {
				case "MX":
					return true;
				default:
					return false;
			}
		},
		hasToShowMultideliverySection() {
			switch (process.env.VUE_APP_INSTANCE_CODE) {
				case "UY":
				case "ENERGY_POS_SL":
					return false;
				default:
					return true;
			}
		},
		hasToShowArFiscalDataSection() {
			switch (process.env.VUE_APP_INSTANCE_CODE) {
				case "AR":
					return true;
				case "ES":
					return true;
				case "MX":
					if (this.shops !== undefined && this.shops.length > 0) {
						let shop = this.shops.find((x) => x.code == this.shopCode);
						if (shop) {
							return shop.fiscalData.isBillingEnabled;
						}
					}
					return false;
				default:
					return false;
			}
		},
		hasToShowOnlineStoreSection() {
			// return false;

			if (this.shops !== undefined && this.shops.length > 0) {
				let shop = this.shops.find((x) => x.code == this.shopCode);
				if (shop) return shop.hasToShowOnlineStoreSection;
			}
			return false;
		},
		getMiscTabStyles() {
			return {
				mainFilter: true,
				mainFilterMobile: true,
				"mainFilter-activeHover": true,
				"mainFilter-active": this.hasToShowMiscTab,
			};
		},
		getMultideliveryTabStyles() {
			return {
				mainFilter: true,
				mainFilterMobile: true,
				"mainFilter-activeHover": true,
				"mainFilter-active": this.hasToShowMultideliveryTab,
			};
		},
		getOnlineStoreTabStyles() {
			return {
				mainFilter: true,
				mainFilterMobile: true,
				"mainFilter-activeHover": true,
				"mainFilter-active": this.hasToShowOnlineStoreTab,
			};
		},
		getLogoAndImagedTabStyles() {
			return {
				mainFilter: true,
				mainFilterMobile: true,
				"mainFilter-activeHover": true,
				"mainFilter-active": this.hasToShowLogoAndImagesTab,
			};
		},
		getQrTabStyles() {
			return {
				mainFilter: true,
				mainFilterMobile: true,
				"mainFilter-activeHover": true,
				"mainFilter-active": this.hasToShowQrTab,
			};
		},
		getMyFiscalDataTabStyles() {
			return {
				mainFilter: true,
				mainFilterMobile: true,
				"mainFilter-activeHover": true,
				"mainFilter-active": this.hasToShowMyFiscalDataTab,
			};
		},
		isSettingLoading() {
			return this.isLoading;
		},
		isMountingComponent() {
			return this.isMounting;
		},
	},
    methods: {
		onMounted() {
			const t = this;
			this.selectedShopCodes = [];
			t.settingList = [];
			this.$store.setSettingList(t.settingList);
			this.toggleMerchantSelectorByLabel("showSettingListSelectionFilter");
			this.$store.hideElements({
				hideCalendar: true,
				hideFilters: false,
				hideTabs: true,
			});


			this.$store.initLoading(this);

			axios
				.get(`/api/users/userProfile`)
				.then((res) => {
					if (res.data.responseCode === 0) {
						this.$store.setUpdateProfileShops(res.data.shops);
						this.$store.setUpdateOriginalProfileShops(
							JSON.parse(JSON.stringify(res.data.shops))
						);
						t.settingList = res.data.shops.map((x) => {
							return {
								name: `${x.name} (${x.code})`,
								code: x.code,
							};
						});
						if (res.data.shops[0]) {
							this.selectedShopCodes = [res.data.shops[0].code];
						}
						this.$store.setSettingList(t.settingList);
						this.$store.finishLoading(this);
					} else {
						t.$store.errorLoading(this);
						t.showError(res.data.responseMessage);
					}
				})
				.catch((err) => {
					console.log(err)
					t.$store.errorLoading(this);
					t.showErrors(err.response);
				});
			this.getContextFromBackend();
			this.isMounting = false; // Lo hago para avisarle al componente de fiscalData MX que ejecute la funcion getEmiteCertificateStatus
		},
		copyConfigHours(openingHours, shopCode) {
			this.openingConfig = openingHours;
			this.shopCode = shopCode;
		},
		generateMenuQrs(shopCode, idList, subdomain, qrCodes) {
			const t = this;
			t.isLoading = true;

			const dataRef = ref(db, `listProducts/${idList}/menues`);

			// Usamos `get` en lugar de `once` para obtener los datos una vez en Firebase v9
			get(dataRef)
				.then((snapshot) => {
					const list = snapshot.val();
					let isUpdateAnyMenu = false;

					if (list !== null && list !== undefined) {
						list.forEach((m) => {
							if (m.uuid === null || m.uuid === undefined) {
								m.uuid = t.createUUID();
								isUpdateAnyMenu = true;
							}

							// Crear el QR con el logo y manejar la respuesta
							t.createQrWithLogo(
								`https://${subdomain}.bistrosoft.com/menu?commerceId=${shopCode}&menuUUID=${m.uuid}`
							)
								.then((url) => {
									qrCodes.push({
										code: shopCode,
										menuName: m.name,
										url: url,
									});

									// Actualizamos el store con los QR generados
									t.$store.setUpdateProfileQrs(qrCodes);
								})
								.catch((err) => {
									console.error("Error al generar el QR:", err);
								});
						});

						// Finalizamos el estado de carga
						t.isLoading = false;
					} else {
						console.warn("No se encontraron menús.");
						t.isLoading = false;
					}

					// Si se actualizó algún menú, actualizamos en Firebase
					if (isUpdateAnyMenu) {
						const updateRef = ref(db, `listProducts/${idList}/menues`);
						update(updateRef, list)
							.then(() => {
								console.log("Menús actualizados correctamente.");
							})
							.catch((error) => {
								console.error("Error al actualizar los menús:", error);
							});
					}

					// Marcamos que hemos terminado la carga
					t.$store.finishLoading(t);
				})
				.catch((error) => {
					console.error("Error al obtener los menús:", error);
					t.isLoading = false; // Nos aseguramos de detener la carga en caso de error
					t.$store.finishLoading(t);
				});
		},
		updateAllOnlineStoreConfiguration(item) {
			//Valido que todos los campos necesarios para que se guarde la sección esten completos.
			if (this.validateAllOnlineStoreConfiguration(item)) {
				//La validación fue exitosa, ejecuto todos los metodos de guardado

				//Nombre de la tienda (y proximamente deberia ir tambien el Editor de instrucciones para Tienda Online)
				this.onUpsertGeneralConfigOnlineStore(item);

				//Horarios de Apertura/Cierre
				this.onUpsertBOrdersOpeningHours(this.openingConfig, this.shopCode);

				//Horarios de Delivery
				this.onUpsertDeliverySchedule(
					this.deliveryHours,
					this.shopCode,
					this.sameOpeningHoursForDelivery
				);

				//Configuracion de delivery
				this.updateDeliveryConfigOnlineStore(
					item.hasStockValidation,
					item.deliveryZone,
					item.minimumAmountIsEnabled,
					item.minimumAmount,
					item.shippingCostIsEnabled,
					item.shippingCost,
					item.code
				);

				this.updateOtherConfigOnlineStore(item.hasToShowUnavailableProducts);

				//Tiempos de entrega
				this.updateDeliveryAndPickupTime(
					item.deliveryTimeIsEnabled,
					item.pickupTimeIsEnabled,
					item.minimumDeliveryTimeInMinutes,
					item.maximumDeliveryTimeInMinutes,
					item.minimumPickupTimeInMinutes,
					item.maximumPickupTimeInMinutes,
					item.code
				);

				//Medios de Pago Habilitados
				this.updatePaymentWayChange(item.availablePaymentWays, item.code);

				//Contacto y Redes Sociales
				this.updateHelpContactCenter(
					item.helpContactCenter,
					item.instagramUrl,
					item.facebookUrl
				);
				//Anuncio Personalizado
				this.updateCustomAd(item.popUpUrl, this.urlPopUpImage);

				//Guardo todos los cambios
				this.saveOnlineConfigInBackend(item.code);
			}
		},
		copyDeliveryConfigHours(
			deliveryHours,
			shopCode,
			sameOpeningHoursForDelivery
		) {
			this.deliveryHours = deliveryHours;
			this.shopCode = shopCode;
			this.sameOpeningHoursForDelivery = sameOpeningHoursForDelivery;
		},
		onSavingBanner(value, shopCode) {
			const t = this;
			t.$store.initLoading(t);
			this.profile.action = "bOrdersBannerUpdate";
			this.profile.bOrdersBanner = value;
			this.profile.shopCode = shopCode;
			this.profile.hasToShowOnlineStoreSection =
				this.hasToShowOnlineStoreSection;
			axios
				.post(`/api/users/userProfile`, this.profile)
				.then((res) => {
					if (res.data.responseCode === 0) {
						this.$store.setUpdateProfileShops(res.data.shops);
						t.showSuccess("Modificación realizada exitosamente");
					} else {
						t.showError(res.data.responseMessage);
					}

					t.$store.finishLoading(this);
				})
				.catch((err) => {
					t.$store.errorLoading(this);
					t.showErrors(err.response);
				});
		},
		onExpandSection(sectionName) {
			if (this.expandTabName === sectionName) {
				this.expandTabName = "";
			} else {
				this.expandTabName = sectionName;
				this.selectProductListId(this.shopCode);
			}
		},
		selectProductListId(shopCode) {
			console.log("shopCode:", shopCode);
			if (this.expandTabName === "qr") {
				this.isLoading = true;
				const t = this;
				const data = ref(db, `products/${shopCode}/idList`);
				get(data).then((snapshot) => {
					const idList = snapshot.val();

					console.log(idList)

					if (idList !== null) {
						console.log(idList)
						t.selectedProductIdList = idList;
					}
					t.isLoading = false;
				},);
			}
		},
		hasToShowThisProfile(shopCode) {
			if (this.selectedShopCodes.length === 0) {
				return true;
			} else {
				return this.selectedShopCodes.includes(shopCode);
			}
		},
		handleHasToPrintTicket(value, shopCode) {
			const t = this;
			t.$store.initLoading(t);
			this.profile.action = "HasToPrintTicketUpdate";
			this.profile.hasToPrintTicket = value;
			this.profile.shopCode = shopCode;
			axios
				.post(`/api/users/userProfile`, this.profile)
				.then((res) => {
					if (res.data.responseCode === 0) {
						this.$store.setUpdateProfileShops(res.data.shops);
						t.showSuccess("Modificación realizada exitosamente");
					} else {
						t.showError(res.data.responseMessage);
					}

					t.$store.finishLoading(this);
				})
				.catch((err) => {
					t.$store.errorLoading(this);
					t.showErrors(err.response);
				});
		},
		validateAllOnlineStoreConfiguration(item) {
			let errorMessages = []; //Acá voy a almacenar todos los mensajes de error para luego mostrarlos todos juntos.

			if (!item.bOrdersOpeningHours.enabled) {
				if (
					item.minimumAmountIsEnabled ||
					item.shippingCostIsEnabled ||
					item.deliveryZone.enabled
				) {
					this.showError("Se debe configurar el horario de delivery.");
					return false;
				}
				return true; //Estan los horarios de atención deshabilitados, no valido nada salvo la zona de delivery.
			}

			//Reviso que haya al menos un horario de atención.
			for (let k = 0; k < 7; k++) {
				if (item.bOrdersOpeningHours.hoursByDay[k].isOpen) {
					this.isAnyDayOpen = true;
					break;
				}
			}

			if (this.isAnyDayOpen) {
				//Reviso que haya un medio de pago habilitado al menos.
				for (let i = 0; i < item.availablePaymentWays.length; i++) {
					if (item.availablePaymentWays[i].isEnabled) {
						this.hasAnyPaymentWayEnabled = true;
						break;
					}
				}

				//Medio de pago?
				if (!this.hasAnyPaymentWayEnabled) {
					errorMessages.push("Se debe configurar al menos un medio de pago.");
				}

				// if(modo ??? Tienda_online??) {
				/// 1=Tienda Online, 2=Validador
				if (item.operationMode === 1) {
					// Telefono de Contacto?
					if (item.helpContactCenter == "") {
						errorMessages.push("Se debe configurar el telefono de contacto.");
					}
					// Tiempo de entrega Pickup?
					if (!item.pickupTimeIsEnabled) {
						errorMessages.push(
							"Se debe configurar el tiempo de entrega de pickup."
						);
					}
					// 2das condiciones
					for (let j = 0; j < 7; j++) {
						if (item.bOrdersDeliveryHours.hoursByDay[j].isOpen) {
							this.conditionsForDeliveryHours = true;
							break;
						}
					}

					if (
						this.conditionsForDeliveryHours ||
						this.sameOpeningHoursForDelivery
					) {
						if (!item.deliveryZone.enabled) {
							errorMessages.push("Se debe configurar la zona de delivery.");
						}

						if (!item.deliveryTimeIsEnabled) {
							errorMessages.push(
								"Se debe configurar el tiempo de entrega de delivery."
							);
						}
					} else {
						if (
							item.minimumAmountIsEnabled ||
							item.shippingCostIsEnabled ||
							item.deliveryZone.enabled
						) {
							errorMessages.push("Se debe configurar el horario de delivery.");
						}
					}
				}

				//Para cuando las 2das condiciones no las tengo que verificar y pasó las 1eras condiciones

				this.isAnyDayOpen = false;
				this.hasAnyPaymentWayEnabled = false;
				this.conditionsForDeliveryHours = false;
			} else {
				errorMessages.push("Se debe configurar el horario de atención.");
			}

			//Si hay mensajes de error los imprimo y devuelvo false, sino, devuelvo true (porque está todo bien)
			if (errorMessages.length > 0) {
				for (let error of errorMessages) {
					this.showError(error);
				}
				return false;
			} else {
				return true;
			}
		},
		handleHasToSendClosureReportByMail(value, shopCode) {
			const t = this;
			t.$store.initLoading(t);
			this.profile.action = "HasToSendClosureReportByMailUpdate";
			this.profile.hasToSendClosureReportByMail = value;
			this.profile.shopCode = shopCode;
			axios
				.post(`/api/users/userProfile`, this.profile)
				.then((res) => {
					if (res.data.responseCode === 0) {
						this.$store.setUpdateProfileShops(res.data.shops);
						t.showSuccess("Modificación realizada exitosamente");
					} else {
						t.showError(res.data.responseMessage);
					}

					t.$store.finishLoading(this);
				})
				.catch((err) => {
					t.$store.errorLoading(this);
					t.showErrors(err.response);
				});
		},
		async downloadTicketViewerQr(shopCode) {
			let domain = this.getWebAccessSubDomainByInstance();

			await this.createQrWithLogo(
				`${domain}/TicketsViewer?ShopCode=${shopCode}`
				// `https://${subdomain}.bistrosoft.com/TicketsViewer?ShopCode=${shopCode}`
			)
				.then((url) => {
					var download = document.createElement("a");
					download.href = url;
					download.download = `Consulta de tickets_${shopCode}.png`;
					download.click();
				})
				.catch((err) => {
					console.error(err);
				});
		},
		onSecondScreenVideoUploadedOk(url, shopCode) {
			this.$store.initLoading(this);
			this.profile.logoUrl = url;
			this.profile.action = "SecondScreenVideo";
			this.profile.isShopLogo = true;
			this.profile.imageType = "headerMenu";
			this.profile.shopCode = shopCode;

			axios
				.post(`/api/users/userProfile`, this.profile)
				.then((res) => {
					if (res.data.responseCode === 0) {
						this.$store.setUpdateProfileShops(res.data.shops);
						this.showSuccess(
							"Video de pantalla secundaria subido exitosamente"
						);
					} else {
						this.showError(res.data.responseMessage);
					}

					this.$store.finishLoading(this);
					document.body.style.overflow = "auto";
				})
				.catch((err) => {
					this.$store.errorLoading(this);
					this.showErrors(err.response);
				});
		},
		removeImgByShopCodeAndType(shopCode, imgType, iconId) {
			const t = this;
			this.$store.initLoading(this);
			this.profile.logoUrl = null;
			this.profile.isShopLogo = imgType === "shopLogo" ? true : false;
			this.profile.action = "PictureUpload";
			this.profile.imageType = imgType;
			this.profile.shopCode = shopCode;
			axios
				.post(`/api/users/userProfile`, this.profile)
				.then((res) => {
					if (res.data.responseCode === 0) {
						this.$store.setUpdateProfileShops(res.data.shops);
						t.$store.finishLoading(this)
						t.showSuccess("Imágen eliminada exitosamente");
						document.getElementById(iconId).style.visibility = "hidden";
					} else {
						t.$store.finishLoading(this)
						t.showError(res.data.responseMessage);

					}
					;
				})
				.catch((err) => {
					t.$store.errorLoading(this);
					t.showErrors(err.response);
				});
		},
		onFixedImageMenuUploadedOk(url, shopCode) {
			this.$store.initLoading(this);
			this.profile.logoUrl = url;
			this.profile.action = "PictureUpload";
			this.profile.isShopLogo = true;
			this.profile.imageType = "backgroundMenu";
			this.profile.shopCode = shopCode;

			axios
				.post(`/api/users/userProfile`, this.profile)
				.then((res) => {
					if (res.data.responseCode === 0) {
						this.$store.setUpdateProfileShops(res.data.shops);
						this.showSuccess(
							"Imágen fija JPG/PNG para menú subida exitosamente"
						);
					} else {
						this.showError(res.data.responseMessage);
					}
					this.$store.finishLoading(this);

					$("#__layout").css("pointer-events", "all");
				})
				.catch((err) => {
					this.$store.errorLoading(this);
					this.showErrors(err.response);
				});
		},
		onFloorPlanUploadedOk(url, shopCode) {
			this.$store.initLoading(this);
			this.profile.logoUrl = url;
			this.profile.isShopLogo = false;
			this.profile.action = "PictureUpload";
			this.profile.imageType = "floorPlan";
			this.profile.shopCode = shopCode;
			axios
				.post(`/api/users/userProfile`, this.profile)
				.then((res) => {
					if (res.data.responseCode === 0) {
						this.$store.setUpdateProfileShops(res.data.shops);
						this.showSuccess("Mapa estáticFo de mesas cargado exitosamente");
					} else {
						this.showError(res.data.responseMessage);
					}

					this.$store.finishLoading(this);
				})
				.catch((err) => {
					this.$store.errorLoading(this);
					this.showErrors(err.response);
				});
		},
		generateTableQrs(shopCode, subdomain, tableQrCodes) {
			const t = this;
			t.isLoading = true;

			const dataRef = ref(db, `shops/${shopCode}/borders`);
			get(dataRef)
				.then((snapshot) => {
					const data = snapshot.val();

					if (data !== null && data !== undefined) {
						let list = Object.entries(data);
						if (list.length > 0) {
							const qrPromises = list.slice(0, Math.min(10, list.length)).map((table) => {
								return t.createQrWithLogo(
									`https://${subdomain}.bistrosoft.com/menu/order?commerceId=${shopCode}&table=${table[0]}`
								)
									.then((url) => {
										tableQrCodes.push({
											code: shopCode,
											tableName: table[1].name !== undefined ? table[1].name : table[0],
											url: url,
										});
										t.$store.setUpdateProfileTableQrs(tableQrCodes);
									})
									.catch((err) => {
										console.error("Error al generar el QR para la mesa:", err);
									});
							});
							Promise.all(qrPromises).then(() => {
								t.isLoading = false;
								t.$store.finishLoading(t)
							});
						}
					}
				})
				.catch(() => {
					t.isLoading = false;
					t.$store.finishLoading(t);
				});
		},
		onBackgroundMenuUploadedOk(url, shopCode) {
			this.$store.initLoading(this);
			this.profile.logoUrl = url;
			this.profile.action = "PictureUpload";
			this.profile.isShopLogo = true;
			this.profile.imageType = "headerMenu";
			this.profile.shopCode = shopCode;

			axios
				.post(`/api/users/userProfile`, this.profile)
				.then((res) => {
					if (res.data.responseCode === 0) {
						this.$store.setUpdateProfileShops(res.data.shops);
						this.showSuccess("Imágen de carta QR subida exitosamente");
					} else {
						this.showError(res.data.responseMessage);
					}

					this.$store.finishLoading(this);
					$("#__layout").css("pointer-events", "all");
				})
				.catch((err) => {
					this.$store.errorLoading(this);
					this.showErrors(err.response);
				});
		},
		onSecondScreenUploadedOk(url, shopCode) {
			this.$store.initLoading(this);
			this.profile.logoUrl = url;
			this.profile.isShopLogo = false;
			this.profile.action = "PictureUpload";
			this.profile.imageType = "secondScreen";
			this.profile.shopCode = shopCode;
			axios
				.post(`/api/users/userProfile`, this.profile)
				.then((res) => {
					if (res.data.responseCode === 0) {
						this.$store.setUpdateProfileShops(res.data.shops);
						this.showSuccess("Imágen de segunda pantalla subida exitosamente");
					} else {
						this.showError(res.data.responseMessage);
					}

					this.$store.finishLoading(this);
					$("#__layout").css("pointer-events", "all");
				})
				.catch((err) => {
					this.$store.errorLoading(this);
					this.showErrors(err.response);
				});
		},
		onPrinterLogoUploadedOk(url, shopCode) {
			this.$store.initLoading(this);
			this.profile.logoUrl = url;
			this.profile.isShopLogo = false;
			this.profile.action = "PictureUpload";
			this.profile.imageType = "printerLogo";
			this.profile.shopCode = shopCode;
			axios
				.post(`/api/users/userProfile`, this.profile)
				.then((res) => {
					if (res.data.responseCode === 0) {
						this.$store.setUpdateProfileShops(res.data.shops);
						this.showSuccess("Imágen de impresora subida exitosamente");
					} else {
						this.showError(res.data.responseMessage);
					}

					this.$store.finishLoading(this);
					$("#__layout").css("pointer-events", "all");
				})
				.catch((err) => {
					this.$store.errorLoading(this);
					this.showErrors(err.response);
				});
		},
		onShopLogoUploadedOk(url, shopCode) {
			this.$store.initLoading(this);
			this.profile.logoUrl = url;
			this.profile.action = "PictureUpload";
			this.profile.isShopLogo = true;
			this.profile.imageType = "shopLogo";
			this.profile.shopCode = shopCode;

			axios
				.post(`/api/users/userProfile`, this.profile)
				.then((res) => {
					if (res.data.responseCode === 0) {
						this.$store.setUpdateProfileShops(res.data.shops);
						this.showSuccess("Logo cargado exitosamente");
					} else {
						this.showError(res.data.responseMessage);
					}

					this.$store.finishLoading(this);
					$("#__layout").css("pointer-events", "all");
				})
				.catch((err) => {
					this.$store.errorLoading(this);
					this.showErrors(err.response);
				});
		},
		checkQrsShop(qrShopCode, shopCode) {
			return qrShopCode === shopCode;
		},
		hasToShowDeliveryZone(item) {
			return item.deliveryZone != null && item.deliveryZone != undefined;
		},
		updateDeliveryZone(updatedDeliveryZoneConfig, oldDeliveryZoneConfig) {
			//Actualizo los datos viejos por los nuevos
			oldDeliveryZoneConfig.isRadialZoneSelected =
				updatedDeliveryZoneConfig.isRadialZoneSelected;
			oldDeliveryZoneConfig.radius = updatedDeliveryZoneConfig.radius;
			oldDeliveryZoneConfig.polygonCoords =
				updatedDeliveryZoneConfig.polygonCoords;
		},
		validateDeliveryConfig(config, openingConfig, sameOpeningHoursForDelivery) {
			//Mismos horarios de atención
			if (!sameOpeningHoursForDelivery) {
				let errorMsg = "";
				if (!openingConfig.enabled) {
					return errorMsg;
				}

				const regex = /^(?:[01]\d|2[0-3]):[0-5]\d$/;

				openingConfig.hoursByDay.forEach((day) => {
					if (day.isOpen) {
						day.hours.forEach((hour) => {
							if (!regex.test(hour.from)) {
								errorMsg =
									"El horario 'desde' del día " +
									this.getDayLabelByKey(day.dayKey) +
									" es inválido";
							}
							if (!regex.test(hour.to)) {
								errorMsg =
									"El horario 'hasta' del día " +
									this.getDayLabelByKey(day.dayKey) +
									" es inválido";
							}
							let from = parseFloat(hour.from.replace(":", "."));
							let to = parseFloat(hour.to.replace(":", "."));
							if (from > to) {
								errorMsg =
									"El horario 'desde' del día " +
									this.getDayLabelByKey(day.dayKey) +
									" debe ser menor al horario 'hasta'";
							}
						});
					}
				});

				return errorMsg;
			} else {
				//Horarios personalizados para delivery
				let errorMsg = "";
				if (!config.enabled) {
					return errorMsg;
				}

				const regex = /^(?:[01]\d|2[0-3]):[0-5]\d$/;

				config.hoursByDay.forEach((day) => {
					if (day.isOpen) {
						day.hours.forEach((hour) => {
							if (!regex.test(hour.from)) {
								errorMsg =
									"El horario 'desde' del día " +
									this.getDayLabelByKey(day.dayKey) +
									" es inválido";
							}
							if (!regex.test(hour.to)) {
								errorMsg =
									"El horario 'hasta' del día " +
									this.getDayLabelByKey(day.dayKey) +
									" es inválido";
							}
							let from = parseFloat(hour.from.replace(":", "."));
							let to = parseFloat(hour.to.replace(":", "."));
							if (from > to) {
								errorMsg =
									"El horario 'desde' del día " +
									this.getDayLabelByKey(day.dayKey) +
									" debe ser menor al horario 'hasta'";
							}
						});
					}
				});
				return errorMsg;
			}
		},
		onUpsertGeneralConfigOnlineStore(item) {
			this.profile.displayedName = item.displayedName;
		},
		onUpsertBOrdersOpeningHours(openingHours) {
			//Valido que esten correctos los horarios de atención
			const errorMsg = this.validateConfig(openingHours);
			if (errorMsg !== "") {
				toast.error(errorMsg);
				return false;
			}

			const t = this;
			t.$store.initLoading(t);

			this.profile.bOrdersOpeningHours = openingHours;
		},
		validateConfig(config) {
			let errorMsg = "";
			if (!config.enabled) {
				return errorMsg;
			}

			const regex = /^(?:[01]\d|2[0-3]):[0-5]\d$/;

			config.hoursByDay.forEach((day) => {
				if (day.isOpen) {
					day.hours.forEach((hour) => {
						if (!regex.test(hour.from)) {
							errorMsg =
								"El horario 'desde' del día " +
								this.getDayLabelByKey(day.dayKey) +
								" es inválido";
						}
						if (!regex.test(hour.to)) {
							errorMsg =
								"El horario 'hasta' del día " +
								this.getDayLabelByKey(day.dayKey) +
								" es inválido";
						}
						let from = parseFloat(hour.from.replace(":", "."));
						let to = parseFloat(hour.to.replace(":", "."));
						if (from > to) {
							errorMsg =
								"El horario 'desde' del día " +
								this.getDayLabelByKey(day.dayKey) +
								" debe ser menor al horario 'hasta'";
						}
					});
				}
			});

			return errorMsg;
		},
		onUpsertDeliverySchedule(
			deliveryHours,
			shopCode,
			sameOpeningHoursForDelivery
		) {
			// valido que esten correctos los horarios de delivery
			const errorMsg = this.validateDeliveryConfig(
				deliveryHours,
				this.openingConfig,
				sameOpeningHoursForDelivery
			);
			if (errorMsg !== "") {
				toast.error(errorMsg);
				return;
			}
			this.profile.BOrdersDeliveryHours = deliveryHours;
			this.profile.sameOpeningHoursForDelivery = sameOpeningHoursForDelivery;
		},
		onFileSelected(event) {
			const t = this;
			this.selectedFile = event.target.files[0];
			this.isImageLoading = true;
			this.isUpdateAllOnlineStoreConfiguration = true;
			if (!this.selectedFile) return;
			if (
				(this.selectedFile.name.endsWith(".jpeg") ||
					this.selectedFile.name.endsWith(".jpg") ||
					this.selectedFile.name.endsWith(".bmp") ||
					this.selectedFile.name.endsWith(".png")) === false
			) {
				this.$store.errorLoading(this);
				this.isImageLoading = false;
				this.isUpdateAllOnlineStoreConfiguration = false;
				this.showError(`Las imágenes deben ser .jpg, .jpeg, .bmp o .png`);
				return;
			}
			const filesize = (this.selectedFile.size / 1024 / 1024).toFixed(4); // MB
			if (filesize > 2) {
				// 2MB
				this.$store.errorLoading(this);
				this.isImageLoading = false;
				this.isUpdateAllOnlineStoreConfiguration = false;
				this.showError(
					`El tamaño de la foto no puede superar los 2MB. La foto que subiste tiene ${filesize}MB`
				);
				return;
			}

			var _URL = window.URL || window.webkitURL;
			var file, img;
			if ((file = this.selectedFile)) {
				img = new Image();
				var objectUrl = _URL.createObjectURL(file);
				img.onload = function () {
					_URL.revokeObjectURL(objectUrl);
					if (this.width > t.maxWidth) {
						t.showError(
							`El ancho de la imagen es de ${this.width}px. No puede superar los ${t.maxWidth}px`
						);
						t.$store.errorLoading(t);
						t.isImageLoading = false;
						t.isUpdateAllOnlineStoreConfiguration = false;
						return;
					}

					if (this.height > t.maxHeight) {
						t.showError(
							`El alto de la imagen es de ${this.height}px. No puede superar los ${t.maxHeight}px`
						);
						t.$store.errorLoading(t);
						t.isImageLoading = false;
						t.isUpdateAllOnlineStoreConfiguration = false;
						return;
					}

					t.saveMediaInCloudStorage(
						t,
						"logos/" + t.shopCode + "/" + t.selectedFile.name,
						(url) => {
							t.urlPopUpImage = url;
							t.isImageLoading = false;
							t.isUpdateAllOnlineStoreConfiguration = false;
						},
						false
					);
				};
				img.src = objectUrl;
			}
		},
		refreshPage() {
			setInterval(location.reload(), 1000);
		},
		updatePaymentWayChange(paymentWays) {
			this.profile.availablePaymentWays = paymentWays;
		},
		updateDeliveryConfigOnlineStore(
			hasStockValidation,
			deliveryZone,
			minimumAmountisEnabled,
			valueMinimumAmount,
			shippingCostIsEnabled,
			valueShippingCost,
			shopCode
		) {
			this.profile.deliveryZone = deliveryZone;
			this.profile.minimumAmountIsEnabled = minimumAmountisEnabled;
			this.profile.minimumAmount = valueMinimumAmount;
			this.profile.shippingCostIsEnabled = shippingCostIsEnabled;
			this.profile.shippingCost = valueShippingCost;
			this.profile.hasStockValidation = hasStockValidation;
		},
		updateOtherConfigOnlineStore(hasToShowUnavailableProducts) {
			this.profile.hasToShowUnavailableProducts = hasToShowUnavailableProducts;
		},
		updateHelpContactCenter(helpContactCenter, instagramUrl, facebookUrl) {
			this.profile.helpContactCenter = helpContactCenter;
			this.profile.instagramUrl = instagramUrl;
			this.profile.facebookUrl = facebookUrl;
		},
		updateCustomAd(popUpUrl, urlPopUpImage) {
			this.profile.popUpUrl = popUpUrl;
			this.profile.urlPopUpImage = urlPopUpImage;
			this.profile.uuidPopUp = this.createUUID();
		},
		saveOnlineConfigInBackend(shopCode) {
			const t = this;

			this.profile.action = "bOrdersAllOnlineStoreUpdate";
			this.profile.shopCode = shopCode;

			this.isUpdateAllOnlineStoreConfiguration = true;

			axios
				.post(`/api/users/userProfile`, this.profile)
				.then((res) => {
					if (res.data.responseCode === 0) {
						this.$store.setUpdateProfileShops(res.data.shops);
						t.showSuccess("Modificación realizada exitosamente");
					} else {
						t.showError(res.data.responseMessage);
					}

					t.$store.finishLoading(this);
				})
				.catch((err) => {
					t.$store.errorLoading(this);
					t.showErrors(err.response);
				})
				.finally(() => {
					t.isUpdateAllOnlineStoreConfiguration = false;
				});
		},
		deleteImage() {
			const t = this;

			this.profile.urlPopUpImage = "";
			this.profile.uuidPopUp = "";
			this.profile.shopCode = this.shopCode;
			this.profile.action = "bOrdersDeleteImagePopUpUpdate";

			this.isUpdateAllOnlineStoreConfiguration = true;
			this.fileInputKey = Date.now(); //Para re-renderizar el input file para la imagen y que aparezca denuevo el texto "Sin archivos selecionados" en el input.

			axios
				.post(`/api/users/userProfile`, this.profile)
				.then((res) => {
					if (res.data.responseCode === 0) {
						this.$store.setUpdateProfileShops(res.data.shops);
						t.showSuccess("Modificación realizada exitosamente");
					} else {
						t.showError(res.data.responseMessage);
					}

					t.$store.finishLoading(this);
				})
				.catch((err) => {
					t.$store.errorLoading(this);
					t.showErrors(err.response);
				})
				.finally(() => {
					t.isUpdateAllOnlineStoreConfiguration = false;
				});
		},
		updateDeliveryAndPickupTime(
			deliveryTimeIsEnabled,
			pickupTimeIsEnabled,
			minimumDeliveryTimeInMinutes,
			maximumDeliveryTimeInMinutes,
			minimumPickupTimeInMinutes,
			maximumPickupTimeInMinutes,
			shopCode
		) {
			this.profile.deliveryTimeIsEnabled = deliveryTimeIsEnabled;
			this.profile.pickupTimeIsEnabled = pickupTimeIsEnabled;
			this.profile.minimumDeliveryTimeInMinutes = minimumDeliveryTimeInMinutes?.toString();
			this.profile.maximumDeliveryTimeInMinutes = maximumDeliveryTimeInMinutes?.toString();
			this.profile.minimumPickupTimeInMinutes = minimumPickupTimeInMinutes?.toString();
			this.profile.maximumPickupTimeInMinutes = maximumPickupTimeInMinutes?.toString();
		},
		downloadQr(url, fileName) {
			var download = document.createElement("a");
			download.href = url;
			download.download = `${fileName}.png`;
			download.click();
		},
		onMenuFileMouseEnter(code) {
			this.selectedMenuShopCode = code;
		},
		onShopLogoFileMouseEnter(code) {
			this.selectedBackgroundMenuShopCode = code;
		},
		onBackgroundMenuFileMouseEnter(code) {
			this.selectedLogoShopCode = code;
		},
		onSecondScreenFileMouseEnter(code) {
			this.selectedSecondScreenShopCode = code;
		},
		onPrinterLogoFileMouseEnter(code) {
			this.selectedPrinterLogoShopCode = code;
		},
		onFloorPlanFileMouseEnter(code) {
			this.selectedFloorPlanShopCode = code;
		},
		removeVideoByShopCodeAndType(shopCode, imgType) {
			const t = this;

			this.$store.initLoading(this);
			this.profile.logoUrl = null;
			this.profile.isShopLogo = imgType === "shopLogo" ? true : false;
			this.profile.action = "SecondScreenVideo";
			this.profile.imageType = imgType;
			this.profile.shopCode = shopCode;
			axios
				.post(`/api/users/userProfile`, this.profile)
				.then((res) => {
					if (res.data.responseCode === 0) {
						this.$store.setUpdateProfileShops(res.data.shops);
						t.showSuccess(
							"Video de pantalla secundaria eliminado exitosamente"
						);
						setTimeout(() => {
							window.location.reload();
						}, 1000);
					} else {
						t.showError(res.data.responseMessage);
					}

					t.$store.finishLoading(this);
				})
				.catch((err) => {
					t.$store.errorLoading(this);
					t.showErrors(err.response);
				});
		},
		onCheckShopCode(shopCode) {
			var index = this.profile.images.checkedShopCodes.indexOf(shopCode);
			if (index === -1) {
				this.profile.images.checkedShopCodes.push(shopCode);
			} else {
				this.profile.images.checkedShopCodes.splice(index, 1);
			}
		},
		getValue(shopCode) {
			if (this.profile.images === null || this.profile.images === undefined)
				return false;

			let code = this.profile.images.checkedShopCodes.filter(
				(t) => t === shopCode
			);

			if (code !== undefined && code !== null && code.length > 0) {
				return true;
			} else {
				return false;
			}
		},
	},
}
</script>