<template>
  <div id="modalProduct" class="modal modal-block-outside" style="overflow-y: auto !important"
    @keydown.esc="closeModal()">
    <div class="modal-header">
      <div v-if="product.image" class="image-cover">
        <div class="image-title">
          <h4 v-if="isNewProduct">Crear Producto</h4>
          <h4 v-else>Editar Producto</h4>
          <p>Fácil y Simple</p>
        </div>

        <img class="object-fit_cover" :src="product.image" />
      </div>

      <div v-else class="image-title">
        <h4 v-if="isNewProduct">Crear Producto</h4>
        <h4 v-else>Editar Producto</h4>
        <p>Fácil y Simple</p>
      </div>

      <!-- CLOSE -->
      <div class="btnClose">
        <a href="#!" @click="closeModal()" class="modal-action modal-close waves-effect waves-green btn-flat">
          <span class="icon-Bistro-_Close"></span>
        </a>
      </div>
    </div>

    <div class="modal-body">
      <form action class="formGeneral">
        <article class="formMain article-product">
          <div class="itemForm">
            <label class="label" for>Nombre del Producto:</label>
            <input class="input" v-model="/* eslint-disable */ product.name /* eslint-enabled */" type="text"
              placeholder="Ingrese nombre del producto" />
          </div>

          <div class="itemForm itemFormForMobile">
            <label for>SKU:</label>
            <input v-model="/* eslint-disable */ product.sku /* eslint-enabled */" type="text" maxlength="14"
              placeholder="SKU" />
          </div>

          <div class="itemForm">
            <label for>Categoría:</label>
            <select v-if="selectedList != null" v-model="
                /* eslint-disable */ product.department /* eslint-enabled */
      " class="browser-default">
              <option value disabled selected>Seleccione Categoría</option>
              <option v-for="category in selectedList.categories" :value="category.name" :key="category.name">
                {{ category.name }}
              </option>
            </select>
            <div class="fix-width-product">
              <div class="itemForm itemFormForMobile fixItemFormForWeightable">
                <div class="container-weightable">
                  <label for>Pesable:</label>
                  <div class="switch">
                    <label>
                      <input v-model="
                          /* eslint-disable */ product.weightable /* eslint-enabled */
      " type="checkbox" />
                      <span class="lever" style="margin-top: 19px"></span>
                    </label>
                  </div>
                </div>
                <div class="container-unit">
                  <label for>Unidad:</label>
                  <select :disabled="product.weightable ? false : true" v-model="
                      /* eslint-disable */ product.measureUnit /* eslint-enabled */
      " class="browser-default">
                    <option v-for="(measureUnit, index) in getMeasureUnits()" :value="measureUnit" :key="index">
                      {{ measureUnit }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="itemForm itemFormForMobile fixItemForIva">
                <vat-selector ref="vatSelector" :rootVat="product.rootVat" :exempt="product.exempt"
                  :nonTaxed="product.nonTaxed" @onVatChanged="onVatChanged" />
              </div>
            </div>
          </div>

          <div class="itemForm">
            <label for>Descripción del Producto:</label>
            <textarea v-model="
                /* eslint-disable */ product.description /* eslint-enabled */
      " placeholder="Ingrese descripción del producto" />
          </div>

          <div class="itemForm" v-if="hasToShowBillingProductFields">
            <div class="fix-width-product">
              <div class="itemForm itemFormForMobile">
                <label for>Clave de producto CFDI:</label>
                <input v-model="
                    /* eslint-disable */ product.billingProductKey /* eslint-enabled */
      " type="text" placeholder="Ingrese clave de producto CFDI" />
              </div>
              <div class="itemForm itemFormForMobile fixItemForSmallInput">
                <label class="label" for>Unidad CFDI:</label>
                <input class="input" v-model="
                    /* eslint-disable */ product.billingProductUnit /* eslint-enabled */
      " type="text" placeholder="Ingrese unidad CFDI" />
              </div>
            </div>
          </div>

          <div class="itemForm" v-if="hasToShowIEPSTax">
            <div class="fix-width-product">
              <div class="itemForm itemFormForMobile">
                <label for>IEPS:</label>
                <select v-model="
                    /* eslint-disable */ product.iepsMxTaxRate /* eslint-enabled */
      " class="browser-default">
                  <option v-for="ieps in getIEPSTaxValues()" :value="ieps" :key="ieps">
                    {{ ieps }} %
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div v-if="hasToShowIsRg5329" class="itemForm tribute">
            <label for>ARCA RG 5329:</label>
            <div class="switch">
              <label>
                <input v-model="
                    /* eslint-disable */ product.rg5329 /* eslint-enabled */
      " type="checkbox" />
                <span class="lever"></span>
              </label>
            </div>
          </div>

          <div class="itemForm tribute">
            <label for>Alerta de stock mínimo:</label>
          </div>

          <div class="itemForm alertStock">
            <input v-model="product.minimunStockQuantity" type="number" placeholder="Stock Mínimo"  />
          </div>

          <div v-if="isImageLoading" class="itemForm selectImage beforeAdditional imageSpinner">
            <SpinnerComp />
          </div>
          <div v-else class="itemForm beforeAdditional itemForm-products-image">
            <label class="selectImage-label" for>(Formato: .jpg, .jpeg o .png. Tamaño Max: 2MB. Resolución: Min. 1440px*1080px - Max. 3000px*3000px)</label>
            <div class="selectImage">
              <label class="selectImage-label" for>Imagen:</label>
              <input type="file" ref="fileupload" @change="onPrimaryFileSelected" />
              <a v-if="product.image" class="selectImage-trashBin" @click="deleteImage">
                <span class="icon-Bistro-_Trash_bin"></span>
              </a>
            </div>
          </div>
          <div v-if="isSecondaryImageLoading && hasToShowSecondaryProductPicture"
            class="itemForm selectImage beforeAdditional imageSpinner">
            <SpinnerComp />
          </div>
          <div v-else-if="!isSecondaryImageLoading && hasToShowSecondaryProductPicture
      " class="itemForm selectImage beforeAdditional">
            <label class="selectImage-label" for>Imagen secundaria para Tienda Online:</label>
            <input type="file" ref="fileupload" @change="onSecondaryFileSelected" />
            <a v-if="secondaryImage" class="selectImage-trashBin" @click="deleteSecondaryImage">
              <span class="icon-Bistro-_Trash_bin"></span>
            </a>
          </div>
          <div v-if="isSecondaryPreviewImageLoading && secondaryImage"
            class="itemForm selectImage beforeAdditional imageSpinner">
            <SpinnerComp />
          </div>
          <div v-else-if="!isSecondaryPreviewImageLoading && secondaryImage"
            class="itemForm selectImage beforeAdditional">
            <label class="selectImage-label" for>Preview imagen secundaria para Tienda Online:</label>
            <input type="file" ref="fileupload" @change="onSecondaryPreviewFileSelected" />
            <a v-if="secondaryPreviewImage" class="selectImage-trashBin" @click="deleteSecondaryPreviewImage">
              <span class="icon-Bistro-_Trash_bin"></span>
            </a>
          </div>

          <div class="itemForm itemForm-double-sized">
            <label for>Receta del producto:</label>
            <textarea v-model="
                /* eslint-disable */ product.recipeSteps /* eslint-enabled */
      " placeholder="Ingrese la receta o los pasos para cocinar este producto" />
          </div>

          <h4 class="aditional-text" v-if="product.type === 'ADICIONAL'">
            Este producto contiene adicionales
          </h4>
        </article>
      </form>
    </div>

    <div class="modal-footer">
      <article class="btn-main">
        <a v-if="isNewProduct === false" class="deleteProduct" @click.prevent="deleteProduct">
          <span class="icon-Bistro-_Trash_bin"></span><span>Eliminar producto</span>
        </a>
        <SpinnerComp v-if="isLoading" />
        <a v-else @click="upsertProduct" type="submit">
          <p v-if="isNewProduct">{{ buttonAction }}</p>
          <p v-else>{{ buttonUpdate }}</p>
        </a>
      </article>
    </div>
  </div>
</template>

<script>
import CurrencyStyle from "../components/CurrencyStyle";
import SpinnerComp from "../components/SpinnerComp";
import currencyCommons from "../mixins/currencyCommons.vue";
import mixCommons from "../mixins/mixCommons";
import productV2Commons from "../mixins/productV2Commons";
import pictureCommons from "../mixins/pictureCommons.vue";
import instanceVisibilityCommons from "../mixins/instanceVisibilityCommons.vue";
import BadgeComp from "../components/BadgeComp";
import VatSelector from "../components/VatSelector";
import mixTester from "../mixins/mixTester";
// import * as firebase from "firebase/app";
// import "firebase/storage";
//import { db } from "~/plugins/firebase";
import $ from "jquery";
import { toast } from "vue3-toastify";
import { db } from "../firebase.js";
import { ref, get, set } from "firebase/database";

/* eslint-disable */

export default {
  mixins: [
    mixTester,
    currencyCommons,
    mixCommons,
    pictureCommons,
    instanceVisibilityCommons,
    productV2Commons
  ],
  props: {
    product: {
      type: Object,
      default: () => {
        return {};
      },
    },
    selectedList: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isNewProduct: {
      type: Boolean,
    },
    currentSku: {
      type: String,
      default: () => {
        return "";
      },
    },
    maxWidth: {
      type: Number,
      default: 3000,
    },
    maxHeight: {
      type: Number,
      default: 3000,
    },
    minWidth: {
      type: Number,
      default: 1440,
    },
    minHeight: {
      type: Number,
      default: 1080,
    },
  },
  watch: {
    product() {
      this.$refs.vatSelector.cleanSelectedVat(
        this.isNewProduct,
        this.product.rootVat,
        this.product.exempt,
        this.product.nonTaxed
      );
      this.secondaryImage = this.product.secondaryImage;
      this.secondaryPreviewImage = this.product.secondaryPreviewImage;
    },
  },
  data() {
    return {
      modalTitle: "Nuevo Producto",
      modalAction: "¡Creado con éxito!",
      buttonAction: "Crear Producto",
      buttonUpdate: "Guardar Producto",
      selectedFile: null,
      isLoading: false,
      secondaryImage: null,
      secondaryPreviewImage: null,
      hasToShowSecondaryImage: false,
      isImageLoading: false,
      isSecondaryImageLoading: false,
      isSecondaryPreviewImageLoading: false,
      savingControl: false,
    };
  },
  computed: {
    hasToShowSecondaryProductPicture() {
      if (!this.selectedList) return false;
      if (!this.$store.getShopsThatHaveToShowSecondaryProductPictures)
        return false;
      const shopCode = this.selectedList.shopCode;
      const hasToShowSecondaryImage =
        this.$store.getShopsThatHaveToShowSecondaryProductPictures.some(
          (obj) => obj.shopCode === shopCode
        );
      return hasToShowSecondaryImage;
    },
    hasToShowIsRg5329() {
      switch (process.env.VUE_APP_INSTANCE_CODE) {
        case "AR": {
          return true;
        }
        default: {
          return false;
        }
      }
    },
    hasToShowIEPSTax() {
      switch (process.env.VUE_APP_INSTANCE_CODE) {
        case "MX": {
          return true;
        }
        default: {
          return false;
        }
      }
    },
    hasToShowBillingProductFields() {
      return this.isVisibleByInstanceCode("MX");
    },
    getMeasureUnit() {
      if (this.product === null || this.product === undefined) return "Unidad";
      if (this.product.weightable === false) return "Unidad";

      if (
        this.product.measureUnit === "" ||
        this.product.measureUnit === undefined ||
        this.product.measureUnit === null
      )
        return "Unidad";

      return this.product.measureUnit;
    },
  },
  methods: {
    getIEPSTaxValues() {
      return [0, 3, 6, 7, 8, 9, 25, 26.5, 30, 30.4, 50, 53, 160];
    },
    closeModal() {
      $("#__layout").css("pointer-events", "all");
    },
    deleteProduct() {
      this.$emit("deleteProduct");
    },
    deleteImage() {
      this.product.image = null;
    },
    deleteSecondaryImage() {
      this.secondaryImage = null;
      this.deleteSecondaryPreviewImage();
    },
    deleteSecondaryPreviewImage() {
      this.secondaryPreviewImage = null;
    },
    getMeasureUnits() {
      // return ['Kg' /*, 'g', 'L', 'ml'*/ ]
      return ["Kg", "L"];
    },
    showLoadingPictureError(msg) {
      this.$store.errorLoading(this);
      this.isImageLoading = false;
      this.showError(msg);
    },
    onVatChanged(productVat) {
      this.product.nonTaxed = productVat.nonTaxed;
      this.product.exempt = productVat.exempt;
      this.product.rootVat = productVat.rootVat;
    },
    onPrimaryFileSelected(event) {
      const t = this;
      this.selectedFile = event.target.files[0];
      this.isImageLoading = true;
      this.onFileSelected(
        t,
        (url) => {
          t.product.image = url;
          t.isImageLoading = false;
        },
        () => {
          t.isImageLoading = false;
        }
      );
    },
    onSecondaryPreviewFileSelected(event) {
      const t = this;
      this.selectedFile = event.target.files[0];
      this.isSecondaryPreviewImageLoading = true;
      this.onFileSelected(
        t,
        (url) => {
          t.secondaryPreviewImage = url;
          toast.success(
            "Preview secundaria de producto agregada exitósamente!"
          );
          t.isSecondaryPreviewImageLoading = false;
        },
        () => {
          t.isSecondaryPreviewImageLoading = false;
        }
      );
    },
    onSecondaryFileSelected(event) {
      const t = this;
      this.selectedFile = event.target.files[0];
      this.isSecondaryImageLoading = true;
      this.onFileSelected(
        t,
        (url) => {
          t.secondaryImage = url;
          toast.success("Foto secundaria de producto agregada exitósamente!");
          t.isSecondaryImageLoading = false;
        },
        () => {
          t.isSecondaryImageLoading = false;
        }
      );
    },
    onFileSelected(t, onSuccess, onFailed) {
      if (!this.selectedFile) {
        onFailed();
        return;
      }
      if (
        (this.selectedFile.name.endsWith(".jpeg") ||
          this.selectedFile.name.endsWith(".jpg") ||
          this.selectedFile.name.endsWith(".bmp") ||
          this.selectedFile.name.endsWith(".png")) === false
      ) {
        this.showLoadingPictureError(
          `Las imágenes deben ser .jpg, .jpeg, .bmp o .png`
        );
        onFailed();
        return;
      }
      const filesize = (this.selectedFile.size / 1024 / 1024).toFixed(4); // MB
      if (filesize > 2) {
        // 2MB

        this.showLoadingPictureError(
          `El tamaño de la foto no puede superar los 2MB. La foto que subiste tiene ${filesize}MB`
        );
        onFailed();
        return;
      }

      var _URL = window.URL || window.webkitURL;
      var file, img;
      if ((file = this.selectedFile)) {
        img = new Image();
        var objectUrl = _URL.createObjectURL(file);
        img.onload = function () {
          _URL.revokeObjectURL(objectUrl);

          //Reviso el maximo
          if (this.width > t.maxWidth) {
            t.showLoadingPictureError(
              `El ancho de la imagen es de ${this.width}px. No puede superar los ${t.maxWidth}px`
            );
            onFailed();
            return;
          }
          
          if (this.height > t.maxHeight) {
            t.showLoadingPictureError(
              `El alto de la imagen es de ${this.height}px. No puede superar los ${t.maxHeight}px`
            );
            onFailed();
            return;
          }

          //Reviso el minimo
          if (this.width < t.minWidth) {
            t.showLoadingPictureError(
              `El ancho de la imagen es de ${this.width}px. Debe superar los ${t.minWidth}px`
            );
            onFailed();
            return;
          }
          
          if (this.height < t.minHeight) {
            t.showLoadingPictureError(
              `El alto de la imagen es de ${this.height}px. Debe superar los ${t.minHeight}px`
            );
            onFailed();
            return;
          }

          t.saveMediaInCloudStorage(
            t,
            "images/" + t.selectedList.id + "/" + t.selectedFile.name,
            onSuccess,
            true,
            t.selectedList.shopCode,
            t.product.sku
          );
        };
        img.src = objectUrl;
        $("#__layout").css("pointer-events", "all");
      }
    },
    upsertProduct() {
      this.isLoading = true;
      const t = this;
      let upsertProductHandler = setInterval(() => {
        clearInterval(upsertProductHandler);
        try {
          if (t.product.name === "") {
            toast.error("El nombre del producto es obligatorio");
          } else if (t.product.department === "") {
            toast.error("La categoría del producto es obligatoria");
          } else if (t.product.rootVat === "" || t.product.rootVat === null || t.product.rootVat === undefined) {
            toast.error("El IVA del producto es obligatorio");
          } else if (t.product.weightable && t.product.measureUnit === "") {
            toast.error(
              "La unidad de medida es obligatoria para un producto pesable"
            );
          } else {
            if (t.product.sku === "") {
              const refDataListProducts = ref(
                db,
                "listProducts/" + t.selectedList.id + "/products"
              );

              let refDataTrigger = false;
              get(refDataListProducts).then((products) => {
                if (!refDataTrigger) {
                  refDataTrigger = true;
                  const resp = products.val();
                  let prods = null;
                  if (resp != null) {
                    prods = Object.entries(resp);
                  }

                  const refnextIncrementForSku = ref(
                    db,
                    `listProducts/${t.selectedList.id}/nextIncrementForSku`
                  );
                  let incrementCount = 0;
                  let refNextTrigger = false;
                  get(refnextIncrementForSku).then((snapshot) => {
                    if (!refNextTrigger) {
                      refNextTrigger = true;
                      let nextIncrementForSku = snapshot.val();
                      if (
                        nextIncrementForSku !== undefined &&
                        nextIncrementForSku !== null
                      ) {
                        t.product.sku = `10${t.selectedList.shopCode +
                          nextIncrementForSku.toString()
                          }`;
                        nextIncrementForSku += 1;
                      } else {
                        t.product.sku = `10${t.selectedList.shopCode}0`;
                        nextIncrementForSku = 1;
                      }

                      if (prods) {
                        while (
                          prods.map((x) => x[1].sku).includes(t.product.sku)
                        ) {
                          t.product.sku = `10${t.selectedList.shopCode +
                            nextIncrementForSku.toString()
                            }`;
                          nextIncrementForSku += 1;
                        }
                      }

                      const setProductListsZ = ref(
                        db,
                        `listProducts/${t.selectedList.id}/nextIncrementForSku`
                      );

                      if (incrementCount === 0) {
                        incrementCount++;
                        set(setProductListsZ, nextIncrementForSku)
                        .then(() => {
                            t.enableProductOnBackEnd(t.product.sku, t.selectedList.shopCode);
                            t.saveProduct();
                          })
                          .catch((error) => {
                            console.error("Error updating product:", error);
                          });
                      }
                    }
                  });
                }
              });
            } else {
              if (
                t.product.sku.startsWith("20") &&
                t.product.weightable === true
              ) {
                toast.error(
                  'El SKU no puede comenzar con "20", dado que es un rango reservado para productos no pesables.'
                );
              } else if (t.product.sku.startsWith("98")) {
                toast.error(
                  'El SKU no puede comenzar con "98", dado que es un rango reservado para adicionales y gustos.'
                );
              } else if (t.product.sku.startsWith("97")) {
                toast.error(
                  'El SKU no puede comenzar con "97", dado que es un rango reservado para ingredientes.'
                );
              } else {
                t.enableProductOnBackEnd(t.product.sku, t.selectedList.shopCode);
                t.saveProduct();
              }
            }
          }
        } finally {
          t.isLoading = false;
        }
      }, 500);
    },
    saveProduct() {
      let saveProductTrigger = false;
      this.checkSameSkuAlreadyExists(
        (prod) => {
          if (this.savingControl) return;
          toast.error(
            `Ya existe el producto ${prod.name} con el SKU ${prod.sku} en tu lista`
          );
        },
        () => {
          if (!saveProductTrigger) {
            saveProductTrigger = true;

            if (this.savingControl) return;

            this.savingControl = true;

            if (
              this.product.stock === null ||
              this.product.stock === undefined
            ) {
              if (this.product.weightable === true) {
                this.product.stock = Math.round(0).toFixed(3);
              } else {
                this.product.stock = Math.round(0).toFixed(0);
              }
            }

            if (this.hasToShowStockInput === true) {
              if (this.product.weightable === true) {
                this.product.stock = isNaN(this.product.stock)
                  ? 0
                  : this.product.stock;
                this.product.stock = (
                  Math.round(this.product.stock * 1000) / 1000
                ).toFixed(3);
              } else {
                this.product.stock = isNaN(this.product.stock)
                  ? 0
                  : this.product.stock;
                this.product.stock = (
                  Math.round(this.product.stock * 100) / 100
                ).toFixed(0);
              }
            }

            if (this.secondaryImage)
              this.product.secondaryImage = this.secondaryImage;
            if (this.secondaryPreviewImage)
              this.product.secondaryPreviewImage = this.secondaryPreviewImage;

            this.$emit("upsertProduct", this.product);
            this.selectedFile = null;

            if (this.isNewProduct) {
              toast.success(this.product.name + " agregado exitosamente!");
              this.savingControl = false;
            } else {
              toast.success(this.product.name + " guardado exitosamente!");
              this.savingControl = false;
            }
          }
        }
      );
    },
    checkSameSkuAlreadyExists(callbackExistantSku, callbackNotExistantSku) {
      const t = this;
      let limitSkuExists = 0;
      let quantitySkuExists = 0;
      let prod = null;

      const refDataListProducts = ref(
        db,
        "listProducts/" + t.selectedList.id + "/products"
      );

      get(refDataListProducts).then((snapshot) => {
        const resp = snapshot.val();
        if (resp != null) {
          let prods = Object.entries(resp);
          prods.forEach((item) => {
            if (item[1].sku === this.product.sku) {
              quantitySkuExists++;
              prod = item[1];
            }
          });

          if (this.currentSku === this.product.sku) limitSkuExists++;

          if (quantitySkuExists > limitSkuExists) callbackExistantSku(prod);
          else callbackNotExistantSku();
        } else {
          callbackNotExistantSku();
        }
      });
    },
  },
  components: {
    CurrencyStyle,
    SpinnerComp,
    VatSelector,
    BadgeComp,
  },
};

/* eslint-enable */
</script>
<style lang="stylus">
.show-div {
	display: block;
}

.hide-div {
	display: none;
}
</style>
