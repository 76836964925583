<template>
	<section class="mainContain-info">

		<!-- FILTRADO DE LA SECCION -->
		<article class="mainFilter filterEvent">
			<!-- TITULO Y SEARCH -->
			<section class="mainFilter-title">
				<div class="fix-title">
					<h1>Detalles de Transacciones</h1>
					<p v-if="isGeneratingReport">La generación del reporte puede demorar unos minutos, gracias por tu
						paciencia.</p>
				</div>
			</section>

			<!-- OPCIONES ADICIONALE -->
			<section class="mainFilter-filter">
				<Spinner v-if="isGeneratingReport" />
				<div v-else @click="onGenerateCurrentTrxDetailReport()" class="btn-main">
					<a class="waves-effect waves-light">
						<p>Generar Nuevo Detalle</p>
					</a>
				</div>
			</section>
		</article>

		<!-- LISTADO DE REPORTES -->
		<article v-if="reports.length > 0" class="mainEvents reportDashboard">

			<!-- LISTADO DE REPORTES -->
			<!-- <ul class="providerDetail-facture"> -->
			<ul class="stockReport trxDetailReport">
				<!-- ASENTAMIENTOS -->
				<!-- VENTAS -->
				<a class="hidden" ref="donwload" :href="href" :download="pdfName">Download</a>
				<li v-for="(item, index) in reports" :key="index">
					<figure class="iconFactu">
						<span class="icon-Bistro-_FileDoble"></span>
					</figure>

					<!-- CONCEPTO -->
					<div class="items stockReportDetailTitle">
						<h3>Reporte de Detalle de Transacciones</h3>
						<p>{{ item.timestamp }}</p>
					</div>

					<!-- OPERACION -->
					<div class="items stockReportDetail ">
						<p><strong>{{ item.shopName }}</strong></p>
					</div>


					<!-- OPCIONES DE ESTATUS -->
					<div class="items downloadStockReport">
						<div v-if="item.status == 'Pending'">
							<Spinner />
							<p>Procesando...</p>
						</div>

						<a v-if="item.status == 'Finished'" :href="item.url" target="_blank"
							class="waves-effect waves-light btn-link" download>
							<span class="icon-Bistro-_Download"></span>
							<span>Descargar Detalle</span>
						</a>

						<div v-if="item.status == 'With Errors'">
							<p>Algo salió mal. Se intentará generar el reporte más tarde</p>
						</div>

					</div>
				</li>
			</ul>

		</article>
		<article v-for="i in 4" :key="i" :class="sectionLoading"  class="mainEvents reportDashboard">
		</article>
		<div v-if="reports && reports.length > 0 && isMoreOfOnePage()" class="base-pagination">
			<BasePagination :current-page="currentPage" :totalCount="totalCount" :countPerPage="countPerPage"
				class="list__pagination" @nextPage="pageChangeHandle('next')"
				@previousPage="pageChangeHandle('previous')" @loadPage="pageChangeHandle" />
		</div>

		<!-- MENSAJE ERROR -->
		<article v-if="reports.length === 0 && loaded" class="reportError">
			<h4>No hay reportes de Detalle de Transacciones</h4>
		</article>

		<help-center />
	</section>
</template>
<script>
import commons from '@/mixins/mixCommons'
import rolesCommons from '@/mixins/rolesCommons'
import bistroV2Commons from '@/mixins/bistroV2Commons'
import authCommons from '@/mixins/authCommons'
import computeds from '@/mixins/menuOptions'
import selectPdvs from '@/mixins/select_pdvs'
import axios from 'axios'
import { parseToQuery } from '@/utils/utils'
import BasePagination from '@/components/BasePagination'
import Spinner from '@/components/SpinnerComp'
import HelpCenter from '@/components/HelpCenter'
import bOrdersCommons from '@/mixins/bOrdersCommons'
import reportCommons from "@/mixins/reportCommons";

export default {
	mixins: [authCommons, commons, computeds, rolesCommons, selectPdvs, bistroV2Commons, reportCommons, bOrdersCommons],
	data() {
		return {
			isGeneratingReport: false,
			isLoadingReport: false,
			params: {},
			uuid: '',
			reports: [],
			loaded: false,
			href: '',
			pdfName: '',
			currentPage: 1,
			countPerPage: 10,
			totalCount: 0,
		}
	},
	async mounted() {
		document.title = `${this.appTitle} - Reportes - Detalle de Transacciones`
		this.$store.setLoadingSkeleton(true)
		this.$store.setHasMounted(false)
		if (this.isUserLoggedIn() === false) {
			axios.get('/api/auth/logout') /// OK
				.then(() => {
					this.$store.SET_USER(null)
					this.resetUser()
					this.setAutomaticLogin(false)
					location.href = '/login'
				})
		} else if (this.hasToShowThisOption(2) === false) {
			this.handleUnauthorizedAccess()

		} else {
			if (await this.checkSessionInBackendAsync()) {

				this.onMounted()
			}


		}
	},
	watch: {
		tab() {

			if (this.tab.id !== 6) {
				this.params['Period'] = this.tab.param
				this.getData()
			}
		},
		dateCalendar() {
			if (this.dateCalendar.indexOf('Seleccione') < 0) {
				this.params['Period'] = this.tab.param
				this.getData()
			}
		}
	},
	methods: {
		async onMounted() {
			this.uuid = this.createUUID()
			if (this.getPeriodSelectionFromLocalStorage() !== null) {
				await this.$store.updateTabSelected(this.getPeriodSelectionFromLocalStorage())
				this.params['Period'] = this.getPeriodSelectionFromLocalStorage().param
			} else {
				await this.$store.initTabs()
			}
			this.$store.setShowProductListSelectionFilter(false)
			this.$store.hideElements({
				hideCalendar: false,
				hideFilters: false,
				hideTabs: false
			})
			this.$store.updateSideMenu(3);
			this.$store.updateCalendarType('range')
			this.$store.CALL_MERCHANTS()


			this.params['CurrentPage'] = this.currentPage
			this.params['CountPerPage'] = this.countPerPage
			this.$store.setHasMounted(true)

		},
		onGenerateCurrentTrxDetailReport() {
			this.addExcelParams()
			this.processReportInBackend(this.getOrdersUrl, this.excelParams)

		},
		addExcelParams() {
			let p = this.params['CurrentPage']
			this.params['CurrentPage'] = null
			this.params['Uuid'] = this.uuid
			this.excelParams = parseToQuery(this.params)
			this.params['CurrentPage'] = p
		},
		isMoreOfOnePage() {
			return (this.totalCount - this.countPerPage) > 0
		},
		getData() {
			if (!this.$store.hasMounted) return

			this.$store.initLoading(this)
			axios.get(`/api/report/trxDetailReport?${parseToQuery(this.params)}`)
				.then(res => {
					this.reports = res.data.reports
					this.totalCount = res.data.totalCount
					this.$store.finishLoading(this)
					this.$store.setLoadingSkeleton(false)
					this.loaded = true
				})
				.catch(err => {
					this.$store.errorLoading(this)
					this.showErrors(err.response)
				})
		},
	},
	computed: {
		getOrdersUrl() {
			if (this.tab.param == 'Today' || this.tab.param == 'CurrentCashBox' || this.tab.param == 'PreviusCashBox') {
				return "/backendConsolidatedV2";
			}
			else
				return '/differedConsolidatedV2'
		},
	},
	components: {
		BasePagination,
		Spinner,
		HelpCenter
	},
}
</script>

<style scoped>
.skeleton-card {
	margin: 10px 25px ;
}
</style>