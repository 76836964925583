<template>
  <div id="modalResumSales" class="modal">
    <div class="modal-header">
      <h4>{{ title }} {{ movementType }} #{{ modalInfo.extendedInfo.id }}</h4>
      <p>Fácil y Simple</p>

      <!-- CLOSE -->
      <div class="btnClose">
        <a
          href="#!"
          class="modal-action modal-close waves-effect waves-green btn-flat"
        >
          <span class="icon-Bistro-_Close"></span>
        </a>
      </div>
    </div>

    <!-- {{modalInfo}} -->

    <div class="modal-body modalStock modalResumSales">
      <article class="cardClient">
        <section class="itemUser">
          <figure>
            <span class="icon-Bistro-_Smile"></span>
          </figure>
          <div class="item">
            <h3>
              {{
                modalInfo.extendedInfo ? modalInfo.extendedInfo.customer : ""
              }}
            </h3>
            <!-- <div class="tdc">
							<span v-bind:class="modalInfo.extendedInfo.paymentType + 1 | payment('icon')"></span>
							<p>{{ modalInfo.extendedInfo.paymentInfo }}</p>
						</div> -->
          </div>
        </section>

        <section
          v-if="modalInfo.extendedInfo.relatedFiscalInfo"
          class="itemUser"
        >
          <div class="item">
            <div class="fiscal-data">
              {{ modalInfo.extendedInfo.relatedFiscalInfo.billType }}
            </div>
            <div class="fiscal-data">
              {{ modalInfo.extendedInfo.relatedFiscalInfo.billNumber }}
            </div>
            <div class="fiscal-data">
              {{ modalInfo.extendedInfo.relatedFiscalInfo.customerName }}
            </div>
            <div class="fiscal-data">
              {{ modalInfo.extendedInfo.relatedFiscalInfo.customerFiscalKey }}
            </div>
            <div class="fiscal-data">
              {{ modalInfo.extendedInfo.relatedFiscalInfo.customerAddress }}
            </div>

            <div v-if="hasToShowBillTicket">
              <div
                class="fiscal-data"
              >
                <a
                  :href="`/ticketsViewer?ShopCode=${modalInfo.shopCode}&InternalReference=${modalInfo.extendedInfo.uuid}`"
                  target="_blank"
                >
                  <span class="icon-Bistro-_Search" title="Ver Factura"></span>
                  Ver Factura
                </a>
              </div>
            </div>
            <div v-else>
              <div v-if="showBillingActions" class="fiscal-data">
                <router-link
                  :to="`/billViewer?InternalReference=${modalInfo.extendedInfo.uuid}`"
                  target="_blank"
                >
                  <span class="icon-Bistro-_Search" title="Ver Factura"></span>
                  Ver Factura
                </router-link>
              </div>
              <div v-if="showBillingActions" class="fiscal-data">
                <a
                  :href="`${modalInfo.extendedInfo.rawRequestUrl}`"
                  target="_blank"
                >
                  <span class="icon-Bistro-_Search" title="Ver XML"></span>
                  Ver XML
                </a>
              </div>

              <div
                v-if="showBillingActions"
                @click.prevent="processVoidBill"
                class="fiscal-data fiscal-data-voidBill"
              >
                <span
                  class="icon-Bistro-_Trash_bin"
                  title="Anular Factura"
                ></span>
                Anular Factura
              </div>
            </div>
          </div>
        </section>
        <div v-if="movementType === 'Comanda' && hasToShowTicketViewer" class="fiscal-data">
      <router-link :to="`/billViewer?InternalReference=${modalInfo.extendedInfo.uuid}`" target="_blank">
        <span class="icon-Bistro-_Search" title="Ver Ticket"></span>
        Ver Ticket
          </router-link>
				</div>
        <section class="record">
          <div class="item">
            <span
              ><strong>{{ modalInfo.extendedInfo.date }}</strong> -
              {{ modalInfo.extendedInfo.hour }}</span
            >
            <h4>
              <currency-style
                :valor="
                  modalInfo.extendedInfo
                    ? $filters.currency(modalInfo.extendedInfo.amount)
                    : ''
                "
              />
            </h4>
          </div>
        </section>
      </article>

      <article class="transactions view-details">
        <h4>Ticket</h4>

        <ul class="mainEvents-transaction-detail">
          <li v-for="(item, index) in modalInfo.details" :key="index">
            <figure class="iconHistory">
              <span class="icon-Bistro-_Check"></span>
            </figure>

            <div class="items">
              <p>
                <strong>{{ item.quantity }} {{ item.measureUnit }}</strong
                >{{ item.productName }}
              </p>
            </div>

            <div class="items">
              <p v-if="item.skuCode !== ''">SKU: {{ item.skuCode }}</p>
            </div>

            <div class="items">
              <h3>
                <currency-style :valor="$filters.currency(item.totalAmount)" />
              </h3>
            </div>

            <div
              class="div-options"
              v-if="item.options !== undefined && item.options.length > 0"
            >
              <ul class="options">
                <li v-for="(op, ind) in item.options" :key="ind">
                  <figure class="iconHistory"></figure>

                  <div class="items">
                    <p>
                      <strong>{{ op.quantity }}</strong
                      >{{ op.optionGroupName }}
                    </p>
                  </div>

                  <div class="items">
                    <p>{{ op.optionName }}</p>
                  </div>

                  <div class="items">
                    <h3>
                      <currency-style
                        :valor="$filters.currency(op.totalAmount)"
                      />
                    </h3>
                  </div>
                </li>
              </ul>
            </div>
          </li>

          <li v-for="(combo, i) in modalInfo.comboDetails" :key="i">
            <figure class="iconHistory">
              <span class="icon-Bistro-_Check"></span>
            </figure>

            <div class="items">
              <p>
                <strong>{{ combo.quantity }}</strong
                >{{ combo.comboName }}
              </p>
            </div>

            <div class="items">
              <p>SKU: {{ combo.sku }}</p>
            </div>

            <div class="items">
              <h3>
                <currency-style :valor="$filters.currency(combo.totalAmount)" />
              </h3>
            </div>

            <div
              v-if="combo.products !== undefined && combo.products.length > 0"
              class="combo-row"
            >
              <ul>
                <li v-for="(item, index) in combo.products" :key="index">
                  <figure class="iconHistory">
                    <!-- <span class="icon-Bistro-_Check"></span> -->
                  </figure>

                  <div class="items">
                    <p>
                      <strong>{{ item.quantity }} {{ item.measureUnit }}</strong
                      >{{ item.productName }}
                    </p>
                  </div>

                  <div class="items">
                    <p>SKU: {{ item.skuCode }}</p>
                  </div>

                  <div class="items amount">
                    <h3>
                      <currency-style
                        :valor="$filters.currency(item.totalAmount)"
                      />
                    </h3>
                  </div>

                  <div
                    class="div-options"
                    v-if="item.options !== undefined && item.options.length > 0"
                  >
                    <ul class="options">
                      <li v-for="(op, ind) in item.options" :key="ind">
                        <figure class="iconHistory"></figure>

                        <div class="items">
                          <p>
                            <strong>{{ op.quantity }}</strong
                            >{{ op.optionGroupName }}
                          </p>
                        </div>

                        <div class="items">
                          <p>{{ op.optionName }}</p>
                        </div>

                        <div class="items amount">
                          <h3>
                            <currency-style
                              :valor="$filters.currency(op.totalAmount)"
                            />
                          </h3>
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </li>
          <li v-for="(item, index) in modalInfo.saleTaxes" :key="index">
            <figure class="iconHistory">
              <span class="icon-Bistro-_Check"></span>
            </figure>
            <div class="items">
              <p>{{ item.taxCode }}</p>
            </div>
            <div class="items">
              <p v-if="item.taxRate"></p>
            </div>
            <div class="items">
              <h3>
                <currency-style :valor="$filters.currency(item.taxAmount)" />
              </h3>
            </div>
          </li>
        </ul>
      </article>

      <article class="paymentWays">
        <h4>Medios de pago usados</h4>

        <ul class="mainEvents-transaction-payment">
          <li v-for="(item, index) in modalInfo.payments" :key="index">
            <div class="payment-type">
              <figure class="iconHistory">
                <span
                  v-bind:class="$filters.payment(item.paymentType + 1, 'icon')"
                ></span>
              </figure>

              <div class="items">
                <p>
                  <strong>{{ item.paymentWay }}</strong>
                </p>
              </div>
            </div>

            <div v-if="item.authorizationCode !== null" class="items">
              <p><strong>Cod:</strong>{{ item.authorizationCode }}</p>
            </div>
            <div v-else class="items">
              <p></p>
            </div>

            <div class="items">
              <h3>
                <currency-style
                  :valor="$filters.currency(item.partialAmount)"
                />
              </h3>
            </div>
          </li>
        </ul>
      </article>
    </div>
    <ConfirmationModalBeforeVoidBill
      :orderExtendedInfo="modalInfo.extendedInfo"
      @onCloseModal="onCloseModal"
      @onCloseModalAndReloadPage="onCloseModalAndReloadPage"
    />
  </div>
</template>
<script>
import mixCommons from "../mixins/mixCommons";
import CurrencyStyle from "../components/CurrencyStyle";
import ConfirmationModalBeforeVoidBill from "../components/ConfirmationModalBeforeVoidBill";

export default {
  mixins: [mixCommons],
  props: {
    title: {
      type: String,
      default: "",
    },
    showBillingActions: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return { modalResumSales: {}, instanceModalResumSales: {} };
  },
  mounted() {
    /* eslint-disable */
    this.modalResumSales = document.getElementById(
      "ConfirmationModalBeforeVoidBill"
    );
    this.instanceModalResumSales = M.Modal.init(this.modalResumSales);
    /* eslint-enable */
  },
  computed: {
    modalInfo() {
      return this.$store.getModalInfoV2;
    },
    movementType() {
      return this.modalInfo.extendedInfo.relatedFiscalInfo
        ? "Venta"
        : "Comanda";
    },
    hasToShowBillTicket() {
      switch (process.env.VUE_APP_INSTANCE_CODE) {
        case "AR":
          return true;
        case "ES":
          return true;
        default:
          return false;
      }
    },
    hasToShowTicketViewer(){
			switch (process.env.VUE_APP_INSTANCE_CODE) {
				case "MX":
					return true;
				default:
					return false;
			}
		},
  },
  methods: {
    /* eslint-disable */

    onCloseModal() {
      this.instanceModalResumSales.close();
      this.$emit("onCloseModal");
    },
    onCloseModalAndReloadPage() {
      this.instanceModalResumSales.close();
      this.$emit("onCloseModalAndReloadPage");
    },
    processVoidBill() {
      this.instanceModalResumSales.open();
      //$("#ConfirmationModalBeforeVoidBill").modal("open");
    },

    /* eslint-enable */
  },
  components: {
    CurrencyStyle,
    ConfirmationModalBeforeVoidBill,
  },
};
</script>
