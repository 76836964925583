<template>
    <section class="mainContain-info">

        <!-- FILTRADO DE LA SECCION -->
        <article class="mainFilter filterEvent">
            <!-- TITULO Y SEARCH -->
            <section class="mainFilter-title">
                <h1>Reportes</h1>
            </section>
        </article>



        <!-- LISTADO DE REPORTES -->
        <article v-if="reports?.length > 0" class="mainEvents reportDashboard">

            <!-- LISTADO DE REPORTES -->
            <ul class="providerDetail-facture">
                <!-- ASENTAMIENTOS -->
                <!-- VENTAS -->
                <a class="hidden" ref="donwload" :href="href" :download="pdfName">Download</a>
                <li v-for="(item, index) in reports" :key="index">
                    <figure class="iconFactu">
                        <span class="icon-Bistro-_FileDoble"></span>
                    </figure>

                    <!-- CONCEPTO -->
                    <div class="items">
                        <h3>Cierre de Caja</h3>
                    </div>

                    <!-- OPERACION -->
                    <div class="items">
                        <p>{{ item.merchantName }}</p>
                        <p>TID: {{ item.deviceId }}</p>
                        <p>Creado: {{ getDate(item.timestamp) }}</p>
                    </div>

                    <!-- TOTAL -->
                    <div class="items">
                    </div>

                    <!-- OPCIONES DE ESTATUS -->
                    <div class="items">
                        <no-ssr>
                            <div class="btn-main">
                                <a @click="createPDF(item)">
                                    <p>Ver Reporte</p>
                                </a>
                            </div>
                        </no-ssr>
                    </div>
                </li>
            </ul>

        </article>
        <article v-else-if="reports?.length === 0 && loaded" class="reportError">
            <h4>No hay reportes de cierre de caja</h4>
        </article>
        <article v-else v-for="i in 4" :key="i" :class="sectionLoading"
            class=" mainEvents reportDashboard providerDetail-facture">
        </article>
        <div v-if="reports && reports?.length > 0 && isMoreOfOnePage()" class="base-pagination">
            <BasePagination :current-page="currentPage" :totalCount="totalCount" :countPerPage="countPerPage"
                class="list__pagination" @nextPage="pageChangeHandle('next')"
                @previousPage="pageChangeHandle('previous')" @loadPage="pageChangeHandle" />
        </div>
        <!-- MENSAJE ERROR -->
       

        <help-center />
    </section>
</template>
<script>
import commons from '@/mixins/mixCommons'
import rolesCommons from '@/mixins/rolesCommons'
import authCommons from '@/mixins/authCommons'
import bistroV2Commons from '@/mixins/bistroV2Commons'
import computeds from '@/mixins/menuOptions'
import selectPdvs from '@/mixins/select_pdvs'
import moment from 'moment'
import axios from 'axios'
import { parseToQuery } from '@/utils/utils'
import BasePagination from '@/components/BasePagination'
import HelpCenter from '@/components/HelpCenter'

export default {
    mixins: [authCommons, commons, computeds, selectPdvs, rolesCommons, bistroV2Commons],
    data() {
        return {
            params: {},
            reports: [],
            loaded: false,
            href: '',
            pdfName: '',
            currentPage: 1,
            countPerPage: 10,
            totalCount: 0,
        }
    },
    async mounted() {
        this.$store.setHasMounted(false)
        this.$store.setLoadingSkeleton(true)
        document.title = `${this.appTitle} - Cierre de caja`
        if (this.isUserLoggedIn() === false) {
            axios.get('/api/auth/logout') /// OK
                .then(() => {
                    this.$store.SET_USER(null)
                    this.resetUser()
                    this.setAutomaticLogin(false)
                    location.href = '/login'
                })
        } else if (this.hasToShowThisOption(2) === false) {
            this.handleUnauthorizedAccess()

        } else {
            if (await this.checkSessionInBackendAsync()) {
                this.onMounted()
            }


        }
    },
    watch: {
        tab() {

            if (this.tab.id !== 6) {
                this.params['Period'] = this.tab.param
                this.getData()
            }
        },
        dateCalendar() {
            if (this.dateCalendar.indexOf('Seleccione') < 0) {
                this.params['Period'] = this.tab.param
                this.getData()
            }
        },
        onSelectedShopCodeChanged() {
            return this.$store.getRefreshSelectedShopCode()
        }
    },
    methods: {
        async onMounted() {
            this.$store.updateSideMenu(3)

            if (this.getPeriodSelectionFromLocalStorage() !== null) {
                await this.$store.updateTabSelected(this.getPeriodSelectionFromLocalStorage())
                this.params['Period'] = this.getPeriodSelectionFromLocalStorage().param
            } else {
                await this.$store.initTabReport()
            }

            this.toggleMerchantSelectorByLabel('showMerchantSelectionFilter')
            this.$store.CALL_MERCHANTS();
            this.$store.hideElements({
                hideCalendar: false,
                hideFilters: false,
                hideTabs: true,
                hideSomeTabs: false
            })
            this.$store.updateCalendarType('range')
            this.params['CurrentPage'] = this.currentPage
            this.params['CountPerPage'] = this.countPerPage
            this.$store.setHasMounted(true)

        },
        isMoreOfOnePage() {
            return (this.totalCount - this.countPerPage) > 0
        },
        createPDF(item) {
            let w = window.open()
            w.document.body.innerHTML = item.htmlPayload;
        },
        getData() {
            if (!this.$store.hasMounted) return

            this.$store.initLoading(this);
            axios.get(`/api/report/cashboxV2?${parseToQuery(this.params)}`)
                .then(res => {
                    if(res.data?.responseCode != 0) {
                        this.showErrors(res);
                    } else {
                        this.reports = res.data?.reports;
                        this.totalCount = res.data?.totalCount;
                    }
                    this.$store.finishLoading(false);
                    this.loaded = true;
                })
                .catch(err => {
                    this.loaded = true;
                    this.$store.errorLoading(this);
                    this.showErrors(err.response);
                });
        },
        getDate(timestamp) {
            return moment(timestamp).format('lll')
        }
    },
    components: {
        BasePagination,
        HelpCenter
    },
}
</script>

<style scoped>
.skeleton-card {
  margin-bottom: 10px;
}
</style>