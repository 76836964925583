<template>
    <main class="pdv-main">
        <SidebarBistro>
            <div id="header_sidebar">
                <!-- <ButtonBistro variant="solid-gray" text="Regresar" :icon="require('@assets/arrow-left-button.svg')"
                        @handleClick="handleClickComeBack" /> -->

            </div>
            <div id="body_sidebar">
                <InfoBoxBistro v-if="!showDetails" :icon="require('@assets/icon-light.svg')"
                    text="Selecciona una comanda para visualizar la orden" background="rgba(255,255,255,0.05)"
                    textColor="#ffffff" :isVertical="false" />
                <SidebarOrderDetail v-if="storedOrder?.products && showDetails" :order="storedOrder"
                    :onDelete="handleDelete" @update:order="updateOrderFromChildComponent" />
            </div>
        </SidebarBistro>
        <ContentBistro>
            <div id="body_content_bistro" class="fix-height">
                <SpacerBistro alignItems="flex-start" :justify="hasOrders ? 'end' : 'space-between'">
                    <InfoBoxBistro v-if="!hasOrders" :icon="require('@assets/icon-light-2.svg')"
                        text="Crea una Comanda para poder visualizarlas aquí" background="rgba(17,44,63,0.09)"
                        textColor="#000000" maxWidth="240px" />
                    <ButtonBistro v-if="showButton" variant="gradient-blue" text="+ Comandas"
                        @handleClick="handleNewOrder" />
                </SpacerBistro>
                <div class="content-bistro-cards" v-if="hasOrders">
                    <CardBistro v-for="order in getOrders" :key="order.id" :status="order.status"
                        :title="getTitle(order)" :duration="order.createdAt" :amount="order.amount"
                        @cardClicked="showOrderSummary(order.id)" @cardDoubleClicked="handleOrder(order.id)" />
                </div>
            </div>
        </ContentBistro>
        <ModalUnauthorized />
        <ModalDeleteOrder v-model="showDeleteOrderModal" :order="storedOrder"
            :selectedProductComment="selectedProductComment" @onConfirmDelete="deleteOrder"
            @update:showDeleteOrderModal="showDeleteOrderModal = false" :showDeleteOrderModal="showDeleteOrderModal" />
    </main>
</template>

<script>
import "@/features/pdv/assets/style.styl";
import router from "@/router";
import shopCommons from "../../shared/mixins/shopCommons.vue";
import SidebarBistro from "../components/SidebarBistro.vue";
import ContentBistro from "../components/ContentBistro.vue";
import SpacerBistro from "../components/SpacerBistro.vue";
import ButtonBistro from "@/features/shared/components/ButtonBistro.vue"
import CardBistro from "../components/CardBistro.vue";
import InfoBoxBistro from "../components/InfoBoxBistro.vue";
import commons from "@/mixins/mixCommons";
import rolesCommons from "@/mixins/rolesCommons";
import bistroV2Commons from "@/mixins/bistroV2Commons";
import axios from "axios";
import { getOrder, createOrder, syncOrders, getActiveOrders,syncCashboxes } from "../../shared/repositories/orders/ordersService.js";
import clickCommons from "../mixins/clickCommons.vue";
import SidebarOrderDetail from "../components/SidebarOrderDetail.vue";
import ModalUnauthorized from "../components/modals/ModalUnauthorized.vue";
import { checkAndroidIsDefined } from "../../shared/utils/utils";
import ModalDeleteOrder from "../components/modals/ModalDeleteOrder.vue";
import mixModals from "@/mixins/mixModals.vue";
import { toast } from "vue3-toastify";


export default {
    name: "PdvOrders",
    mixins: [shopCommons, commons, rolesCommons, bistroV2Commons, clickCommons, mixModals],
    data() {
        return {
            orders: [],
            showButton: false
        };
    },
    async mounted() {
        document.title = `${this.appTitle}  - PDV`;
        if (this.isUserLoggedIn() === false) {
            axios
                .get("/api/auth/logout") /// OK
                .then(() => {
                    this.$store.SET_USER(null);
                    this.resetUser();
                    this.setAutomaticLogin(false);
                    location.href = "/login";
                });
        } else if (!this.hasToShowSettingsPosTab) {
            this.handleUnauthorizedAccess()
        } else {
            this.onMounted();
        }
    },
    watch: {
        async shopCode() {
            await this.checkAndSyncCashbox()
        },
        '$pdv.getOrder': {
            handler(newOrder) {
                this.updateOrderInList(newOrder);
            },
            deep: true
        }
    },
    computed: {
        storedOrder() {
            return this.$pdv.getOrder
        },
        showDetails() {
            return this.$pdv.getShowDetails
        },
        hasOrders() {
            return this.orders.length > 0;
        },
        getOrders() {
            return this.orders;
        }
    },
    methods: {
        async checkAndSyncCashbox() {
            const dataBox = localStorage.getItem(`cashBox_${this.shopCode}`)

            if (dataBox && JSON.parse(dataBox)?.[0]?.closeBox !== true) {
                this.showButton = true
                this.orders = await getActiveOrders(this.shopCode);
                if (checkAndroidIsDefined(this.shopCode)) {
                    await syncOrders(this.shopCode, this)
                    await syncCashboxes(this.shopCode)
                }
            } else {
                toast.error("Debes abrir la caja para poder operar.");
                this.showButton = false
                this.orders = []
            }
        },
        async onMounted() {
            this.$store.updateTopMenu(7);
            this.toggleMerchantSelectorByLabel("showPDVListSelectionFilter");
            this.$store.hideElements({
                hideCalendar: true,
                hideFilters: false,
                hideTabs: true,
            });
            this.getListByShopCode();
            if (this.shopCode) {
                await this.checkAndSyncCashbox()
            }
            this.$pdv.setIsDisabled(false)
        },
        getTitle(order) {
            return order.origin + " " + order.id;
        },
        async handleNewOrder() {
            const order = await createOrder(this.shopCode);
            router.push("/pdv/order/details/" + this.shopCode + "/" + order.id);
        },
        async handleOrder(orderId) {
            const order = await getOrder(this.shopCode, orderId);
            router.push("/pdv/order/details/" + this.shopCode + "/" + order.id);
        },
        async showOrderSummary(orderId) {
            const order = await getOrder(this.shopCode, orderId);
            this.$pdv.setOrder(order);
            this.$pdv.setShowDetails(true);
        },
        updateOrderInList(updatedOrder) {
            if(!updatedOrder) return;
            const index = this.orders.findIndex(order => order.id === updatedOrder.id);
            if (index !== -1) {
                this.orders[index] = updatedOrder
            }
        },
    },
    components: {
        SidebarBistro,
        ContentBistro,
        SpacerBistro,
        InfoBoxBistro,
        ButtonBistro,
        CardBistro,
        SidebarOrderDetail,
        ModalUnauthorized,
        ModalDeleteOrder
    },
};
</script>

<style scoped>
.show-button-right {
    margin-left: auto;
}
</style>