<template>
    <div id="modalCreditsCards" class="modal modal-block-outside modal-pdv" @keydown.esc="closeModalCreditsCards()">
        <div class="modal-header">
            <div class="image-title">
                <h2>Número de tarjeta y código</h2>
            </div>
            <div class="btnClose">
                <a href="#!" @click="closeModalCreditsCards()"
                    class="modal-action modal-close waves-effect waves-green btn-flat">
                    <span class="icon-Bistro-_Close"></span>
                </a>
            </div>
        </div>

        <div class="modal-body">
            <section v-if="!selectedCard" class="box-credits-cards">
                <h4>1. Seleccione la tarjeta</h4>
                <div>
                    <ButtonPayCard :variant="'icon-only'" :icon="require('@/features/pdv/assets/img/visa-credito.svg')"
                         @handleClick="selectMethod('visa-credito')" :alt="'visa-credito'" /> <!-- assets/img/credit_cards/tc_visa.png -->
                    <ButtonPayCard :variant="'icon-only'" :icon="require('@/features/pdv/assets/img/amex.svg')"
                        @handleClick="selectMethod('amex')" :alt="'amex'" />
                    <ButtonPayCard :variant="'icon-only'" :icon="require('@/features/pdv/assets/img/cabal.svg')"
                        @handleClick="selectMethod('cabal')" :alt="'cabal'" />
                    <ButtonPayCard :variant="'icon-only'" :icon="require('@/features/pdv/assets/img/maestro.svg')"
                        @handleClick="selectMethod('maestro')" :alt="'maestro'" />
                    <ButtonPayCard :variant="'icon-only'"
                        :icon="require('@/features/pdv/assets/img/mastercard.svg')"
                        @handleClick="selectMethod('mastercard')" :alt="'mastercard'" />
                    <ButtonPayCard :variant="'icon-only'"
                        :icon="require('@/features/pdv/assets/img/visa-debito.svg')"
                        @handleClick="selectMethod('visa-debito')" :alt="'visa-debito'" />
                </div>
            </section>
            <section class="box-credits-cards" v-else>

                <h4>2. Código de autorización</h4>
                <div>
                    <ButtonPayCard :variant="'icon-only'" :icon="getCardIcon(selectedCard)" :disableHover="true"/>
                    <label>
                        <input type="text"  maxlength="6" placeholder="Ingrese código de autorización" v-model="authCode" @input="clearInput" />
                    </label>
                </div>
            </section>
        </div>
        <hr>
        <div class="modal-footer">
            <ButtonBistro text="Cerrar" variant="solid-gray" @handleClick="closeModalCreditsCards" />
            <ButtonBistro text="Confirmar pago" v-show="selectedCard" @handleClick="confirmAuthCode" />
        </div>
    </div>
</template>

<script>
import ButtonBistro from "@/features/shared/components/ButtonBistro.vue"
import mixModals from '@/mixins/mixModals.vue';
import payCommons from '../../mixins/payCommons.vue';
import ButtonPayCard from '../ButtonPayCard.vue';

export default {
    mixins: [payCommons, mixModals],
    data() {
        return {
            authCode: null,
            selectedCard: null
        };
    },
    methods: {
        clearInput() {
            this.authCode = event.target.value.replace(/[^0-9]/g, "");
        },
        confirmAuthCode() {
            this.$emit("onCardSelected", {
                selectedCard: this.selectedCard,
                authCode: this.authCode
            });
            this.closeModalById('#modalCreditsCards')
        },
        selectMethod(method) {
            this.selectedCard = method;
        },
        getCardIcon(card) {
            return require(`@/features/pdv/assets/img/${card}.svg`);
        },
    },
    components: {
        ButtonBistro,
        ButtonPayCard
    }
}
</script>

<style lang="scss" scoped>
.box-credits-cards {
    padding: 40px;

    h4{
        padding-bottom: 10px;
    }

    &>div {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 15px;
    }

    .button-pay .button-icon-pay img {
        width: 80px;
    }
}

.modal-footer>button {
    margin-right: 20px;
}
</style>
