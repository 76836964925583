<script>
export default {
    computed: {
     
        cashboxInProgress() {
            return this.$store.getCashBox
        },
    }
    
}
</script>